import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { useSDK } from "@sdk";
import { iFbConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Alert,
  Button,
  Descriptions,
  Divider,
  Form,
  Space,
  Spin,
  Switch,
} from "antd";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";
import { ExternalAuthConnector } from "components/common/external-auth-connector-button/external-auth-connector-button";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { SectionHeader } from "components/common/section-header";
import { ConnectionStartWithChatBotConfig } from "components/modules/connections/common-automation-configs/start-with-chat-bot-config";
import { ConnectionDataEditor } from "components/modules/connections/common-connection-settings/connection-data-editor";
import { GeneralConnectionConfig } from "components/modules/connections/common-connection-settings/general-connection-config";
import { RoutingPreferencesForm } from "components/modules/connections/common-connection-settings/routing-preferences-form/routing-preferences-form";
import { RoutingRules } from "components/modules/conversations/components/routing-rules/routing-rules";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { stringArrayToSentence } from "utils/string-array-to-sentence";
import FBBlockedUsers from "../blocked-facebook-users/blocked-facebook-users.modal-registry";

export const ManageFacebookConnection = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const isDevMode = useSelector(selectIsAdvancedMode);
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const { doAction: syncConversations, isProcessing: isConversationSyncing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (connectionId: string) =>
          SDK.connections.loadAllRecentFbConversation(connectionId),
        successMessage: "Conversations have been synced",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const { doAction: syncPosts, isProcessing: isPostsSyncing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (connectionId: string) =>
          SDK.syncAllPosts(connectionId),
        successMessage: "Posts have been synced",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const fbConnectionData = connection?.data as iFbConnectionData;

  const {
    data: connectionStatus,
    isLoading,
    error,
    reload: reloadDebug,
  } = useSDK(
    (SDK) =>
      SDK.connections
        .checkFacebookAuthenticationStatus(connectionId)
        .then((d) => {
          const grantedScopes = d?.debugResponse?.data.scopes;
          if (!grantedScopes.includes("pages_read_user_content")) {
            throw {
              message: "Missing pages_read_user_content",
            };
          }
          return d;
        }),
    [connectionId],
    !connectionId,
  );

  const {
    doAction: reAuthenticateFacebookConnection,
    isProcessing: isAuthenticating,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (connectionId: string, accessToken: string) =>
        SDK.connections
          .reAuthenticateFacebookConnection(connectionId, accessToken)
          .then((d) => {
            return reloadDebug();
          }),
      successMessage: "Re-authentication has been successful",
      failureMessage: "Something went wrong",
    }),
    [reloadDebug],
  );

  const { triggerTempModal } = useModalPanels();

  return (
    <>
      <Spin
        spinning={!error && isLoading}
        indicator={<LoadingIndicatorWithoutSpin />}
        wrapperClassName="flex-1 flex fill-spinner"
      >
        <div className="animated fadeInLeftMin  flex flex-col w-full">
          <SectionHeader
            title={fbConnectionData?.page?.name || "Connected Page"}
            icon={<i className="ri-facebook-box-line"></i>}
            description="Chat routing, access, user limits, email notifications, etc."
          />
          <Divider />
          <div className="flex flex-row w-full justify-end items-center mb-4">
            <Space>
              <Button
                type="link"
                onClick={() => {
                  triggerTempModal(FBBlockedUsers, { connectionId });
                }}
                icon={<i className="ri-spam-3-line"></i>}
              >
                Blocked Users
              </Button>
              <Button
                type="link"
                loading={isConversationSyncing}
                onClick={() => {
                  syncConversations(connectionId);
                }}
                icon={<i className="ri-refresh-line"></i>}
              >
                (Re)Sync Conversations
              </Button>
              <Button
                type="link"
                loading={isPostsSyncing}
                onClick={() => {
                  syncPosts(connectionId);
                }}
                icon={<i className="ri-refresh-line"></i>}
              >
                (Re)Sync Posts
              </Button>
            </Space>
          </div>

          {error || (!isLoading && !connectionStatus?.debugResponse) ? (
            <>
              <Alert
                message={
                  <div className="font-bold">
                    It looks like your Facebook Integration is broken
                  </div>
                }
                description={
                  <div>
                    It could happen for the following reasons <br />
                    <ul>
                      <li>👉 You disconnected ClickConnector Manually</li>
                      <li>
                        👉 The user who gave permission is no longer part of the
                        page
                      </li>
                      <li>
                        👉 The user who gave permission changed their password
                      </li>
                    </ul>
                    <div
                      className="flex flex-row justify-end items-center"
                      style={{ width: 202 }}
                    >
                      <Spin
                        spinning={isAuthenticating || (!error && isLoading)}
                        indicator={<LoadingIndicatorWithoutSpin />}
                        wrapperClassName="flex-1 flex fill-spinner"
                      >
                        <ExternalAuthConnector
                          type="FACEBOOK"
                          onAuthenticated={({ accessToken }) => {
                            reAuthenticateFacebookConnection(
                              connection.id,
                              accessToken,
                            );
                          }}
                          label="Re-Connect Facebook"
                        />
                      </Spin>
                    </div>
                  </div>
                }
                type="error"
                showIcon
                className="p-4 rounded-lg my-2 mb-4"
              />
            </>
          ) : (
            <></>
          )}

          {isDevMode &&
            !(error || (!isLoading && !connectionStatus?.debugResponse)) && (
              <div>
                <Spin
                  spinning={isAuthenticating || (!error && isLoading)}
                  indicator={<LoadingIndicatorWithoutSpin />}
                  wrapperClassName="flex-1 flex fill-spinner"
                >
                  <ExternalAuthConnector
                    type="FACEBOOK"
                    onAuthenticated={({ accessToken }) => {
                      reAuthenticateFacebookConnection(
                        connection.id,
                        accessToken,
                      );
                    }}
                    label="Re-Authenticate Facebook"
                    insider={true}
                  />
                </Spin>
              </div>
            )}

          <CollapsibleConfigurationSection
            icon={"ri-plug-line"}
            title={"Connected Page Details"}
            description={"Access, expiration, integration id etc"}
          >
            <Descriptions
              title=""
              bordered
              layout="horizontal"
              column={1}
              style={{ maxWidth: 600 }}
            >
              <Descriptions.Item label="Connected Page">
                {fbConnectionData?.page?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Page Category">
                {fbConnectionData?.page?.category}
              </Descriptions.Item>
              {!error && (
                <>
                  <Descriptions.Item label="Application">
                    {connectionStatus?.debugResponse?.data.application}
                  </Descriptions.Item>
                  <Descriptions.Item label="Application">
                    {connectionStatus?.debugResponse?.data.application}
                  </Descriptions.Item>
                  <Descriptions.Item label="Application">
                    {connectionStatus?.debugResponse?.data.application} (
                    {connectionStatus?.debugResponse?.data.app_id})
                  </Descriptions.Item>
                  <Descriptions.Item label="Data Access Expiry">
                    {dayjs(
                      connectionStatus?.debugResponse?.data
                        .data_access_expires_at * 1000,
                    ).toString()}
                  </Descriptions.Item>
                  <Descriptions.Item label="Access Issued at">
                    {dayjs(
                      connectionStatus?.debugResponse?.data.issued_at * 1000,
                    ).toString()}
                  </Descriptions.Item>
                  <Descriptions.Item label="Profile ID">
                    {connectionStatus?.debugResponse?.data.profile_id}
                  </Descriptions.Item>
                  <Descriptions.Item label="Granted Permissions">
                    {stringArrayToSentence(
                      connectionStatus?.debugResponse?.data.scopes || [],
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="UserId">
                    {connectionStatus?.debugResponse?.data.user_id}
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
          </CollapsibleConfigurationSection>
        </div>
      </Spin>

      <Divider>Chat Routing Settings</Divider>

      {/* Routing Settings */}
      <RoutingPreferencesForm connectionId={connectionId} />

      <ConnectionStartWithChatBotConfig connectionId={connectionId!} />
      <ConnectionDataEditor
        connectionId={connectionId!}
        icon={"ri-route-line"}
        title="Auto Campaign Routing"
        description="Route Conversations based on campaigns"
      >
        <div className="auto-trigger flex flex-row items-center justify-between">
          <div className="font-bold">Enable 'Auto Campaign Routing'</div>
          <Form.Item
            name={["data", "autoRouteConfig", "active"]}
            hasFeedback={false}
            valuePropName="checked"
            style={{ marginBottom: 0, marginLeft: 5 }}
          >
            <Switch />
          </Form.Item>
        </div>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const configEnabled = getFieldValue([
              "data",
              "autoRouteConfig",
              "active",
            ]);
            if (configEnabled) {
              return (
                <div className="mt-4">
                  <Form.Item
                    label=""
                    name={["data", "autoRouteConfig", "rules"]}
                  >
                    <RoutingRules />
                  </Form.Item>
                </div>
              );
            }
            return <></>;
          }}
        </Form.Item>
      </ConnectionDataEditor>

      <GeneralConnectionConfig connectionId={connectionId} withoutHeader />
    </>
  );
};
