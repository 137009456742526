import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ConfigurationEditorModal = React.lazy(
  () => import("./configuration-editor-modal.modal"),
);

const ConfigurationEditor: iModalProps<
  typeof ConfigurationEditorModal,
  {
    icon: JSX.Element;
    title: string;
    children;
    entityType:
      | "CONNECTION"
      | "CONTACT"
      | "WORKSPACE"
      | "MY_PROFILE"
      | "USER"
      | "WIDGET"
      | "SELF_SERVICE_PORTAL"
      | "DYNAMIC_FORM"
      | "MAGIC_ASSISTANT";
    entityId?: string;
    transformFormValues?: (formValues: any) => any;
    transformEntityValues?: (entity: any) => any;
    onFormValueChange?: (formValues: any) => any;
    panelRef?: any;
    ignoreNavigationWarning?: boolean;
    onSaved?: () => any;
  }
> = {
  name: "configuration-editor-modal",
  component: GenerateModalWithSuspense(ConfigurationEditorModal),
};

export default ConfigurationEditor;
