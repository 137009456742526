export interface iWidget {
  id?: string;
  organizationId: string;
  connectionId: string;
  data: iWidgetData;
  configurations: iWidgetConfiguration;
  metaData: iWidgetMetaData;
}

export interface iWidgetData {
  label: string;
  sendFollowupEmail?: boolean;
  logo?: string;
  homepageAddress?: string;
  allowedDomains?: string[];
}

export interface iWidgetMetaData {
  isActive: boolean;
  isDeleted: boolean;
  createdAt: number;
}

export interface iWidgetBlackListedClients {
  ips: string[];
  whiteListedCountries: string[];
}

export interface iShopifyPluginConfig {
  isActive: boolean;
}

export interface iKBConfig {
  isActive: boolean;
  selfServicePortalId?: string;
}

export interface iWidgetOfflineHoursDayConfig {
  startTime: string;
  endTime: string;
}

export interface iWidgetOfflineHoursDayWiseConfig {
  [day: string]: iWidgetOfflineHoursDayConfig;
}

export interface iWidgetOfflineHours {
  timezone: string;
  dayWiseConfig: iWidgetOfflineHoursDayWiseConfig;
}

export interface iWidgetAnnouncementsConfig {
  isEnabled: boolean;
  message: string;
}

export interface iWidgetConfiguration {
  appearance: iWidgetAppearanceConfiguration;
  conversationFlow: iChatConversationFlowConfig;
  locale: iChatLocale;
  faqCollections: iChatFAQCollection[];
  blackListedClients?: iWidgetBlackListedClients;
  whitelistedDomains?: string[];
  allowMultipleActiveChat?: boolean;
  automaticallyOpenChatWidgetOnNewMessages?: boolean;
  shopifyPlugin?: iShopifyPluginConfig;
  knowledgeBase?: iKBConfig;
  offlineHours?: iWidgetOfflineHours;
  hideCCLogo?: boolean;
  announcements?: iWidgetAnnouncementsConfig;
  chatBotProfile?: iBotAssistantProfile;
  disableFormTracking?: boolean;
}

export interface iBotAssistantProfile {
  name: string;
  avatar: string;
}

// Appearance Configuration

export interface iWidgetAppearanceConfiguration {
  colorPallet: iWidgetColorPallet;
  customCss?: string; // this could be inline saved to DB,
  launcherPosition?: LauncherPosition;
  eyeGrabber?: string;
  headerPattern?: string;
  chatBubbleSize?: "DEFAULT" | "COMPACT";
  chatBubbleStyle?: "rounded" | "circle";
  chatBubbleImage?: string;
  chatBubbleXOffset?: number;
  chatBubbleYOffset?: number;
  widgetSize?: "DEFAULT" | "COMPACT";
}

export interface iWidgetColorPallet {
  primary: string;
}

export enum LauncherPosition {
  RIGHT_BOTTOM = "RIGHT_BOTTOM",
  LEFT_BOTTOM = "LEFT_BOTTOM",
}

// Conversation Flow

export interface iChatConversationFlowConfig {
  greetingsMessage: iChatFlowConfig_Greetings;
  preChatForm: iChatFlowConfig_PreChatForm;
  chatRatings: iChatFlowConfig_ChatRatings;
  campaigns: iCampaign[];
}

export interface iChatFlowConfig_PreChatForm {
  isEnabled: boolean;
  fields: any[];
  allowOnlyWorkEmails?: boolean;
  privacyPolicyLink?: string;
}

export interface iChatFlowConfig_ChatRatings {
  enabled: boolean;
  allowAgentsToPrompt?: boolean;
}

export interface iChatFlowConfig_GreetingsOption {
  label: string;
  action: string;
  data: any;
}
export interface iChatFlowConfig_Greetings {
  text: string;
  coverImage?: string;
  startWithAIAssist?: boolean;
  magicAssistantId?: string;
  aiAssistantExamples?: string[];
  options: iChatFlowConfig_GreetingsOption[];
  autoTrigger: boolean;
  // Ideally if there is any widget attachment, it should be present in the attachment data
  chatBotId?: string;
}

// Locale

export interface iChatLocale {
  language: string;
  custom: {
    [phrase: string]: string;
  };
}

// FAQs

export interface iChatFAQCollection {
  label: string;
  faqList: iChatFAQ[];
}

export interface iChatFAQ {
  question: string;
  body: string;
}

// Campaigns
export interface iCampaign {
  id: string;
  isEnabled: boolean;
  data: iCampaignData;
}

export interface iCampaignOptions {
  additionalMessages?: { message: string }[];
  userId?: string;
}

export interface iCampaignData {
  triggerCondition: iCampaignTriggerCondition;

  // For Chat Prompts
  message: string;
  offerImageUrl?: string;

  quickReplies?: iChatFlowConfig_GreetingsOption[];
  options?: iCampaignOptions;
  chatBotId?: string;
}

export interface iCampaignTriggerCondition {
  preset: TriggerConditionPresets;
  data: iCampaignTriggerConditionData;
}

export interface iCampaignTriggerConditionData {
  query?: any[];
  secondsToDelay?: number;
  pageContains?: string;
  forUtmTags?: {
    tag: string;
    value: string;
  }[];
}

export enum TriggerConditionPresets {
  NONE = "NONE",
  FIVE_SECONDS_AFTER_VISIT = "FIVE_SECONDS_AFTER_VISIT",
  ON_SECOND_VISIT = "ON_SECOND_VISIT",
  ON_NAVIGATION_TO_SECOND_PAGE = "ON_NAVIGATION_TO_SECOND_PAGE",
  ON_EVENT_RECEIVED_FROM_PARENT = "ON_EVENT_RECEIVED_FROM_PARENT",
}
