import { axios, AxiosResponse } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";
import { UserTracker } from "user-tracker";
import { iCheckList, iCheckListInstance } from "./checklists-model";

export class CheckListsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.checkLists.configure(config);
  }

  checkLists = Object.assign(
    new EntityServices<iCheckList>(SDKConfig, "checklists", {
      onCreate: () => {
        UserTracker.track("CHECK_LISTS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("CHECK_LISTS - Delete", {});
      },
    }),
    {
      queryLogs: async (
        checkListId: string,
        req: { query: any; options: PaginatedOptions },
      ) => {
        const results: AxiosResponse<PaginatedResults<iCheckListInstance>> =
          await axios.post(
            this.config.basePath + `/checklists/${checkListId}/logs/query`,
            req,
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
      queryReport: async (checkListId: string, req: { query: any }) => {
        const results: AxiosResponse<{ count: number }> = await axios.post(
          this.config.basePath + `/checklists/${checkListId}/report`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
