import { Divider, Form, Input, Select } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { CountrySelector } from "components/common/country-selector/country-selector";
import { PillSelector } from "components/common/pill-selector/pill-selector";
import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { StyledSwitch } from "components/common/styled-swtich";
import {
  ColorPickerFormItem,
  ImageUploaderFormItem,
} from "components/modules/connections/channels/email/components/forms/email-template";
import { LogoUploadValidations } from "components/modules/file-management/upload-validations";
import { IndustryOptions } from "components/pages/home/onboarding/standard-onboarding/onboarding-industry-options";
import { Timezones } from "components/pages/reports/components/scheduled-reports/timezones";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import {
  selectIndustry,
  selectOrganization,
} from "store/modules/workspace/workspace.selectors";
import { AboutWorkspace } from "../sections/general-settings/components/about-workspace/about-workspace";
import { CustomPagesDomain } from "../sections/general-settings/components/custom-pages-domain";
import { DemoAccountActions } from "../sections/general-settings/components/demo-account-actions";
import { TroubleshootWorkspace } from "../sections/general-settings/components/trouble-shoot-workspace";

export const ConfigureBrandAndWorkspace = () => {
  const organization = useSelector(selectOrganization);

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const industry = useSelector(selectIndustry);
  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin p-8 overflow-x-hidden">
      <SectionHeader
        title="Brand and Workspace"
        icon={<i className="ri-building-line"></i>}
        description="Default branding, country, industry, mailing address, etc"
      />

      <Divider />
      <div className="flex-1 flex flex-col pb-8">
        <DemoAccountActions />

        <div className="configuration-container">
          <div>
            <div className="font-bold">Workspace and Branding</div>

            <AboutWorkspace />

            <TroubleshootWorkspace />

            {isAdvancedMode && (
              <>
                <ConfigurationEditorComponent
                  title="Insider"
                  description="Change the workspace to insider mode"
                  icon={"ri-award-line"}
                  // icon={"ri-briefcase-line"}
                  entityType="WORKSPACE"
                >
                  <StyledSwitch
                    label="Is Insider"
                    name={["appData", "HELP_DESK", "isInsiderAccount"]}
                  />
                </ConfigurationEditorComponent>
              </>
            )}

            <ConfigurationEditorComponent
              title="Primary Website"
              description="Your Primary website that you use as your home page for your brand"
              icon={"ri-global-fill"}
              entityType="WORKSPACE"
            >
              <Form.Item
                label="Enter a URL"
                name={["data", "website"]}
                hasFeedback
                rules={[
                  {
                    type: "url",
                    message: "Please enter a valid URL",
                    // required: true,
                  },
                ]}
              >
                <Input defaultValue={"https://"} />
              </Form.Item>
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              title="Branding"
              description="Set Logo and Brand Colour"
              icon={"ri-drop-fill"}
              entityType="WORKSPACE"
            >
              <ColorPickerFormItem
                label="Brand Color"
                fieldName={["data", "primaryColor"]}
              />
              <ImageUploaderFormItem
                label="Logo"
                fieldName={["data", "logo"]}
                entityType="WORKSPACE"
                entityId={organization?.id!}
                validations={LogoUploadValidations}
                resizeBeforeUpload={{
                  maxWidth: 200,
                  maxHeight: 200,
                  minHeight: 200,
                  minWidth: 200,
                  compressFormat: "JPEG",
                }}
              />
            </ConfigurationEditorComponent>

            <CustomPagesDomain />

            <ConfigurationEditorComponent
              title="Industry"
              description="Based on your Industry, your workspace will be tweaked"
              icon={"ri-building-3-line"}
              // icon={"ri-briefcase-line"}
              entityType="WORKSPACE"
            >
              <Form.Item
                label="Select an industry"
                name={["data", "industry"]}
                hasFeedback
              >
                <PillSelector options={IndustryOptions} className="mt-2" />
              </Form.Item>
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              title="Country"
              description="Country to be used as default country when handling phone numbers"
              icon={"ri-flag-line"}
              entityType="WORKSPACE"
            >
              <Form.Item
                label="Select a country"
                name={["data", "country"]}
                hasFeedback
              >
                <CountrySelector />
              </Form.Item>
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              title="Timezone"
              description="Timezone to be used as default timezone for scheduled reports"
              icon={"ri-global-line"}
              entityType="WORKSPACE"
            >
              <Form.Item
                name={["data", "timezone"]}
                label="Timezone"
                rules={[]}
              >
                <Select style={{ minWidth: 150 }} showSearch>
                  {Timezones.map((item) => (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              icon={"ri-map-pin-line"}
              title={"Mailing Address"}
              description={
                "The mailing address that will be used in newsletters"
              }
              entityType={"WORKSPACE"}
              entityId={organization?.id!}
            >
              <Form.Item
                label="Adders Line 1"
                name={["data", "mailingAddress", "addressLine1"]}
                rules={[{ required: true, message: "Address is required" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Adders Line 2"
                name={["data", "mailingAddress", "addressLine2"]}
                rules={[]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="City"
                name={["data", "mailingAddress", "city"]}
                rules={[{ required: true, message: "City is required" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Postal Code"
                name={["data", "mailingAddress", "postalCode"]}
                rules={[]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Country"
                name={["data", "mailingAddress", "country"]}
                rules={[{ required: true, message: "Country is required" }]}
              >
                <CountrySelector />
              </Form.Item>
            </ConfigurationEditorComponent>
          </div>
        </div>
      </div>
    </StyledScrollArea>
  );
};
