import { Modal } from "antd";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect } from "react";
import { UserTracker } from "user-tracker";
import { useUrlState } from "utils/hooks/use-url-state";
import { HelpCenterBreadCrumbBar } from "./components/help-center-breadcrumb-bar";
import { HelpCenterArticle } from "./pages/help-center-article";
import { HelpCenterCollection } from "./pages/help-center-collection";
import { HelpCenterHome } from "./pages/help-center-home";

const HelpCenterModal = ({
  visible,
  onChangeVisibility,
  zIndex,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  zIndex?: number;
}) => {
  const [selectedArticle, setSelectedArticle] = useUrlState(
    "help-center-article",
  );

  const [selectedCollection, onSelectCollection] = useUrlState(
    "help-center-collection",
  );

  useEffect(() => {
    if (visible) {
      UserTracker.track("HELP_CENTER - Visible", {});
    }
  }, [visible]);

  return (
    <Modal
      title={<HelpCenterBreadCrumbBar />}
      open={visible}
      zIndex={zIndex}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      className="help-center"
      data-click-context="Help Center Modal"
      bodyStyle={{ padding: 0, height: "calc(100vh - 150px)" }}
      width={800}
    >
      {selectedArticle ? (
        <HelpCenterArticle articleId={selectedArticle} />
      ) : selectedCollection ? (
        <HelpCenterCollection />
      ) : (
        <HelpCenterHome />
      )}
      <DarkModeBg />
    </Modal>
  );
};

export default HelpCenterModal;
