import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Switch,
  Tag,
} from "antd";
import { ModalTitle } from "components/common/modal-title";
import { useState } from "react";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { useQueryWithStore } from "store/store.hooks";

import { iWidget } from "@sdk/chat-widgets/chat-widgets.models";
import { iArticleCollection } from "@sdk/knowledge-base/knowledge-base.models";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { SimpleCollapse } from "components/common/simple-collapse/simple-collapse";
import { StyledSwitch } from "components/common/styled-swtich";
import { ImageInputWithUploader } from "components/modules/file-management/image-input-with-uploader";
import { highlightElement } from "components/modules/organization-management/general/account-configurations/use-hash-fragment";
import { MagicAssistantSelector } from "components/pages/knowledge-kit-page/magic-assistant-selector/magic-assistant-selector";
import { push } from "connected-react-router";
import { DarkModeBg } from "dark-mode-bg";
import { useDispatch } from "react-redux";
import { loadAllChatBots } from "store/modules/chat-bots/chat-bots.helpers";
import { selectAllChatBots } from "store/modules/chat-bots/chat-bots.selectors";
import { ManageTriggersInner } from "../manage-triggers/manage-chat-campaigns";

export const ChatWidgetGreetings = ({ connectionId }: { connectionId }) => {
  const { state: widget, retry: reload } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );

  const { state: chatBots, retry: reloadChatBots } = useQueryWithStore(
    selectAllChatBots,
    loadAllChatBots(),
    [],
  );

  const availableArticleCollections: iArticleCollection[] = [];

  const [isManageCampaignsVisible, setManageCampaignsVisible] = useState(false);

  const dispatch = useDispatch();

  return (
    <>
      <ConfigurationEditorComponent
        icon={"ri-chat-smile-line"}
        title="Setup Greetings and Chat Bot"
        description="Choose and configure between Simple Greetings and Chatbots"
        entityId={widget?.id!}
        entityType="WIDGET"
        transformFormValues={(
          formValues: iWidget & { greetingType: string },
        ) => {
          if (formValues.greetingType === "SIMPLE_GREETING") {
            formValues.configurations.conversationFlow.greetingsMessage.chatBotId =
              null as any;
          }
          return formValues;
        }}
        // onEntityReceived={entity=>{}}
      >
        <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
          {({ getFieldValue, setFieldsValue }) => {
            const defaultGreetingType = getFieldValue([
              "configurations",
              "conversationFlow",
              "greetingsMessage",
              "chatBotId",
            ])
              ? "CHAT_BOT"
              : "SIMPLE_GREETING";
            return (
              <>
                <Form.Item
                  name="greetingType"
                  label={
                    <div className="font-bold">
                      How would you like to greet your customers?
                    </div>
                  }
                  className="w-full"
                >
                  <Radio.Group
                    defaultValue={defaultGreetingType}
                    className="w-full"
                    onChange={() => {
                      highlightElement(
                        document.getElementById("greetings-config"),
                      );
                    }}
                  >
                    <Space direction="vertical" className="w-full">
                      <Radio
                        value="SIMPLE_GREETING"
                        className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 w-full font-bold"
                      >
                        With a Simple Greeting
                      </Radio>
                      <Radio
                        value="CHAT_BOT"
                        className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 w-full font-bold"
                      >
                        With a Chatbot
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <div id="greetings-config" className="rounded-lg">
                  <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                    {({ getFieldValue, setFieldsValue }) => {
                      const targetType =
                        getFieldValue(["greetingType"]) || defaultGreetingType;

                      return (
                        <Divider>
                          <Tag>
                            {targetType === "SIMPLE_GREETING"
                              ? "Set up greetings"
                              : "Set up Chatbot"}
                          </Tag>
                        </Divider>
                      );
                    }}
                  </Form.Item>

                  <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                    {({ getFieldValue, setFieldsValue }) => {
                      const targetType =
                        getFieldValue(["greetingType"]) || defaultGreetingType;
                      if (targetType === "SIMPLE_GREETING") {
                        return (
                          <div className="body">
                            <Form.Item
                              label=""
                              name={[
                                "configurations",
                                "conversationFlow",
                                "greetingsMessage",
                                "text",
                              ]}
                              rules={[]}
                            >
                              <Input.TextArea
                                showCount
                                maxLength={140}
                                rows={2}
                                placeholder="Eg: Hey there 👋, How can we help you?"
                              />
                            </Form.Item>

                            <div className="mb-2">
                              <SimpleCollapse title="Greetings Image (Optional)">
                                <div className="mb-4" style={{ width: 320 }}>
                                  <Form.Item
                                    label=""
                                    name={[
                                      "configurations",
                                      "conversationFlow",
                                      "greetingsMessage",
                                      "coverImage",
                                    ]}
                                    rules={[]}
                                    extra={
                                      <div>
                                        * We recommend 320px x 180px images.
                                        Please minify the images for faster
                                        loading time
                                      </div>
                                    }
                                    help={
                                      <div>
                                        <Form.Item
                                          shouldUpdate
                                          style={{ marginBottom: 0 }}
                                        >
                                          {() => {
                                            return getFieldValue([
                                              "configurations",
                                              "conversationFlow",
                                              "greetingsMessage",
                                              "coverImage",
                                            ]) ? (
                                              <Button
                                                type="link"
                                                onClick={() =>
                                                  setFieldsValue({
                                                    configurations: {
                                                      conversationFlow: {
                                                        greetingsMessage: {
                                                          coverImage: false,
                                                        },
                                                      },
                                                    },
                                                  })
                                                }
                                                className="p-0"
                                              >
                                                Clear Image
                                              </Button>
                                            ) : (
                                              <></>
                                            );
                                          }}
                                        </Form.Item>
                                      </div>
                                    }
                                  >
                                    <ImageInputWithUploader
                                      type="CONNECTION"
                                      entityId={connectionId}
                                      width={320}
                                      height={180}
                                    />
                                  </Form.Item>
                                </div>
                              </SimpleCollapse>
                            </div>

                            <div className="font-bold  ml-8">Quick Replies</div>
                            <div className="quick-replies-container ml-16 mt-4">
                              <Form.List
                                name={[
                                  "configurations",
                                  "conversationFlow",
                                  "greetingsMessage",
                                  "options",
                                ]}
                              >
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map((field) => (
                                      <Space
                                        key={field.key}
                                        style={{ display: "flex" }}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "label"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Enter a text",
                                            },
                                          ]}
                                          hasFeedback={false}
                                          style={{ marginBottom: 4 }}
                                        >
                                          <Input placeholder="Quick Reply" />
                                        </Form.Item>
                                        {/* <Form.Item
                                          {...field}
                                          name={[field.name, "action"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Select an action",
                                            },
                                          ]}
                                          hasFeedback={false}
                                          style={{ marginBottom: 4 }}
                                        >
                                          <Select style={{ minWidth: 150 }}>
                                            <Select.Option value="MESSAGE">
                                              Send as Message
                                            </Select.Option>
                                            <Select.Option value="FAQ" disabled>
                                              Show FAQ
                                            </Select.Option>
                                            <Select.Option
                                              value="ROUTE"
                                              disabled
                                            >
                                              Route to specific user group
                                            </Select.Option>
                                          </Select>
                                        </Form.Item> */}
                                        {/* User Group */}

                                        {/* <Form.Item
                                          shouldUpdate
                                          style={{ marginBottom: 4 }}
                                        >
                                          {({ getFieldValue }) => {
                                            const selectedOption =
                                              getFieldValue([
                                                "options",
                                                field.name,
                                                "action",
                                              ]);
                                            if (selectedOption === "FAQ") {
                                              return (
                                                <Form.Item
                                                  {...field}
                                                  name={[
                                                    field.name,
                                                    "data",
                                                    "FAQId",
                                                  ]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Select a FAQ",
                                                    },
                                                  ]}
                                                  hasFeedback={false}
                                                  style={{ marginBottom: 0 }}
                                                >
                                                  <Select
                                                    style={{ minWidth: 150 }}
                                                  >
                                                    {availableArticleCollections.map(
                                                      (collection) => (
                                                        <Select.Option
                                                          key={collection.id}
                                                          value={collection.id!}
                                                        >
                                                          {collection.label}
                                                        </Select.Option>
                                                      )
                                                    )}
                                                  </Select>
                                                </Form.Item>
                                              );
                                            }
                                            if (selectedOption === "ROUTE") {
                                              return (
                                                <Form.Item
                                                  {...field}
                                                  name={[
                                                    field.name,
                                                    "data",
                                                    "userGroupId",
                                                  ]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Select a User Group",
                                                    },
                                                  ]}
                                                  hasFeedback={false}
                                                  style={{ marginBottom: 0 }}
                                                >
                                                  <Select
                                                    style={{ minWidth: 150 }}
                                                  >
                                                    {availableArticleCollections.map(
                                                      (collection) => (
                                                        <Select.Option
                                                          key={collection.id}
                                                          value={collection.id!}
                                                        >
                                                          {collection.label}
                                                        </Select.Option>
                                                      )
                                                    )}
                                                  </Select>
                                                </Form.Item>
                                              );
                                            }
                                            return <div></div>;
                                          }}
                                        </Form.Item> */}
                                        <MinusCircleOutlined
                                          onClick={() => remove(field.name)}
                                        />
                                      </Space>
                                    ))}
                                    <div
                                      onClick={() =>
                                        add({
                                          action: "MESSAGE",
                                          /*type: "work", phone: ""*/
                                        })
                                      }
                                      className="cursor-pointer"
                                    >
                                      + add a quick reply
                                    </div>
                                  </>
                                )}
                              </Form.List>
                            </div>

                            <div className="auto-trigger flex flex-row items-center justify-between my-4 mt-8">
                              <div className="left">
                                <div className="font-bold">
                                  Automatically Trigger Greetings
                                </div>
                                <div className="text-gray-600 dark:text-gray-400 mb-2 mode_transition">
                                  * When 'Automatically Trigger Greetings' is
                                  enabled, greeting message will be triggered
                                  after few seconds when a visitor lands on your
                                  website.
                                </div>
                              </div>

                              <Form.Item
                                name={[
                                  "configurations",
                                  "conversationFlow",
                                  "greetingsMessage",
                                  "autoTrigger",
                                ]}
                                hasFeedback={false}
                                valuePropName="checked"
                                style={{ marginBottom: 0, marginLeft: 5 }}
                              >
                                <Switch />
                              </Form.Item>
                            </div>
                            <div className="magic-assist flex flex-row items-center justify-between my-4">
                              <div className="left">
                                <div className="font-bold">
                                  Enable Magic Assist <Tag>New</Tag>
                                </div>
                                <div className="text-gray-600 dark:text-gray-400 mb-2 mode_transition">
                                  Provide Instant Answers with your Knowledge
                                  Base Articles and Knowledge Kit Articles.
                                </div>
                                <Space>
                                  <Button
                                    type="text"
                                    icon={<i className="ri-tools-line"></i>}
                                    onClick={() => {
                                      dispatch(push(`/knowledge-base`));
                                    }}
                                  >
                                    Setup Knowledge Base
                                  </Button>
                                  <Button
                                    type="text"
                                    icon={<i className="ri-tools-line"></i>}
                                    onClick={() => {
                                      dispatch(push(`/knowledge-kit`));
                                    }}
                                  >
                                    Setup Knowledge Kit
                                  </Button>
                                </Space>
                              </div>

                              <Form.Item
                                name={[
                                  "configurations",
                                  "conversationFlow",
                                  "greetingsMessage",
                                  "startWithAIAssist",
                                ]}
                                hasFeedback={false}
                                valuePropName="checked"
                                style={{ marginBottom: 0, marginLeft: 5 }}
                              >
                                <Switch />
                              </Form.Item>
                            </div>
                            <Form.Item shouldUpdate noStyle>
                              {({ getFieldValue, setFieldsValue }) => {
                                const startWithAIAssist = getFieldValue([
                                  "configurations",
                                  "conversationFlow",
                                  "greetingsMessage",
                                  "startWithAIAssist",
                                ]);
                                if (startWithAIAssist) {
                                  return (
                                    <>
                                      <Form.Item
                                        label="Select Magic Assistant"
                                        name={[
                                          "configurations",
                                          "conversationFlow",
                                          "greetingsMessage",
                                          "magicAssistantId",
                                        ]}
                                        hasFeedback
                                      >
                                        <MagicAssistantSelector
                                          autoSelectFirstOption
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Example Queries"
                                        name={[
                                          "configurations",
                                          "conversationFlow",
                                          "greetingsMessage",
                                          "aiAssistantExamples",
                                        ]}
                                        hasFeedback
                                      >
                                        <Select
                                          mode="tags"
                                          style={{ width: "100%" }}
                                          placeholder="Example Queries"
                                          dropdownStyle={{ display: "none" }}
                                          allowClear
                                        ></Select>
                                      </Form.Item>
                                    </>
                                  );
                                }
                                return <></>;
                              }}
                            </Form.Item>
                          </div>
                        );
                      } else if (targetType === "CHAT_BOT") {
                        return (
                          <div className="mt-6">
                            <Form.Item
                              name={[
                                "configurations",
                                "conversationFlow",
                                "greetingsMessage",
                                "chatBotId",
                              ]}
                              label="Select Chatbot Flow"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a Chatbot flow",
                                },
                              ]}
                            >
                              <Select style={{ minWidth: 150 }}>
                                {chatBots.map((chatBot) => (
                                  <Select.Option
                                    key={chatBot.id}
                                    value={chatBot.id!}
                                  >
                                    {chatBot.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>

                            <StyledSwitch
                              name={[
                                "configurations",
                                "conversationFlow",
                                "greetingsMessage",
                                "autoTrigger",
                              ]}
                              label=" Automatically Trigger Chat Bot"
                              description=" * When 'Automatically Chat Bot' is
                enabled, greeting message will be triggered
                after few seconds when a visitor lands on your
                website."
                            />

                            {/* Todo */}
                          </div>
                        );
                      }
                      return <></>;
                    }}
                  </Form.Item>

                  <div className="my-8">
                    <Button
                      type="dashed"
                      block
                      onClick={() => setManageCampaignsVisible(true)}
                    >
                      Set up chat campaigns for your ads
                    </Button>
                  </div>
                </div>
              </>
            );
          }}
        </Form.Item>
      </ConfigurationEditorComponent>
      <Modal
        title={
          <ModalTitle
            title="Manage Chat Campaigns"
            icon={<i className="ri-chat-follow-up-line"></i>}
          />
        }
        open={isManageCampaignsVisible}
        footer={null}
        onCancel={() => {
          setManageCampaignsVisible(false);
        }}
        className="responsive-modal very-big-modal"
        data-click-context="Manage Chat Campaigns Modal"
      >
        <ManageTriggersInner connectionId={connectionId} />
        <DarkModeBg />
      </Modal>
    </>
  );
};
