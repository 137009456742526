import { StyledScrollArea } from "components/common/styled-scroll-area";

import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { ConfigureChatWidgetConnection } from "./channels/chat-widget/components/configure-chat-widget/configure-chat-widget-connection";
import { ConfigureMailbox } from "./channels/email/components/configure-mailbox/configure-mailbox";
import { ManageFacebookConnection } from "./channels/facebook/manage-facebook-connection/manage-facebook-connection";
import { ManageInstagramConnection } from "./channels/instagram/manage-instagram-connection/manage-instagram-connection";
import { ManageTelegramConnection } from "./channels/telegram/manage-telegram-connection/manage-telegram-connection";
import { ManageTelnyxConnection } from "./channels/telnyx/manage-telnyx-connection/manage-telnyx-connection";
import { ConfigureTwitterConnection } from "./channels/twitter/configure-twitter-connection/configure-twitter-connection";
import { ManageWACloudNativeConnection } from "./channels/whatsapp-cloud-native/manage-whatsapp-cloud-native-connection/manage-whatsapp-cloud-native-connection";
import { ManageWACloudConnection } from "./channels/whatsapp-cloud/manage-whatsapp-cloud-connection/manage-whatsapp-cloud-connection";
import { ConfigureWhatsappPrivateConnection } from "./channels/whatsapp-private/configure-whatsapp-private-connection/configure-whatsapp-private-connection";

export const ManageChannelOuter = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const connection = useSelector(selectConnectionById(connectionId));

  const Component = useMemo(() => {
    if (!connection) {
      return <></>;
    }
    if (connection.type === "EMAIL") {
      return (
        <>
          <ConfigureMailbox connectionId={connectionId} />
        </>
      );
    }
    if (connection.type === "FACEBOOK") {
      return (
        <>
          <ManageFacebookConnection connectionId={connectionId} />
        </>
      );
    }
    if (connection.type === "INSTAGRAM") {
      return <ManageInstagramConnection connectionId={connectionId} />;
    }
    if (connection.type === "TELEGRAM") {
      return <ManageTelegramConnection connectionId={connectionId} />;
    }
    if (connection.type === "TELNYX") {
      return <ManageTelnyxConnection connectionId={connectionId} />;
    }
    if (connection.type === "TWITTER") {
      return <ConfigureTwitterConnection connectionId={connectionId} />;
    }
    if (connection.type === "WEB_CHAT") {
      return <ConfigureChatWidgetConnection connectionId={connectionId} />;
    }
    if (connection.type === "WHATS_APP_CLOUD") {
      return <ManageWACloudConnection connectionId={connectionId} />;
    }
    if (connection.type === "WHATS_APP_CLOUD_NATIVE") {
      return <ManageWACloudNativeConnection connectionId={connectionId} />;
    }
    if (connection.type === "WHATS_APP_PRIVATE") {
      return <ConfigureWhatsappPrivateConnection connectionId={connectionId} />;
    }
    return <>Unidentified Connection Type: {connection.type}</>;
  }, [connection, connectionId]);

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin p-8 overflow-x-hidden">
      {Component}
    </StyledScrollArea>
  );
};
