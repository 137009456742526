import { SocketConnector } from "@sdk/@libs/socket-connector";
import {
  iConversation,
  iMessage,
  iMessageSenderType,
} from "@sdk/conversations/conversations.models";
import { iSession } from "@sdk/sessions/sessions.models";
import { NotificationHelper } from "components/common/notifications/notifications-helper";
import { find } from "lodash";

import { DeepPartial, Store } from "redux";
import { Socket } from "socket.io-client";
import {
  addNewActivity,
  patchActivity,
} from "store/modules/activities/activities.slice";
import { setSocketStatus } from "store/modules/app-state/app-state.actions";
import {
  addNewArticleCollection,
  patchArticleCollection,
} from "store/modules/article-collection/article-collection.slice";
import {
  addNewArticle,
  patchArticle,
} from "store/modules/articles/articles.slice";
import {
  addNewAutomationWorkflow,
  patchAutomationWorkflow,
} from "store/modules/automation-workflows/automation-workflows.slice";
import { addNewBadge, patchBadge } from "store/modules/badges/badges.slice";
import {
  addNewCallBackRequest,
  patchCallBackRequest,
} from "store/modules/call-back-requests/call-back-requests.slice";
import {
  addNewCallLog,
  patchCallLog,
} from "store/modules/call-logs/call-logs.slice";
import {
  addNewCampaign,
  patchCampaign,
} from "store/modules/campaigns/campaigns.slice";
import {
  addNewChatBotInstance,
  patchChatBotInstance,
} from "store/modules/chat-bot-instance/chat-bot-instance.slice";
import {
  addNewChatBot,
  patchChatBot,
} from "store/modules/chat-bots/chat-bots.slice";
import {
  addNewChatConnector,
  patchChatConnector,
} from "store/modules/chat-connectors/chat-connectors.slice";
import {
  addNewChatWidget,
  patchChatWidget,
} from "store/modules/chat-widgets/chat-widgets.slice";
import {
  addNewCheckList,
  patchCheckList,
} from "store/modules/checklists/checklists.slice";
import {
  addNewCompany,
  patchCompany,
} from "store/modules/companies/companies.slice";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import {
  addNewConnection,
  patchConnection,
} from "store/modules/connections/connections.slice";
import {
  addNewContactList,
  patchContactList,
} from "store/modules/contact-lists/contact-lists.slice";
import { loadContactById } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import {
  addNewContact,
  patchContact,
  removeContactRecord,
} from "store/modules/contacts/contacts.slice";
import { loadConversationById } from "store/modules/conversations/conversations.helpers";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import {
  addNewConversation,
  patchConversation,
} from "store/modules/conversations/conversations.slice";
import {
  addNewConversionWidget,
  patchConversionWidget,
} from "store/modules/conversion-widgets/conversion-widgets.slice";
import {
  addNewDynamicForm,
  patchDynamicForm,
} from "store/modules/dynamic-forms/dynamic-forms.slice";
import {
  addNewEngagement,
  patchEngagement,
} from "store/modules/engagements/engagements.slice";
import {
  addNewFbPost,
  patchFbPost,
} from "store/modules/fb-posts/fb-posts.slice";
import { addNewGroup, patchGroup } from "store/modules/groups/groups.slice";
import {
  addNewHolidayQuote,
  patchHolidayQuote,
} from "store/modules/holiday-quotes/holiday-quotes.slice";
import {
  addNewIgMedia,
  patchIgMedia,
} from "store/modules/ig-medias/ig-medias.slice";
import {
  addNewIndexedWebPage,
  patchIndexedWebPage,
} from "store/modules/indexed-web-pages/indexed-web-pages.slice";
import {
  addNewKnowledgeDocument,
  patchKnowledgeDocument,
} from "store/modules/knowledge-documents/knowledge-documents.slice";
import {
  addNewMagicAssistant,
  patchMagicAssistant,
} from "store/modules/magic-assistants/magic-assistants.slice";
import {
  addNewMessage,
  patchMessage,
} from "store/modules/messages/message.actions";
import {
  addNewNewsFeedItem,
  patchNewsFeedItem,
} from "store/modules/newsfeed-items/newsfeed-items.slice";
import {
  addNewNotification,
  patchNotification,
} from "store/modules/notifications/notifications.slice";
import {
  addNewOpportunity,
  patchOpportunity,
} from "store/modules/opportunities/opportunities.slice";
import {
  addNewPipelineCard,
  patchPipelineCard,
} from "store/modules/pipeline-cards/pipeline-cards.slice";
import {
  addNewProactiveCampaign,
  patchProactiveCampaign,
} from "store/modules/proactive-campaigns/proactive-campaigns.slice";
import {
  addNewReminder,
  patchReminder,
} from "store/modules/reminders/reminders.slice";
import {
  addNewScheduledMessage,
  patchScheduledMessage,
} from "store/modules/scheduled-messages/scheduled-messages.slice";
import {
  addNewScheduledReport,
  patchScheduledReport,
} from "store/modules/scheduled-reports/scheduled-reports.slice";
import {
  addNewScreenRecording,
  patchScreenRecording,
} from "store/modules/screen-recordings/screen-recordings.slice";
import {
  addNewScreenTour,
  patchScreenTour,
} from "store/modules/screen-tours/screen-tours.slice";
import {
  addNewSelfServicePortal,
  patchSelfServicePortal,
} from "store/modules/self-service-portals/self-service-portals.slice";
import { loadSessionById } from "store/modules/sessions/sessions.helpers";
import { selectActiveSessionsIds } from "store/modules/sessions/sessions.selectors";
import {
  addNewSession,
  patchSession,
} from "store/modules/sessions/sessions.slice";
import { addNewTask, patchTask } from "store/modules/tasks/tasks.slice";
import { addNewTicket, patchTicket } from "store/modules/tickets/tickets.slice";
import { addNewUser, patchUser } from "store/modules/users/users.actions";
import {
  selectCurrentUser,
  selectCurrentUserId,
  selectUser,
} from "store/modules/users/users.selectors";
import {
  patchCredits,
  patchOrganization,
  setCredits,
} from "store/modules/workspace/workspace.actions";
import {
  selectAlertAllOnNewIncomingChat,
  selectConnectionContextVisibility,
  selectPreventBurstJoinChat,
} from "store/modules/workspace/workspace.selectors";
import { GlobalEventEmitter } from "utils/global-event-emitter";
import { justWait } from "utils/just-wait";

let lastDisconnectedTime = 0;
export interface iSyncEvent {
  data: any;
  entityId: string;
  entityType: string;
  id: string;
  type: "UPDATE" | "NEW";
}

export interface iSpecialEvent {
  data: any;
  entity: string;
  id: string;
  name: string;
  organizationId: string;
}

export const HandleSocketEvents = (socket: Socket<any, any>, store: Store) => {
  socket.on("connect", () => {
    console.log("HSE: Connected");
    // Todo: Dispatch Action
    store.dispatch(setSocketStatus(true));
  });

  socket.on("reconnect", () => {
    console.log("HSE: Reconnected");
    // Todo: Dispatch Action
    // Check the last connected time gap and if the gap is bigger than 7 seconds, then reload
    store.dispatch(setSocketStatus(true));
    if (
      lastDisconnectedTime + 15 * 1000 < Date.now() &&
      !window.location.href.includes("localhost")
    ) {
      window.location.reload();
    }
  });

  socket.on("disconnect", (reason) => {
    console.log("HSE: Disconnected");
    lastDisconnectedTime = Date.now();
    setTimeout(() => {
      if (!SocketConnector.socket?.connected) {
        // Todo: Dispatch Action
        store.dispatch(setSocketStatus(false));
      }
    }, 4000);
  });

  socket.on("SYNC", (data: iSyncEvent) => {
    // console.log("SYNC", data);
    if (data.type === "UPDATE") {
      switch (data.entityType) {
        case "ORGANIZATION": {
          store.dispatch(
            patchOrganization({ ...data.data, id: data.entityId }),
          );
          break;
        }
        case "CREDIT_RECORD": {
          store.dispatch(patchCredits({ ...data.data, id: data.entityId }));
          break;
        }
        case "USER": {
          store.dispatch(patchUser({ ...data.data, id: data.entityId }));
          break;
        }
        case "USER_GROUP": {
          store.dispatch(patchGroup({ ...data.data, id: data.entityId }));
          break;
        }

        case "CONTACT": {
          store.dispatch(patchContact({ ...data.data, id: data.entityId }));
          break;
        }
        case "ENGAGEMENT": {
          store.dispatch(patchEngagement({ ...data.data, id: data.entityId }));
          break;
        }
        case "COMPANY": {
          store.dispatch(patchCompany({ ...data.data, id: data.entityId }));
          break;
        }
        case "OPPORTUNITY": {
          store.dispatch(patchOpportunity({ ...data.data, id: data.entityId }));
          break;
        }
        case "ACTIVITY": {
          store.dispatch(patchActivity({ ...data.data, id: data.entityId }));
          break;
        }
        case "CONTACT_LISTS": {
          store.dispatch(patchContactList({ ...data.data, id: data.entityId }));
          break;
        }
        case "CONVERSATION": {
          if (selectConversationById(data.entityId)(store.getState())) {
            const conversationUpdates = data.data as Partial<iConversation>;
            store.dispatch(
              patchConversation({ ...data.data, id: data.entityId }),
            );
            if (
              conversationUpdates?.members &&
              conversationUpdates?.members.length > 0
            ) {
              // Member has been updated. This essentially means that someone joined the conversation
              // Remove the queued chat notification
              NotificationHelper.dismiss.clientIsInQueueByConversationId(
                data.entityId,
              );
            }
          } else {
            loadConversationById(data.entityId)()(store)
              .then((conversation) => {
                // Could be an old conversation that got activated because of new message
                // If it's current user's chat and it has a new message, then fire a notification
                const currentUserId = selectCurrentUserId(store.getState());

                if (find(conversation.members, { userId: currentUserId })) {
                  if (
                    conversation.metaData.lastMessage.isByClient &&
                    conversation.metaData.lastMessage.timestamp + 10000 >
                      Date.now()
                  ) {
                    console.log("Show New message Notification");
                    const senderName =
                      selectContactById(conversation.contactId)(
                        store.getState(),
                      )?.data.firstName || "Customer";

                    const contextVisibility = selectConnectionContextVisibility(
                      store.getState(),
                    );

                    const connection = selectConnectionById(
                      conversation.connectionId,
                    )(store.getState());

                    NotificationHelper.fire.onNewMessage({
                      connectionName: contextVisibility
                        ? connection?.label
                        : undefined,
                      senderName,
                      message: {
                        conversationId: conversation.id!,
                        message: conversation.metaData.lastMessage.text,
                      },
                    });
                  }
                }
              })
              .catch((e) => {
                console.log(
                  "Error while loading a conversation",
                  e,
                  data.entityId,
                );
              });
          }
          break;
        }
        case "CONNECTION": {
          store.dispatch(patchConnection({ ...data.data, id: data.entityId }));
          break;
        }
        case "MESSAGE": {
          store.dispatch(patchMessage({ ...data.data, id: data.entityId }));
          break;
        }
        case "NOTIFICATION": {
          // Todo
          store.dispatch(
            patchNotification({ ...data.data, id: data.entityId }),
          );
          break;
        }
        // LIVE CHAT
        case "WIDGET": {
          store.dispatch(patchChatWidget({ ...data.data, id: data.entityId }));
          break;
        }
        case "SESSION": {
          store.dispatch(patchSession({ ...data.data, id: data.entityId }));
          if ((data.data as DeepPartial<iSession>).metaData?.active) {
            // if its active add to session list
            const activeSessions = selectActiveSessionsIds(store.getState());
            if (!activeSessions.includes(data.entityId)) {
              loadSessionById(data.entityId)()(store).then((session) => {
                if (session) {
                  store.dispatch(addNewSession(session));
                }
              });
            }
          }
          break;
        }
        // FACEBOOK
        case "FB_POST": {
          store.dispatch(patchFbPost({ ...data.data, id: data.entityId }));
          break;
        }
        case "FB_POST_COMMENT": {
          GlobalEventEmitter.emit("FB_COMMENT_CHANGE", {
            type: "EDIT",
            data: { ...data.data, id: data.entityId },
          });
          break;
        }
        // INSTAGRAM
        case "IG_MEDIA": {
          store.dispatch(patchIgMedia({ ...data.data, id: data.entityId }));
          break;
        }
        case "IG_MEDIA_COMMENT": {
          GlobalEventEmitter.emit("IG_COMMENT_CHANGE", {
            type: "EDIT",
            data: { ...data.data, id: data.entityId },
          });
          break;
        }
        // Knowledge base
        case "ARTICLE_COLLECTION": {
          store.dispatch(
            patchArticleCollection({ ...data.data, id: data.entityId }),
          );
          break;
        }
        case "ARTICLE": {
          store.dispatch(patchArticle({ ...data.data, id: data.entityId }));
          break;
        }
        case "SELF_SERVICE_PORTAL": {
          store.dispatch(
            patchSelfServicePortal({ ...data.data, id: data.entityId }),
          );
          break;
        }
        // Scheduled Message
        case "SCHEDULED_MESSAGE": {
          store.dispatch(
            patchScheduledMessage({ ...data.data, id: data.entityId }),
          );
          break;
        }
        // Scheduled Record
        case "SCHEDULED_REPORT": {
          store.dispatch(
            patchScheduledReport({ ...data.data, id: data.entityId }),
          );
          break;
        }

        // Chatbots
        case "CHAT_BOT": {
          store.dispatch(patchChatBot({ ...data.data, id: data.entityId }));
          break;
        }
        // Bot Instances
        case "CHAT_BOT_INSTANCE": {
          store.dispatch(
            patchChatBotInstance({ ...data.data, id: data.entityId }),
          );
          break;
        }
        // Chat Connectors
        case "CHAT_CONNECTOR": {
          store.dispatch(
            patchChatConnector({ ...data.data, id: data.entityId }),
          );
          break;
        }
        // Campaigns
        case "CAMPAIGN": {
          store.dispatch(patchCampaign({ ...data.data, id: data.entityId }));
          break;
        }
        // Conversion Widget
        case "CONVERSION_WIDGET": {
          store.dispatch(
            patchConversionWidget({ ...data.data, id: data.entityId }),
          );
          break;
        }
        // Tasks
        case "TASK": {
          store.dispatch(patchTask({ ...data.data, id: data.entityId }));
          break;
        }
        // Dynamic Form
        case "DYNAMIC_FORM": {
          store.dispatch(patchDynamicForm({ ...data.data, id: data.entityId }));
          break;
        }
        // Telnyx Phone
        case "CALL_LOG": {
          store.dispatch(patchCallLog({ ...data.data, id: data.entityId }));
          break;
        }
        case "CALL_BACK_REQUEST": {
          store.dispatch(
            patchCallBackRequest({ ...data.data, id: data.entityId }),
          );
          break;
        }
        // Automation Workflows
        case "AUTOMATION_WORKFLOW": {
          store.dispatch(
            patchAutomationWorkflow({ ...data.data, id: data.entityId }),
          );
          break;
        }
        // Pipeline Cards
        case "PIPELINE_CARD": {
          store.dispatch(
            patchPipelineCard({ ...data.data, id: data.entityId }),
          );
          break;
        }
        // Tickets
        case "TICKET": {
          store.dispatch(patchTicket({ ...data.data, id: data.entityId }));
          break;
        }
        // Reminders
        case "REMINDER": {
          store.dispatch(patchReminder({ ...data.data, id: data.entityId }));
          break;
        }
        // Badge Records
        case "BADGE_RECORD": {
          store.dispatch(patchBadge({ ...data.data, id: data.entityId }));
          break;
        }
        // Holiday Quotes
        case "HOLIDAY_QUOTE": {
          store.dispatch(
            patchHolidayQuote({ ...data.data, id: data.entityId }),
          );
          break;
        }
        // Magic Assistant
        case "MAGIC_ASSISTANT": {
          store.dispatch(
            patchMagicAssistant({ ...data.data, id: data.entityId }),
          );
          break;
        }
        // Knowledge Documents
        case "KNOWLEDGE_DOCUMENT": {
          store.dispatch(
            patchKnowledgeDocument({ ...data.data, id: data.entityId }),
          );
          break;
        }
        case "INDEXED_WEB_PAGE": {
          store.dispatch(
            patchIndexedWebPage({ ...data.data, id: data.entityId }),
          );
          break;
        }
        case "SCREEN_RECORDING": {
          store.dispatch(
            patchScreenRecording({ ...data.data, id: data.entityId }),
          );
          break;
        }
        case "SCREEN_TOUR": {
          store.dispatch(patchScreenTour({ ...data.data, id: data.entityId }));
          break;
        }
        case "PROACTIVE_CAMPAIGN": {
          store.dispatch(
            patchProactiveCampaign({ ...data.data, id: data.entityId }),
          );
          break;
        }
        case "NEWSFEED_ITEM": {
          store.dispatch(
            patchNewsFeedItem({ ...data.data, id: data.entityId }),
          );
          break;
        }
        case "CHECKLIST": {
          store.dispatch(patchCheckList({ ...data.data, id: data.entityId }));
          break;
        }
        default: {
          console.log("Unidentified entity", data.entityType);
        }
      }
    } else if (data.type === "NEW") {
      switch (data.entityType) {
        case "USER": {
          store.dispatch(addNewUser(data.data));
          break;
        }
        case "USER_GROUP": {
          store.dispatch(addNewGroup(data.data));
          break;
        }
        case "CREDIT_RECORD": {
          store.dispatch(setCredits(data.data));
          break;
        }
        case "TRANSACTION": {
          store.dispatch(
            patchCredits({ amount: data.data.balanceAtTransaction }),
          );
          GlobalEventEmitter.emit("NEW_TRANSACTION", data.data);
          break;
        }
        case "CONTACT": {
          store.dispatch(addNewContact(data.data));
          break;
        }
        case "ENGAGEMENT": {
          store.dispatch(addNewEngagement(data.data));
          break;
        }
        case "ACTIVITY": {
          store.dispatch(addNewActivity(data.data));
          break;
        }
        case "COMPANY": {
          store.dispatch(addNewCompany(data.data));
          break;
        }
        case "OPPORTUNITY": {
          store.dispatch(addNewOpportunity(data.data));
          break;
        }
        case "CONTACT_LISTS": {
          store.dispatch(addNewContactList(data.data));
          break;
        }
        case "CONNECTION": {
          store.dispatch(addNewConnection(data.data));
          break;
        }
        case "CONVERSATION": {
          // console.log('New Conversation Event');
          const conversation = data.data;
          loadContactById(conversation.contactId)()(store).then((contact) => {
            store.dispatch(
              addNewConversation({ ...conversation, id: data.entityId }),
            );
            const showNotifications = selectAlertAllOnNewIncomingChat(
              store.getState(),
            );
            // console.log('showNotifications', showNotifications);
            if (showNotifications && contact) {
              const contextVisibility = selectConnectionContextVisibility(
                store.getState(),
              );
              const connection = selectConnectionById(
                conversation.connectionId,
              )(store.getState());

              const preventBurstJoinChats = selectPreventBurstJoinChat(
                store.getState(),
              );

              NotificationHelper.fire.onQueuedConversation({
                connectionName: contextVisibility
                  ? connection?.label
                  : undefined,
                clientName: contact.data.firstName! || "Customer",
                conversationId: data.entityId,
                showJoinChatOption: preventBurstJoinChats,
              });
            }
          });
          break;
        }

        case "MESSAGE": {
          const message = data.data as iMessage;
          const currentUser = selectCurrentUser(store.getState());
          if (message.from.id === currentUser?.id) {
            // Delaying to finish animation
            (async () => {
              await justWait(1800);
              store.dispatch(addNewMessage(data.data));
            })();
          } else {
            store.dispatch(addNewMessage(data.data));
          }

          (async () => {
            const message = data.data as iMessage;
            const currentUser = selectCurrentUser(store.getState());
            let senderName;
            // Todo
            let conversation = selectConversationById(message.conversationId)(
              store.getState(),
            );
            if (!conversation) {
              await loadConversationById(message.conversationId)()(store);
              conversation = selectConversationById(message.conversationId)(
                store.getState(),
              );
            }

            if (message.from.senderType === iMessageSenderType.USER) {
              senderName =
                message.from.id === "cc"
                  ? "System"
                  : message.from.id.includes(message.organizationId)
                  ? selectUser(message.from.id)(store.getState())?.data
                      ?.firstName || "System"
                  : "Facebook User";
            } else if (message.from.senderType === iMessageSenderType.CLIENT) {
              senderName = selectContactById(message.from.id)(store.getState())
                ?.data.firstName;
            } else {
              senderName = "System";
            }
            // Fire notification only if the message is from different user
            if (
              currentUser &&
              currentUser.id !== message.from.id &&
              senderName !== "System"
            ) {
              // Fire Notification only if the user is a member
              if (
                find(conversation.members, {
                  userId: currentUser.id,
                })
              ) {
                NotificationHelper.fire.onNewMessage({
                  senderName,
                  message,
                });
              }
            }
          })();

          break;
        }
        case "NOTIFICATION": {
          store.dispatch(addNewNotification(data.data));
          break;
        }
        // LIVE CHAT
        case "WIDGET": {
          store.dispatch(addNewChatWidget(data.data));
          break;
        }
        case "SESSION": {
          store.dispatch(addNewSession(data.data));
          break;
        }
        // FACEBOOK
        case "FB_POST": {
          store.dispatch(addNewFbPost(data.data));
          break;
        }
        case "FB_POST_COMMENT": {
          GlobalEventEmitter.emit("FB_COMMENT_CHANGE", {
            type: "ADD",
            data: { ...data.data, id: data.entityId },
          });
          break;
        }
        // INSTAGRAM
        case "IG_MEDIA": {
          store.dispatch(addNewIgMedia(data.data));
          break;
        }
        case "IG_MEDIA_COMMENT": {
          GlobalEventEmitter.emit("IG_COMMENT_CHANGE", {
            type: "ADD",
            data: { ...data.data, id: data.entityId },
          });
          break;
        }
        // Knowledge base
        case "ARTICLE_COLLECTION": {
          store.dispatch(addNewArticleCollection(data.data));
          break;
        }
        case "ARTICLE": {
          store.dispatch(addNewArticle(data.data));
          break;
        }
        case "SELF_SERVICE_PORTAL": {
          store.dispatch(addNewSelfServicePortal(data.data));
          break;
        }
        // Scheduled Message
        case "SCHEDULED_MESSAGE": {
          store.dispatch(addNewScheduledMessage(data.data));
          break;
        }
        // Scheduled Report
        case "SCHEDULED_REPORT": {
          store.dispatch(addNewScheduledReport(data.data));
          break;
        }
        // Chatbots
        case "CHAT_BOT": {
          store.dispatch(addNewChatBot(data.data));
          break;
        }
        // Bot Instances
        case "CHAT_BOT_INSTANCE": {
          store.dispatch(addNewChatBotInstance(data.data));
          break;
        }
        // Chat Connectors
        case "CHAT_CONNECTOR": {
          store.dispatch(addNewChatConnector(data.data));
          break;
        }

        // Cmapigns
        case "CAMPAIGN": {
          store.dispatch(addNewCampaign(data.data));
          break;
        }
        // Conversion Widget
        case "CONVERSION_WIDGET": {
          store.dispatch(addNewConversionWidget(data.data));
          break;
        }

        // Task
        case "TASK": {
          store.dispatch(addNewTask(data.data));
          break;
        }

        // Dynamic Form
        case "DYNAMIC_FORM": {
          store.dispatch(addNewDynamicForm(data.data));
          break;
        }
        // Telnyx Phone
        case "CALL_LOG": {
          store.dispatch(addNewCallLog(data.data));
          break;
        }
        case "CALL_BACK_REQUEST": {
          store.dispatch(addNewCallBackRequest(data.data));
          break;
        }
        // Automation Workflows
        case "AUTOMATION_WORKFLOW": {
          store.dispatch(addNewAutomationWorkflow(data.data));
          break;
        }
        // Pipeline Cards
        case "PIPELINE_CARD": {
          store.dispatch(addNewPipelineCard(data.data));
          break;
        }
        // Tickets
        case "TICKET": {
          store.dispatch(addNewTicket(data.data));
          break;
        }
        // Reminders
        case "REMINDER": {
          store.dispatch(addNewReminder(data.data));
          break;
        }
        // Badge Records
        case "BADGE_RECORD": {
          GlobalEventEmitter.emit("BADGE_EARNED", data.data);
          store.dispatch(addNewBadge(data.data));
          break;
        }
        // Holiday Quotes
        case "HOLIDAY_QUOTE": {
          store.dispatch(addNewHolidayQuote(data.data));
          break;
        }
        // Magic Assistant
        case "MAGIC_ASSISTANT": {
          store.dispatch(
            addNewMagicAssistant({ ...data.data, id: data.entityId }),
          );
          break;
        }
        // Knowledge Documents
        case "KNOWLEDGE_DOCUMENT": {
          store.dispatch(
            addNewKnowledgeDocument({ ...data.data, id: data.entityId }),
          );
          break;
        }
        case "INDEXED_WEB_PAGE": {
          store.dispatch(
            addNewIndexedWebPage({ ...data.data, id: data.entityId }),
          );
          break;
        }
        case "SCREEN_RECORDING": {
          store.dispatch(
            addNewScreenRecording({ ...data.data, id: data.entityId }),
          );
          break;
        }
        case "SCREEN_TOUR": {
          store.dispatch(addNewScreenTour({ ...data.data, id: data.entityId }));
          break;
        }
        case "PROACTIVE_CAMPAIGN": {
          store.dispatch(
            addNewProactiveCampaign({ ...data.data, id: data.entityId }),
          );
          break;
        }
        case "NEWSFEED_ITEM": {
          store.dispatch(
            addNewNewsFeedItem({ ...data.data, id: data.entityId }),
          );
          break;
        }
        case "CHECKLIST": {
          store.dispatch(addNewCheckList({ ...data.data, id: data.entityId }));
          break;
        }
        default: {
          console.log("Unidentified entity", data.entityType);
        }
      }
    }
  });

  socket.on("SPECIAL_EVENTS", (data: iSpecialEvent) => {
    // console.log("SPECIAL_EVENTS", data);
    if (data.entity === "CONVERSATIONS") {
      switch (data.name) {
        case "MEMBERS_ADDED":
        case "TRANSFER_REQUEST_ACCEPTED": {
          const conversation = data.data.entity as iConversation;
          console.log("conversation needs to be fetched", conversation);
          if (!selectConversationById(conversation.id!)(store.getState())) {
            loadConversationById(conversation.id!)()(store).catch((e) => {
              console.log(
                "Error while loading a conversation",
                e,
                conversation.id!,
              );
            });
          }
          break;
        }
        default: {
          // Nothing
        }
      }
    } else if (data.entity === "CONTACT") {
      switch (data.name) {
        case "CONTACT_MERGED": {
          console.log("CONTACT_MERGED");
          const { oldContactId, newContactId } = data.data as {
            oldContactId: string;
            newContactId: string;
          };
          // remove merged contact from store
          store.dispatch(removeContactRecord(oldContactId));
          // refresh merged contact
          loadContactById(newContactId)()(store, true);
          // refresh after a second too
          setTimeout(() => {
            loadContactById(newContactId)()(store, true);
          }, 2000);

          break;
        }
        default: {
          // Nothing
        }
      }
    }
  });

  socket.on("*", (eventName, data) => {
    const event = {
      name: eventName,
      payload: data,
    };
    if (event.name === "ABC") {
      // Todo:
    }
  });

  socket.on("USER_PRESENCE", (data: any) => {
    //
  });
};
