export const replaceKeyBindingToActualKey = (str: string) => {
  const isMac =
    typeof navigator === "object" &&
    /Mac|iPod|iPhone|iPad/.test(navigator.platform);
  return str
    .replace("$mod", isMac ? "⌘" : "Ctrl")
    .replace("Meta", "⌘")
    .replace("Alt", isMac ? "⌥" : "Alt")
    .replace("Control", isMac ? "^" : "Ctrl")
    .replace("Escape", "Esc");
};
