import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iAppFeatureRequest } from "@sdk/app-feature-requests/app-feature-requests-model";
import React from "react";

const EditFeatureRequestModal = React.lazy(
  () => import("./edit-feature-request.modal"),
);

const EditFeatureRequest: iModalProps<
  typeof EditFeatureRequestModal,
  {
    feature: iAppFeatureRequest;
    onEdited: (data: iAppFeatureRequest) => any;
  }
> = {
  name: "edit-feature-request",
  component: GenerateModalWithSuspense(EditFeatureRequestModal),
};

export default EditFeatureRequest;
