import { iOrganization } from "@sdk/user-management/user-management.models";
import { Checkbox, Divider, Form, Input, InputNumber, Select } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { CountrySelector } from "components/common/country-selector/country-selector";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { StyledSwitch } from "components/common/styled-swtich";
import {
  ColorPickerFormItem,
  ImageUploaderFormItem,
} from "components/modules/connections/channels/email/components/forms/email-template";
import { MigrateData } from "components/modules/organization-management/general/migrate-data/migrate-data";

import { PillSelector } from "components/common/pill-selector/pill-selector";
import { ConnectionSelector } from "components/modules/conversations/components/connection-selector/connection-selector";
import { LogoUploadValidations } from "components/modules/file-management/upload-validations";
import { FlightItineraryTemplateTypes } from "components/pages/conversations/pnr-converter/flight-itenerary-template-types";
import { IndustryOptions } from "components/pages/home/onboarding/standard-onboarding/onboarding-industry-options";
import { Timezones } from "components/pages/reports/components/scheduled-reports/timezones";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import {
  selectIndustry,
  selectOrganization,
} from "store/modules/workspace/workspace.selectors";
import { isIpAddress } from "utils/is-ip-address";
import { stringArrayToSentence } from "utils/string-array-to-sentence";
import { BetaFeatures } from "./beta-features";
import { AboutWorkspace } from "./components/about-workspace/about-workspace";
import { CustomPagesDomain } from "./components/custom-pages-domain";
import { DemoAccountActions } from "./components/demo-account-actions";
import { TroubleshootWorkspace } from "./components/trouble-shoot-workspace";
import { FeatureThatCanBeLimited } from "./features-limited";

export const GeneralSettings = () => {
  const organization = useSelector(selectOrganization);

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const industry = useSelector(selectIndustry);
  return (
    <>
      <StyledScrollArea className="w-full h-full flex-1 animated fadeInLeftMin">
        <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
          <SectionHeader
            title="General Settings"
            icon={<i className="ri-building-line"></i>}
            description="Access and change organization-wide settings"
          />
          <Divider />

          <div className="flex-1 flex flex-col pb-8">
            <DemoAccountActions />

            <div className="configuration-container">
              <div>
                <div className="font-bold">Workspace and Branding</div>

                <AboutWorkspace />

                <TroubleshootWorkspace />

                {isAdvancedMode && (
                  <>
                    <ConfigurationEditorComponent
                      title="Insider"
                      description="Change the workspace to insider mode"
                      icon={"ri-award-line"}
                      // icon={"ri-briefcase-line"}
                      entityType="WORKSPACE"
                    >
                      <StyledSwitch
                        label="Is Insider"
                        name={["appData", "HELP_DESK", "isInsiderAccount"]}
                      />
                    </ConfigurationEditorComponent>
                  </>
                )}

                <ConfigurationEditorComponent
                  title="Primary Website"
                  description="Your Primary website that you use as your home page for your brand"
                  icon={"ri-global-fill"}
                  entityType="WORKSPACE"
                >
                  <Form.Item
                    label="Enter a URL"
                    name={["data", "website"]}
                    hasFeedback
                    rules={[
                      {
                        type: "url",
                        message: "Please enter a valid URL",
                        // required: true,
                      },
                    ]}
                  >
                    <Input defaultValue={"https://"} />
                  </Form.Item>
                </ConfigurationEditorComponent>

                <ConfigurationEditorComponent
                  title="Branding"
                  description="Set Logo and Brand Colour"
                  icon={"ri-drop-fill"}
                  entityType="WORKSPACE"
                >
                  <ColorPickerFormItem
                    label="Brand Color"
                    fieldName={["data", "primaryColor"]}
                  />
                  <ImageUploaderFormItem
                    label="Logo"
                    fieldName={["data", "logo"]}
                    entityType="WORKSPACE"
                    entityId={organization?.id!}
                    validations={LogoUploadValidations}
                  />
                </ConfigurationEditorComponent>

                <CustomPagesDomain />

                <ConfigurationEditorComponent
                  title="Industry"
                  description="Based on your Industry, your workspace will be tweaked"
                  icon={"ri-building-3-line"}
                  // icon={"ri-briefcase-line"}
                  entityType="WORKSPACE"
                >
                  <Form.Item
                    label="Select an industry"
                    name={["data", "industry"]}
                    hasFeedback
                  >
                    <PillSelector options={IndustryOptions} className="mt-2" />
                  </Form.Item>
                </ConfigurationEditorComponent>

                <ConfigurationEditorComponent
                  title="Country"
                  description="Country to be used as default country when handling phone numbers"
                  icon={"ri-flag-line"}
                  entityType="WORKSPACE"
                >
                  <Form.Item
                    label="Select a country"
                    name={["data", "country"]}
                    hasFeedback
                  >
                    <CountrySelector />
                  </Form.Item>
                </ConfigurationEditorComponent>

                <ConfigurationEditorComponent
                  title="Timezone"
                  description="Timezone to be used as default timezone for scheduled reports"
                  icon={"ri-global-line"}
                  entityType="WORKSPACE"
                >
                  <Form.Item
                    name={["data", "timezone"]}
                    label="Timezone"
                    rules={[]}
                  >
                    <Select style={{ minWidth: 150 }} showSearch>
                      {Timezones.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </ConfigurationEditorComponent>

                <ConfigurationEditorComponent
                  icon={"ri-map-pin-line"}
                  title={"Mailing Address"}
                  description={
                    "The mailing address that will be used in newsletters"
                  }
                  entityType={"WORKSPACE"}
                  entityId={organization?.id!}
                >
                  <Form.Item
                    label="Adders Line 1"
                    name={["data", "mailingAddress", "addressLine1"]}
                    rules={[{ required: true, message: "Address is required" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Adders Line 2"
                    name={["data", "mailingAddress", "addressLine2"]}
                    rules={[]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="City"
                    name={["data", "mailingAddress", "city"]}
                    rules={[{ required: true, message: "City is required" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Postal Code"
                    name={["data", "mailingAddress", "postalCode"]}
                    rules={[]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Country"
                    name={["data", "mailingAddress", "country"]}
                    rules={[{ required: true, message: "Country is required" }]}
                  >
                    <CountrySelector />
                  </Form.Item>
                </ConfigurationEditorComponent>

                <div className="font-bold">Access and Availability</div>

                <ConfigurationEditorComponent
                  icon="ri-shield-check-line"
                  title="Whitelisted IPs"
                  description="Choose which IPs can login to your ClickConnector's workspace"
                  entityType="WORKSPACE"
                >
                  <Form.Item
                    label="Whitelisted IPs"
                    name={["data", "whiteListedAccessIPs"]}
                    hasFeedback
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, ips) {
                          if (!ips || ips.length === 0) {
                            return Promise.resolve();
                          }
                          const invalidIps = ips.filter(
                            (ip) => !isIpAddress(ip),
                          );
                          if (invalidIps.length > 0) {
                            return Promise.reject(
                              `The following Ips are Invalid: ${stringArrayToSentence(
                                invalidIps,
                              )}`,
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Whitelisted IPs"
                      dropdownStyle={{ display: "none" }}
                      allowClear
                    ></Select>
                  </Form.Item>
                </ConfigurationEditorComponent>

                <ConfigurationEditorComponent
                  icon={"ri-timer-line"}
                  title={"Smart Availability"}
                  description={`Automatically set user's availability to "away" after a period of inactivity`}
                  entityType="WORKSPACE"
                  transformFormValues={(values) => ({
                    appData: {
                      HELP_DESK: {
                        autoKickUsers: {
                          active:
                            values.appData?.HELP_DESK?.autoKickUsers?.active,
                          timeout:
                            (values.appData.HELP_DESK?.autoKickUsers?.timeout ||
                              0) *
                            (60 * 1000),
                        },
                      },
                    },
                  })}
                  transformEntityValues={(organization: iOrganization) => ({
                    appData: {
                      HELP_DESK: {
                        autoKickUsers: {
                          active:
                            organization?.appData?.HELP_DESK?.autoKickUsers
                              ?.active,
                          timeout:
                            (organization?.appData?.HELP_DESK?.autoKickUsers
                              ?.timeout || 0) /
                            (60 * 1000),
                        },
                      },
                    },
                  })}
                >
                  <div className="mb-3">
                    <StyledSwitch
                      label="Active"
                      name={["appData", "HELP_DESK", "autoKickUsers", "active"]}
                    />
                  </div>
                  <Form.Item
                    label="Timeout (minutes):"
                    name={["appData", "HELP_DESK", "autoKickUsers", "timeout"]}
                    rules={[{ required: true }]}
                    className="m-0"
                  >
                    <InputNumber className="w-full" min={5} max={60} />
                  </Form.Item>
                </ConfigurationEditorComponent>

                <ConfigurationEditorComponent
                  icon={"ri-lock-password-line"}
                  title={"Stronger Password"}
                  description={`Enforce users to set strong password`}
                  entityType="WORKSPACE"
                >
                  <div className="mb-3">
                    <StyledSwitch
                      label="Enforce Strong Password"
                      name={[
                        "appData",
                        "HELP_DESK",
                        "shouldEnforceStrongerPassword",
                      ]}
                    />
                    <div className="text-gray-600">
                      This enforcement will only be enabled when user tries to
                      set a new password
                    </div>
                  </div>
                </ConfigurationEditorComponent>

                <ConfigurationEditorComponent
                  icon="ri-forbid-line"
                  title="Restrict ClickConnector Features by User Roles"
                  description="Set granular user role level access to ClickConnector features"
                  entityType="WORKSPACE"
                >
                  * By default below features are available to all user roles
                  <table className="simple-table-style w-full my-8">
                    <thead>
                      <tr>
                        <th className="font-bold">Feature</th>
                        <th className="font-bold">Team Member</th>
                        <th className="font-bold">Manager</th>
                      </tr>
                    </thead>
                    <tbody>
                      {FeatureThatCanBeLimited.map((item, index) => {
                        return (
                          <tr key={item.key}>
                            <td>{item.label}</td>
                            <td>
                              <Form.Item
                                label=""
                                name={[
                                  "appData",
                                  "HELP_DESK",
                                  "featureRestrictionConfigs",
                                  item.key,
                                  "team",
                                ]}
                                rules={[]}
                                className="m-0"
                              >
                                <CheckBoxWithDeleteIcon />
                              </Form.Item>
                            </td>
                            <td>
                              {" "}
                              <Form.Item
                                label=""
                                name={[
                                  "appData",
                                  "HELP_DESK",
                                  "featureRestrictionConfigs",
                                  item.key,
                                  "manager",
                                ]}
                                rules={[]}
                                className="m-0"
                              >
                                <CheckBoxWithDeleteIcon />
                              </Form.Item>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </ConfigurationEditorComponent>
                {isAdvancedMode && (
                  <ConfigurationEditorComponent
                    icon="ri-gift-line"
                    title="Enable Beta Features"
                    description="Set granular user role level access to ClickConnector Insider features"
                    entityType="WORKSPACE"
                  >
                    * By default below features are not available to any user
                    roles
                    <table className="simple-table-style w-full my-8">
                      <thead>
                        <tr>
                          <th className="font-bold">Feature</th>
                          <th className="font-bold">Team Member</th>
                          <th className="font-bold">Manager</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BetaFeatures.map((item, index) => {
                          return (
                            <tr key={item.key}>
                              <td>{item.label}</td>
                              <td>
                                <Form.Item
                                  label=""
                                  name={[
                                    "appData",
                                    "HELP_DESK",
                                    "betaFeaturesConfig",
                                    item.key,
                                    "Team Member",
                                  ]}
                                  rules={[]}
                                  className="m-0"
                                  valuePropName="checked"
                                >
                                  <Checkbox />
                                </Form.Item>
                              </td>
                              <td>
                                {" "}
                                <Form.Item
                                  label=""
                                  name={[
                                    "appData",
                                    "HELP_DESK",
                                    "betaFeaturesConfig",
                                    item.key,
                                    "Manager",
                                  ]}
                                  rules={[]}
                                  className="m-0"
                                  valuePropName="checked"
                                >
                                  <Checkbox />
                                </Form.Item>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </ConfigurationEditorComponent>
                )}
              </div>

              <div className="font-bold">Other</div>

              <MigrateData />

              {/* Holiday Quotes Settings */}
              {industry === "Travel and Tourism" && (
                <>
                  <ConfigurationEditorComponent
                    icon="ri-article-line"
                    title="Holiday Quotes Configuration"
                    description="Configure Holiday Quote Generator and default settings"
                    entityType="WORKSPACE"
                  >
                    <StyledSwitch
                      label="Enable Sharing Block by Default"
                      name={[
                        "appData",
                        "HELP_DESK",
                        "holidayQuoteConfig",
                        "enableSharingBlockByDefault",
                      ]}
                      defaultChecked={true}
                    />
                    <StyledSwitch
                      label="Enable Feedback Block by Default"
                      name={[
                        "appData",
                        "HELP_DESK",
                        "holidayQuoteConfig",
                        "enableFeedbackBlockByDefault",
                      ]}
                      defaultChecked={true}
                    />
                    <StyledSwitch
                      label="Enable Payment Block by Default"
                      name={[
                        "appData",
                        "HELP_DESK",
                        "holidayQuoteConfig",
                        "enablePaymentLinksByDefault",
                      ]}
                      defaultChecked={true}
                    />

                    <Form.Item
                      name={[
                        "appData",
                        "HELP_DESK",
                        "holidayQuoteConfig",
                        "defaultPaymentLink",
                      ]}
                      label="Default Payment Link"
                      rules={[]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name={[
                        "appData",
                        "HELP_DESK",
                        "holidayQuoteConfig",
                        "defaultFlightItineraryStyle",
                      ]}
                      label="Flight Itinerary Default Template"
                      rules={[]}
                    >
                      <Select
                        style={{ minWidth: 150 }}
                        defaultValue={"Elegant"}
                      >
                        {FlightItineraryTemplateTypes.map((item) => (
                          <Select.Option value={item.value} key={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </ConfigurationEditorComponent>
                </>
              )}
              <ConfigurationEditorComponent
                icon="ri-lock-password-line"
                title="Customer OTP Configurations"
                description="Configure connection from which OTP emails should be delivered"
                entityType="WORKSPACE"
              >
                <Form.Item
                  name={[
                    "appData",
                    "HELP_DESK",
                    "customerPortalConfig",
                    "otpEmailConnectionId",
                  ]}
                  label="Connection to Use for OTP Emails"
                  rules={[]}
                  help={
                    <div className="text-gray-600 text-sm">
                      Select the email connection that will be used to deliver
                      the OTP email.
                    </div>
                  }
                >
                  <ConnectionSelector
                    connectionFilter={(connection) =>
                      connection.type === "EMAIL"
                    }
                  />
                </Form.Item>
              </ConfigurationEditorComponent>
            </div>
          </div>
        </SimpleCardSection>
      </StyledScrollArea>
    </>
  );
};

export const CheckBoxWithDeleteIcon = ({
  value,
  onChange,
}: {
  value?;
  onChange?;
}) => {
  return (
    <div
      className="cursor-pointer text-3xl"
      onClick={() => onChange && onChange(!value)}
    >
      {value ? (
        <i className="ri-checkbox-indeterminate-fill"></i>
      ) : (
        <i className="ri-checkbox-blank-line"></i>
      )}
    </div>
  );
};
