import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { useSDKWithRemotePagination } from "@sdk/sdk.hooks";

import { orderBy } from "lodash";

import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLastAppVersion } from "store/modules/app-state/app-state.actions";
import { selectLastAppVersion } from "store/modules/app-state/app-state.selectors";
import ChangeLogs from "./change-logs.modal-registry";

export const useChangeLogs = () => {
  const { triggerTempModal } = useModalPanels();

  const {
    paginationState,
    setPaginationState,
    data: _itemsInView,
    setData: setItemsInView,
    isLoading,
    reload: resetAndReloadChangeLogs,
  } = useSDKWithRemotePagination((SDK) => SDK.HQ_getHQChangeLogs, {}, [], {
    pageSize: 15,
    currentPage: 1,
    paginationOptions: {
      sort: "-version",
    },
  });

  const itemsInView = useMemo(
    () => _itemsInView.filter((item) => item.version),
    [_itemsInView],
  );

  const lastAppVersion = useSelector(selectLastAppVersion);

  const dispatch = useDispatch();

  const showChangeLogs = useCallback(() => {
    triggerTempModal(ChangeLogs, {});
  }, [triggerTempModal]);

  useEffect(() => {
    if (itemsInView && itemsInView.length > 0) {
      const lastChangeLog = orderBy(itemsInView, "version", "desc")[0];
      if (
        lastAppVersion ||
        moment().isBetween(moment(1644891764372), moment(1645064603734))
      ) {
        if ((lastAppVersion || 0) < lastChangeLog.version) {
          showChangeLogs();
          dispatch(setLastAppVersion(lastChangeLog.version));
        }
      } else {
        if (lastChangeLog && lastChangeLog.version) {
          dispatch(setLastAppVersion(lastChangeLog.version));
        }
      }
    }
  }, [dispatch, itemsInView, lastAppVersion, showChangeLogs]);
};
