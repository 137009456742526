import { flattenDeep } from "lodash";
import React, { useMemo } from "react";
import { useConfigurationMenu } from "./use-configuration-menu";

export interface iSearchResultItem {
  id: string;
  icon: JSX.Element;
  label: string;
  description: string;
  searchKeywords: string;
  menuPath: string;
  activeConfig?: string;
  parentId?: string;
}

export const useConfigurationsIndex = () => {
  const mainMenu = useConfigurationMenu();

  const allSearchableItems = useMemo(() => {
    return flattenDeep(
      mainMenu
        .filter((item) => !item.isHidden || !item.ignoreSearch)
        .map((item) => {
          return [
            {
              id: item.id!,
              icon: React.cloneElement(item.icon, {
                size: "1.3rem",
                width: "1.3rem",
              }),
              label: item.title,
              description: item.description,
              searchKeywords: item.searchKeywords,
              menuPath: item.id!,
              activeConfig: undefined,
              parentId: undefined,
            } as iSearchResultItem,
            ...(item.subSections || []).map(
              (subItem) =>
                ({
                  id: `${item.id}-${subItem.label}`,
                  icon: React.cloneElement(subItem.icon || item.icon, {
                    size: "1.3rem",
                    width: "1.3rem",
                  }),
                  label: subItem.label,
                  description: subItem.description,
                  searchKeywords: subItem.searchKeywords,
                  menuPath: `${item.id}${subItem.path}`,
                  activeConfig: subItem?.activeConfig,
                  parentId: item.id!,
                }) as iSearchResultItem,
            ),
            ...(item.subSections || []).map((subItem) =>
              (subItem?.subSections || []).map(
                (subSectionLevel2) =>
                  ({
                    id: `${item.id}-${subItem.label}-${subSectionLevel2.label}`,
                    icon: React.cloneElement(
                      subSectionLevel2.icon || subItem.icon || item.icon,
                      {
                        size: "1.3rem",
                        width: "1.3rem",
                      },
                    ),
                    label: subSectionLevel2.label,
                    description: subSectionLevel2.description,
                    searchKeywords: subSectionLevel2.searchKeywords,
                    menuPath: `${item.id}${subItem.path}`,
                    activeConfig: subSectionLevel2?.activeConfig,
                    parentId: `${item.id}-${subItem.label}`,
                  }) as iSearchResultItem,
              ),
            ),
          ];
        }),
    );
  }, [mainMenu]);
  return allSearchableItems;
};
