import { Badge, Button, Tooltip } from "antd";
import Upload from "antd/lib/upload";
import _ from "lodash";
import { useCallback } from "react";
import { LoadingIndicatorWithSpin } from "../loading-indicator/loading-indicator";

export const FileUploadSection = ({
  fileList,
  isUploading,
  uploadingFiles,
  setFileList,
  onUpload,
}: {
  fileList: any[];
  isUploading: boolean;
  uploadingFiles: string[];
  setFileList: (list) => any;
  onUpload: (file) => any;
}) => {
  const onPreview = useCallback(async (file) => {
    let src = file.url;
    if (src) {
      const win = window.open(src, "_blank");
      win && win.focus();
      return;
    }
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    if (imgWindow) {
      imgWindow.document.write(image.outerHTML);
    }
  }, []);

  return fileList.length || isUploading || uploadingFiles.length > 0 ? (
    <div className="attachments-container p-4 relative">
      <Upload
        // action={(file) => uploadFileWithLoading(file)}
        customRequest={onUpload}
        listType="picture-card"
        fileList={fileList as any}
        onRemove={(item) => {
          setFileList(_.filter(fileList, (itemX) => item.uid !== itemX.uid));
        }}
        onPreview={onPreview}
        // showUploadList={true}
        openFileDialogOnClick={true}
      >
        {isUploading || uploadingFiles.length > 0 ? (
          <Badge count={uploadingFiles.length}>
            <LoadingIndicatorWithSpin />
          </Badge>
        ) : fileList.length < 5 ? (
          "+ Upload"
        ) : (
          ""
        )}
      </Upload>
      {fileList.length > 0 && (
        <div style={{ right: 5, top: 50 }} className="absolute">
          <Tooltip title="Clear Attachments">
            <Button
              onClick={() => setFileList([])}
              icon={<i className="ri-delete-bin-line"></i>}
              type="text"
              shape="circle"
            />
          </Tooltip>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
