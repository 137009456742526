import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { useEffect, useMemo, useState } from "react";
import BgPatternPicker from "./bg-pattern-selector.modal-registry";

const BgPatternSelector = ({
  value,
  onChange,
  primaryColor,
}: {
  value?: string;
  onChange?: (val: string) => any;
  primaryColor: string;
}) => {
  const { triggerTempModal } = useModalPanels();

  const [bgPatterns, setBgPatterns] = useState(
    [] as { name: string; backgroud: string }[],
  );
  useEffect(() => {
    import("./patterns.json").then((d) => {
      setBgPatterns(d.default);
    });
  }, []);

  const selectedPattern = useMemo(() => {
    return bgPatterns.find((item) => item.backgroud === value);
  }, [bgPatterns, value]);

  return (
    <>
      <div
        className="text-white flex flex-col justify-end items-center rounded-lg cursor-pointer p-4 font-semibold"
        style={{
          width: 200,
          height: 120,
          backgroundColor: primaryColor,
          backgroundImage: value,
        }}
        onClick={() => {
          triggerTempModal(BgPatternPicker, {
            primaryColor,
            onSelected: (pattern) => {
              onChange && onChange(pattern);
            },
          });
        }}
      >
        {!value && "None"}
        {value && selectedPattern && selectedPattern.name}
        {value && !selectedPattern && "Custom"}
      </div>
    </>
  );
};

export default BgPatternSelector;
