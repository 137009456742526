import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { iFileRecord } from "@sdk/file-management/file-management.models";
import { Button, Spin } from "antd";
import classnames from "classnames";
import { ImageX } from "components/common/image";

import {
  iImageUploadResizerOptions,
  iUploadValidation,
} from "components/modules/file-management/upload-validation-model";
import FileSelector from "components/pages/knowledge-base/collections/components/file-selector.modal-registry";
import { LoadingIndicatorWithSpin } from "../loading-indicator/loading-indicator";
import "./image-input.scss";

export const ImageInputWithPreview = ({
  selectedFile,
  onChange,
  isUploading = false,
  image,
  className,
  shape = "round",
  width = 80,
  height = 80,
}: {
  selectedFile: any;
  onChange: any;
  isUploading?: any;
  image?: JSX.Element;
  className?: string;
  shape?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <div className={className} style={{ width, height }}>
      <div className="image-uploader-with-input">
        {selectedFile && (
          <Spin spinning={isUploading} indicator={<LoadingIndicatorWithSpin />}>
            <ImageX
              style={{ width, height }}
              src={URL.createObjectURL(selectedFile)}
            />
          </Spin>
        )}
        {!selectedFile &&
          (image ? (
            <>{image}</>
          ) : (
            !isUploading && (
              <div
                className={classnames(
                  shape === "round" ? "rounded-full" : "rounded-lg",
                  "bg-gray-300 flex items-center justify-center",
                )}
                style={{ width, height }}
              >
                <i className="ri-upload-2-line"></i>
              </div>
            )
          ))}
        <input
          type="file"
          onChange={(event) => {
            if (event.target.files !== null) {
              onChange(event.target.files[0]);
            }
          }}
        />
        <div
          className={classnames(
            "upload-icon",
            shape === "round" ? "rounded-full" : "rounded-lg",
          )}
        >
          <i className="ri-upload-2-line"></i>
        </div>
      </div>
    </div>
  );
};

export const ImageInputWithPreviewV2 = ({
  selectedFile,
  onChange,
  onFileSelected,
  isUploading = false,
  image,
  className,
  shape = "round",
  width = 80,
  height = 80,
  useFilePicker,
  type,
  entityId,
  filePickerPresetRenderer,
  validations,
  resizeBeforeUpload,
  customRecentUploadQuery,
}: {
  selectedFile: any;
  onChange: any;
  onFileSelected: (file: iFileRecord) => any;
  isUploading?: any;
  image?: JSX.Element;
  className?: string;
  shape?: string;
  width?: number;
  height?: number;
  useFilePicker?: boolean;
  type: string;
  entityId: string;
  filePickerPresetRenderer?: (data: {
    onFileSelection: (file: iFileRecord) => void;
    selectedItems: string[];
  }) => JSX.Element;
  validations?: iUploadValidation[];
  resizeBeforeUpload?: iImageUploadResizerOptions;
  customRecentUploadQuery?: any;
}) => {
  const { triggerTempModal } = useModalPanels();
  return (
    <div
      className={className}
      style={{
        maxWidth: width,
        maxHeight: height,
        width: "100%",
        height: "100%",
      }}
    >
      <div className="image-uploader-with-input flex flex-row justify-center relative">
        {selectedFile && (
          <Spin spinning={isUploading} indicator={<LoadingIndicatorWithSpin />}>
            <ImageX
              style={{
                width: "auto",
                height: "auto",
                maxWidth: width,
                maxHeight: height,
                objectFit: "contain",
              }}
              src={URL.createObjectURL(selectedFile)}
            />
          </Spin>
        )}
        {useFilePicker ? (
          <>
            <div
              onClick={(e) => {
                triggerTempModal(FileSelector, {
                  singleSelection: true,
                  onSelected: (files) => {
                    onFileSelected(files[0]);
                  },
                  reference: {
                    type,
                    entityId,
                  },
                  filePickerPresetRenderer: filePickerPresetRenderer,
                  validations,
                  resizeBeforeUpload,
                  customRecentUploadQuery,
                });
              }}
              className="cursor-pointer"
            >
              {!selectedFile &&
                (image ? (
                  <>{image}</>
                ) : (
                  !isUploading && (
                    <Button
                      icon={<i className="ri-upload-2-line"></i>}
                      type="dashed"
                    >
                      Pick File
                    </Button>
                  )
                ))}
              {(selectedFile || image) && (
                <div
                  className={classnames(
                    "upload-icon",
                    shape === "round" ? "rounded-full" : "rounded-lg",
                  )}
                >
                  <i className="ri-upload-2-line"></i>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {!selectedFile &&
              (image ? (
                <>{image}</>
              ) : (
                !isUploading && (
                  <Button
                    icon={<i className="ri-upload-2-line"></i>}
                    type="dashed"
                  >
                    Upload File
                  </Button>
                )
              ))}
            <input
              type="file"
              onChange={(event) => {
                if (event.target.files !== null) {
                  onChange(event.target.files[0]);
                }
              }}
            />
            {(selectedFile || image) && (
              <div
                className={classnames(
                  "upload-icon",
                  shape === "round" ? "rounded-full" : "rounded-lg",
                )}
              >
                <i className="ri-upload-2-line"></i>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
