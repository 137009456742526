import { useSDK } from "@sdk";
import { Input } from "antd";
import qs from "query-string";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { loadAllCCArticleCollections } from "store/modules/cc-article-collection/cc-article-collection.helpers";
import { selectAllCCArticleCollections } from "store/modules/cc-article-collection/cc-article-collection.selectors";
import { loadAllCCArticles } from "store/modules/cc-articles/cc-articles.helpers";
import { selectAllCCArticles } from "store/modules/cc-articles/cc-articles.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useDebouncedValue } from "utils/hooks/use-debounced-value";
import { useUrlState } from "utils/hooks/use-url-state";
import { HelpCenterArticleItem } from "../components/help-center-article-item";
import { HelpCenterCollectionItem } from "../components/help-center-collection-item";
import "../help-center.scss";

export const HelpCenterHome = () => {
  const { state: _collections, isLoading } = useQueryWithStore(
    selectAllCCArticleCollections,
    loadAllCCArticleCollections,
  );
  const collections = useMemo(
    () => _collections.filter((item) => !item.metaData?.isHidden),
    [_collections],
  );
  // Todo: Replace
  const { state: _articles, isLoading: isLoadingArticles } = useQueryWithStore(
    selectAllCCArticles,
    loadAllCCArticles,
  );

  // const { filteredData, searchTerm, setSearchTerm } = useSearch(
  //   _.clone(
  //     _articles.filter(item => item.metaData.status === "PUBLISHED")
  //   ).reverse()
  // );

  // const { paginationState, setPaginationState, itemsInView, totalItems } =
  //   usePagination(filteredData, {});

  const [searchTerm, setSearchTerm] = useState("");

  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 600);

  const {
    reload,
    data: searchResults,
    isLoading: isSearching,
  } = useSDK(
    (SDK) => SDK.ccArticles.search(debouncedSearchTerm),
    [debouncedSearchTerm],
    !debouncedSearchTerm,
  );

  const [selectedCollection, onSelectCollection] = useUrlState(
    "help-center-collection",
  );

  const [selectedArticle, onSelectArticle] = useUrlState("help-center-article");

  const location = useLocation();

  useEffect(() => {
    const searchTermInUrl = qs.parse(location.search)["searchTerm"]
      ? String(qs.parse(location.search)["searchTerm"])
      : "";
    if (searchTermInUrl) {
      setSearchTerm(searchTermInUrl);
    }
  }, [location, setSearchTerm]);

  return (
    <div className="flex flex-col">
      <div className="header">
        <div
          className="help-center-banner bg-gray-100 dark:bg-gray-800 flex flex-col justify-center items-center font-bold"
          style={{
            backgroundImage:
              "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%23000000' fill-opacity='0.07'%3E%3Cpath fill-rule='evenodd' d='M11 0l5 20H6l5-20zm42 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM0 72h40v4H0v-4zm0-8h31v4H0v-4zm20-16h20v4H20v-4zM0 56h40v4H0v-4zm63-25a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM53 41a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-30 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-28-8a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zM56 5a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zm-3 46a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM21 0l5 20H16l5-20zm43 64v-4h-4v4h-4v4h4v4h4v-4h4v-4h-4zM36 13h4v4h-4v-4zm4 4h4v4h-4v-4zm-4 4h4v4h-4v-4zm8-8h4v4h-4v-4z'/%3E%3C/g%3E%3C/svg%3E\")",
          }}
        >
          <div className="text-3xl">Welcome to Help Center</div>
        </div>
        <div className="search-container bg-white dark:bg-gray-900">
          <Input.Search
            placeholder="Search Help Center..."
            size="large"
            className="rounded-md py-4"
            value={searchTerm}
            onChange={(e) => {
              // if (e.target.value.length > 2) {
              setSearchTerm(e.target.value);
              //setArticleSearchTerm(e.target.value);
              // }
            }}
          />
        </div>
      </div>
      <div className="text-gray-600 px-12">
        Everything that you need to learn about ClickConnector can ba found
        here. Feel free to use the search to find what you are looking for. If
        you are not able to find what you need, you can always chat with us
      </div>

      {(isLoadingArticles || isLoading) && (
        <div className="w-full flex flex-col justify-center items-center p-20">
          <i className="ri-loader-5-line animate-spin text-2xl"></i>
        </div>
      )}
      {!(isLoadingArticles || isLoading) && (
        <>
          {searchTerm && (
            <>
              <div>
                {isSearching ? (
                  <div className="flex flex-col justify-center items-center mt-8">
                    <svg
                      className="w-10 h-10 text-gray-600 dark:text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      ></path>
                    </svg>
                    <div className="text-gray-600 mt-4">Searching...</div>
                  </div>
                ) : (
                  <>
                    {searchResults.length > 0 ? (
                      <div className="p-8">
                        {searchResults.map((article) => (
                          <HelpCenterArticleItem
                            key={article.id}
                            article={article}
                            onSelect={() => onSelectArticle(article.id!)}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="text-xl my-6 mb-16 flex flex-col items-center">
                        <div>
                          <i className="ri-emotion-sad-line text-8xl text-blue-400"></i>
                        </div>
                        <div className="text-gray-500 leading-none">
                          Sorry! We couldn't find any results
                        </div>
                        {/* Todo: Chat with us */}
                      </div>
                    )}
                  </>
                )}
              </div>
              {/* <div className="flex flex-row justify-end items-center py-4  px-8">
                <Pagination
                  pageSize={paginationState.pageSize}
                  current={paginationState.currentPage}
                  total={totalItems}
                  onChange={(currentPage, pageSize) =>
                    setPaginationState({
                      ...paginationState,
                      pageSize: pageSize || 20,
                      currentPage,
                    })
                  }
                />
              </div> */}
            </>
          )}

          {!searchTerm && !selectedCollection && (
            <div className="p-8">
              {collections.map((collection) => (
                <HelpCenterCollectionItem
                  key={collection.id}
                  collection={collection}
                  onSelect={() =>
                    onSelectCollection(collection.id!, { searchTerm: "" })
                  }
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};
