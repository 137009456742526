import { DeepPartial } from "@reduxjs/toolkit";
import { iWidget } from "@sdk/chat-widgets/chat-widgets.models";
import { iConnection } from "@sdk/conversations/conversations.models";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Slider,
  Tooltip,
} from "antd";
import { Formatter } from "antd/es/slider";

import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import BgPatternSelector from "components/common/bg-pattern-selector/bg-pattern-selector";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { HelpCenterPromptButton } from "components/common/help-center/embeddable-components/help-center-prompt-button";
import { ModalTitle } from "components/common/modal-title";
import { LazyQuillEditor } from "components/common/quill-editor/lazy-quill-editor";
import { SectionHeader } from "components/common/section-header";
import { StyledSwitch } from "components/common/styled-swtich";
// import { WidgetPreviewHome } from "components/modules/connections/channels/chat-widget/components/configure-chat-widget/widget-preivew/widget-preview-home";
import { ChannelNameForm } from "components/modules/connections/common-connection-settings/channel-name/channel-name-form";
import { ConnectionLimits } from "components/modules/connections/common-connection-settings/connection-limits/connection-limits";
import { ConnectionTaggingPreferencesEditor } from "components/modules/connections/common-connection-settings/connection-tagging-preference-editor/connection-tagging-preferences-editor";
import { DeleteConnectionSettings } from "components/modules/connections/common-connection-settings/delete-connection-settings/delete-connection-settings";
import { EmailNotificationsForm } from "components/modules/connections/common-connection-settings/email-notifications-form/email-notifications-form";
import { RestrictAccessForm } from "components/modules/connections/common-connection-settings/restrict-access-form/restrict-access-form";
import { RoutingPreferencesForm } from "components/modules/connections/common-connection-settings/routing-preferences-form/routing-preferences-form";
import { ConnectionSelector } from "components/modules/conversations/components/connection-selector/connection-selector";

import { SelfServicePortalSelector } from "components/pages/knowledge-base/self-service-portal-selector/self-service-portal-selector";
import copyToClipboard from "copy-to-clipboard";
import { DarkModeBg } from "dark-mode-bg";
import { flatten } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectAllUsers } from "store/modules/users/users.selectors";
import { selectShopifyStoreConfig } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { CountryCodes } from "utils/country-codes";
import { isIpAddress } from "utils/is-ip-address";
import { stringArrayToSentence } from "utils/string-array-to-sentence";
import { CollapsibleHeader } from "../../../../../../common/collapsible-configuration-panel/collapsible-header";
import {
  ColorPickerFormItem,
  ImageUploaderFormItem,
} from "../../../email/components/forms/email-template";
import { AutomaticEmailFollowups } from "../automatic-email-followups/automatic-email-followups";
import { ChatWidgetInstallInstructions } from "../chat-widget-install-instructions/chat-widget-install-instructions";
import { ChatWidgetGreetings } from "../chat-widget-theme/chat-widget-greetings";
import { CustomTexts } from "../custom-texts/custom-texts";
import { ConfigureChatWidgetOfflineHours } from "../manage-offline-hours/manage-offline-hours";
import ManageChatCampaigns from "../manage-triggers/manage-chat-campaigns.modal-registry";
import { ManageWidgetFaqs } from "../manage-widget-faqs/manage-widget-faqs";
import "./configure-chat-widget.scss";
import { defaultWidgetPreviewData } from "./widget-preivew/default-widget-data";
import { WidgetHomePreview } from "./widget-preivew/widget-home-preview";

const preChatFormOptions = [
  { label: "Collect Name", value: "NAME" },
  { label: "Collect Email", value: "EMAIL" },
  { label: "Collect Mobile Number", value: "PHONE" },
  { label: "Obtain Marketing Consent", value: "MARKETING_PREFERENCE" },
];

const offSetSliderFormatter: Formatter = (value: number | undefined) =>
  `${value || 0}px`;

export const ConfigureChatWidgetConnection = ({
  connectionId,
  hideHeader,
}: {
  connectionId: string;
  hideHeader?: boolean;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const { state: widget } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );

  const [isFaqModalVisible, setFaqModalVisible] = useState(false);

  const [isChatWidgetExpanded, setChatWidgetExpanded] = useState(false);

  const [widgetColor, setWidgetColor] = useState(
    widget?.configurations?.appearance.colorPallet.primary || "#0466ff",
  );

  const [headerPattern, setHeaderPattern] = useState(
    widget?.configurations?.appearance.headerPattern,
  );

  useEffect(() => {
    setWidgetColor(widget?.configurations?.appearance.colorPallet.primary!);
    setHeaderPattern(widget?.configurations?.appearance.headerPattern!);
  }, [
    widget?.configurations?.appearance.colorPallet.primary,
    widget?.configurations?.appearance.headerPattern,
  ]);

  const allUsers = useSelector(selectAllUsers);

  const widgetPreviewConfig = useMemo(() => {
    const faqCollections = widget?.configurations.faqCollections;
    const faqs = flatten(
      (faqCollections || []).map((collection) =>
        collection.faqList.map((question) => ({
          QUESTION: question.question,
        })),
      ),
    );
    return {
      HOME_HEADER: {
        WELCOME_TEXT:
          widget?.configurations.locale?.custom?.GREETINGS_MESSAGE ||
          defaultWidgetPreviewData.HOME_HEADER.WELCOME_TEXT,
        HEADER_TAGLINE:
          widget?.configurations.locale?.custom?.GUIDE_MESSAGE ||
          defaultWidgetPreviewData.HOME_HEADER.HEADER_TAGLINE,
        AVATARS_IMAGES: allUsers.map((user) => user.data.avatar),
        TOTAL_USERS: allUsers.length,
        HEADER_PATTERN:
          headerPattern || widget?.configurations.appearance?.headerPattern,
      },
      FAQ: {
        COLLECTIONS: widget?.configurations.faqCollections,
      },
    } as DeepPartial<typeof defaultWidgetPreviewData>;
  }, [
    allUsers,
    headerPattern,
    widget?.configurations.appearance?.headerPattern,
    widget?.configurations.faqCollections,
    widget?.configurations.locale?.custom?.GREETINGS_MESSAGE,
    widget?.configurations.locale?.custom?.GUIDE_MESSAGE,
  ]);

  const shopifyPlugin = useSelector(selectShopifyStoreConfig);

  const dispatch = useDispatch();

  const widgetDirectUrl = useMemo(
    () => `https://widget.clickconnector.app/${widget?.id}/`,
    [widget?.id],
  );

  const { triggerTempModal, changePanelStateWithName } = useModalPanels();

  return (
    <div className="animated fadeInLeftMin configure-chat-widget">
      {!hideHeader && (
        <>
          <SectionHeader
            title="Configure Chat Widget"
            icon={<i className="ri-chat-settings-line"></i>}
            description="Configure what your customers see when they chat with a user"
          />
          <Divider />
        </>
      )}
      <div className="outer-container flex flex-col justify-center items-center">
        <div className="text-gray-600 dark:text-gray-400 mb-2">
          Here's how your chat widget will look 👇
        </div>
        <div
          className="chat-widget-container cursor-not-allowed"
          style={{
            ...(isChatWidgetExpanded
              ? {
                  width: "28rem",
                  height: "45rem",
                }
              : {
                  width: "28rem",
                  height: "24rem",
                }),
            overflow: "hidden",
            borderRadius: "10px 10px 0 0",
            boxShadow: "0 0 20px #00000038",
          }}
        >
          <div
            className="text-black relative"
            style={{ width: 420, height: "45rem" }}
          >
            <WidgetHomePreview
              color={widgetColor}
              configData={widgetPreviewConfig}
            />
            <div className="absolute w-full h-full z-10 top-0 left-0"></div>
          </div>
        </div>
        <Button
          block
          type="text"
          icon={
            isChatWidgetExpanded ? (
              <i className="ri-arrow-up-s-line"></i>
            ) : (
              <i className="ri-arrow-down-s-line"></i>
            )
          }
          onClick={() => setChatWidgetExpanded(!isChatWidgetExpanded)}
        />
      </div>
      <Divider className="m-0" />

      <Divider>Installation</Divider>

      <ChatWidgetInstallInstructions connectionId={connectionId} />

      <Divider>Appearance</Divider>

      {/* Todo: */}
      <ConfigurationEditorComponent
        icon={"ri-palette-line"}
        title="Customize Look and Feel"
        description="Change widget colour, header pattern, chat bubble style, position and size"
        entityId={widget?.id!}
        entityType="WIDGET"
        onFormValueChange={(value) => {
          const currentColor =
            value?.configurations?.appearance?.colorPallet?.primary;
          const currentPattern =
            value?.configurations?.appearance?.headerPattern;
          setWidgetColor(currentColor);
          setHeaderPattern(currentPattern);
        }}
      >
        <ColorPickerFormItem
          fieldName={["configurations", "appearance", "colorPallet", "primary"]}
          label="Widget Color"
        />
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const primaryColor = getFieldValue([
              "configurations",
              "appearance",
              "colorPallet",
              "primary",
            ]);
            return (
              <Form.Item
                name={["configurations", "appearance", "headerPattern"]}
                label={"Header Pattern"}
                rules={[]}
              >
                <BgPatternSelector primaryColor={primaryColor} />
              </Form.Item>
            );
          }}
        </Form.Item>
        {/* Widget Position */}
        <Form.Item
          name={["configurations", "appearance", "launcherPosition"]}
          label={"Widget Position"}
          rules={[]}
        >
          <Select style={{ minWidth: 150 }} defaultValue={"RIGHT_BOTTOM"}>
            <Select.Option value="RIGHT_BOTTOM">Right</Select.Option>
            <Select.Option value="LEFT_BOTTOM">left</Select.Option>
          </Select>
        </Form.Item>
        {/* Chat Bubble Style */}
        <Form.Item
          name={["configurations", "appearance", "chatBubbleStyle"]}
          label={"Widget Style"}
          rules={[]}
        >
          <Select style={{ minWidth: 150 }} defaultValue={"rounded"}>
            <Select.Option value="rounded">Rounded</Select.Option>
            <Select.Option value="circle">Circle</Select.Option>
          </Select>
        </Form.Item>
        {/* Chat Bubble Image */}
        <ImageUploaderFormItem
          label="Custom Chat Bubble Image"
          fieldName={["configurations", "appearance", "chatBubbleImage"]}
          entityType="CHAT_WIDGET"
          entityId={widget?.id!}
          allowClear={true}
        />
        {/* Chat Bubble Size */}
        <Form.Item
          name={["configurations", "appearance", "chatBubbleSize"]}
          label={"Chat Bubble Size"}
          rules={[]}
        >
          <Select style={{ minWidth: 150 }} defaultValue={"DEFAULT"}>
            <Select.Option value="COMPACT">Compact</Select.Option>
            <Select.Option value="DEFAULT">Default</Select.Option>
            <Select.Option value="LARGE">Large</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={["configurations", "appearance", "widgetSize"]}
          label={"Chat Widget Size"}
          rules={[]}
        >
          <Select style={{ minWidth: 150 }} defaultValue={"DEFAULT"}>
            <Select.Option value="COMPACT">Compact</Select.Option>
            <Select.Option value="DEFAULT">Default</Select.Option>
            <Select.Option value="LARGE">Large</Select.Option>
          </Select>
        </Form.Item>
        {/* Chat Bubble X Offset */}
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const launcherPosition = getFieldValue([
              "configurations",
              "appearance",
              "launcherPosition",
            ]);
            const positions =
              launcherPosition === "LEFT_BOTTOM" ? "left" : "right";
            return (
              <Form.Item
                name={["configurations", "appearance", "chatBubbleXOffset"]}
                label={`Move Chat Bubble from ${positions} of the screen`}
                rules={[]}
              >
                <Slider
                  defaultValue={0}
                  tooltip={{ formatter: offSetSliderFormatter }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        {/* Chat Bubble Y Offset */}
        <Form.Item
          name={["configurations", "appearance", "chatBubbleYOffset"]}
          label={"Move Chat Bubble from Bottom of the screen"}
          rules={[]}
        >
          <Slider
            defaultValue={0}
            tooltip={{ formatter: offSetSliderFormatter }}
          />
        </Form.Item>
      </ConfigurationEditorComponent>

      <CustomTexts connectionId={connectionId} />

      <Divider>Greetings and Chat Routing</Divider>

      <ChatWidgetGreetings connectionId={connectionId} />

      <div
        className="cursor-pointer border border-gray-400 dark:border-gray-700 rounded-lg p-4  flex flex-row items-center"
        onClick={() => triggerTempModal(ManageChatCampaigns, { connectionId })}
      >
        <i className="ri-arrow-right-s-line text-lg mr-2"></i>

        <CollapsibleHeader
          icon={<i className={`ri-chat-follow-up-line  text-3xl`}></i>}
          title={"Proactive Chat Campaigns for Landing Pages"}
          description={
            "Setup up chat campaigns and greetings based on page criteria"
          }
        />
      </div>

      {/* Routing Settings */}
      <RoutingPreferencesForm connectionId={connectionId} />

      <Divider>Chat Widget Features</Divider>

      <AutomaticEmailFollowups connectionId={connectionId!} />

      {shopifyPlugin?.isActive && (
        <ConfigurationEditorComponent
          icon={
            <div>
              <img
                src="/assets/integrations/shopify.png"
                style={{ width: "2rem" }}
                alt=""
                className="mt-2"
              />
            </div>
          }
          title="Enable Shopify Plugin"
          description="Allow your customers to check order status right from ClickConnector"
          entityId={widget?.id!}
          entityType="WIDGET"
        >
          <StyledSwitch
            label="Enabled"
            name={["configurations", "shopifyPlugin", "isActive"]}
            disabled={!shopifyPlugin?.isActive}
          />
          {!shopifyPlugin?.isActive && (
            <div className="flex flex-row justify-center items-center">
              <span>Shopify Integration is not enabled</span>
              <a
                onClick={() => {
                  changePanelStateWithName(
                    "configurations",
                    true,
                    {},
                    {
                      configurations: `APPS_AND_INTEGRATIONS`,
                      activeConfigs: "Shopify",
                    },
                    [],
                  );
                }}
                className="pl-2 underline"
              >
                Click here to enable
              </a>
            </div>
          )}
        </ConfigurationEditorComponent>
      )}

      {/* Chat Ratings */}
      <ConfigurationEditorComponent
        icon={"ri-emotion-happy-line"}
        title="Enable Chat Ratings"
        description="Collect feedback after the conversation is ended"
        entityId={widget?.id!}
        entityType="WIDGET"
      >
        <StyledSwitch
          label="Enabled"
          name={[
            "configurations",
            "conversationFlow",
            "chatRatings",
            "enabled",
          ]}
        />
      </ConfigurationEditorComponent>
      {/*  Knowledge Base */}
      <ConfigurationEditorComponent
        icon={"ri-book-line"}
        title="Show Knowledge Base"
        description="Display your Knowledge Base articles right inside the chat widget"
        entityId={widget?.id!}
        entityType="WIDGET"
      >
        <StyledSwitch
          label="Enabled"
          name={["configurations", "knowledgeBase", "isActive"]}
        />
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => {
            const showPortal = getFieldValue([
              "configurations",
              "knowledgeBase",
              "isActive",
            ]);
            if (showPortal) {
              return (
                <Form.Item
                  label="Knowledge Base Portal"
                  name={[
                    "configurations",
                    "knowledgeBase",
                    "selfServicePortalId",
                  ]}
                  hasFeedback
                >
                  <SelfServicePortalSelector autoSelectFirstOption />
                </Form.Item>
              );
            }
            return <></>;
          }}
        </Form.Item>
      </ConfigurationEditorComponent>
      <ConfigurationEditorComponent
        icon={"ri-file-list-line"}
        title="Pre-chat Form"
        description="Collect data through a simple form before a user starts to chat"
        entityId={widget?.id!}
        entityType="WIDGET"
      >
        <StyledSwitch
          label="Pre-chat form is enabled"
          name={[
            "configurations",
            "conversationFlow",
            "preChatForm",
            "isEnabled",
          ]}
        />

        <Form.Item shouldUpdate style={{ marginBottom: 4 }}>
          {({ getFieldValue }) => {
            const selectedOption = getFieldValue([
              "configurations",
              "conversationFlow",
              "preChatForm",
              "isEnabled",
            ]);
            if (selectedOption) {
              return (
                <>
                  <Form.Item
                    name={[
                      "configurations",
                      "conversationFlow",
                      "preChatForm",
                      "fields",
                    ]}
                    style={{ marginBottom: 4 }}
                  >
                    <Checkbox.Group options={preChatFormOptions} />
                  </Form.Item>
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      const fields = getFieldValue([
                        "configurations",
                        "conversationFlow",
                        "preChatForm",
                        "fields",
                      ]);
                      if (fields.includes("EMAIL")) {
                        return (
                          <StyledSwitch
                            name={[
                              "configurations",
                              "conversationFlow",
                              "preChatForm",
                              "allowOnlyWorkEmails",
                            ]}
                            label={"Allow only professional email"}
                          />
                        );
                      }
                      return <div></div>;
                    }}
                  </Form.Item>
                  <Alert
                    message="If the client has already provided the required information, pre chat forms will be disabled automatically to improve the client experience"
                    type="info"
                  />

                  <div className="mt-4"></div>
                  <Form.Item
                    name={[
                      "configurations",
                      "conversationFlow",
                      "preChatForm",
                      "privacyPolicyLink",
                    ]}
                    label={
                      "Optionally you could also display your privacy policy link"
                    }
                    rules={[
                      {
                        type: "url",
                        message: "Please enter a valid URL",
                      },
                    ]}
                  >
                    <Input placeholder="Privacy Policy Link (Optional)" />
                  </Form.Item>
                </>
              );
            } else {
              return <></>;
            }
          }}
        </Form.Item>
      </ConfigurationEditorComponent>
      <div
        className="cursor-pointer border border-gray-400 dark:border-gray-700 rounded-lg p-4  flex flex-row items-center"
        onClick={() => setFaqModalVisible(true)}
      >
        <i className="ri-arrow-right-s-line text-lg mr-2"></i>
        <CollapsibleHeader
          icon={<i className={`ri-question-answer-line text-3xl`}></i>}
          title={"Setup FAQs (Depreciated - Use Knowledge Base instead)"}
          description={
            "Setup up most frequently asked questions for your customers to browse"
          }
        />
      </div>
      <Modal
        title={
          <ModalTitle
            title="Setup FAQs"
            icon={<i className="ri-question-answer-line"></i>}
          />
        }
        open={isFaqModalVisible}
        footer={null}
        onCancel={() => {
          setFaqModalVisible(false);
        }}
        data-click-context="Edit FAQs Modal"
        width={"100%"}
      >
        <ManageWidgetFaqs connectionId={connectionId} />
        <DarkModeBg />
      </Modal>

      <ConfigureChatWidgetOfflineHours connectionId={connectionId} />

      <ConfigurationEditorComponent
        icon={"ri-lock-2-line"}
        title="Enable Customer Sign in"
        description="Customer sign in allows your customers to sign in to your portal and see their chat history"
        entityId={widget?.id!}
        entityType="WIDGET"
      >
        <StyledSwitch
          label="Enabled"
          name={["configurations", "customerSignIn", "isEnabled"]}
        />
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => {
            const isEnabled = getFieldValue([
              "configurations",
              "customerSignIn",
              "isEnabled",
            ]);

            if (isEnabled) {
              return (
                <div className="flex flex-row justify-end items-center">
                  <Button
                    type="link"
                    onClick={() => {
                      changePanelStateWithName(
                        "configurations",
                        true,
                        {},
                        {
                          activeConfigs: "Customer OTP Configurations",
                          configurations: "POWER_SETTINGS",
                        },
                      );
                    }}
                    icon={<i className="ri-arrow-right-line"></i>}
                  >
                    Configure email connection to use when sending OTP
                  </Button>
                </div>
              );
            }
            return <></>;
          }}
        </Form.Item>
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon={"ri-megaphone-line"}
        title="Temporary Widget Announcements"
        description="Show a simple temporary announcement to all customers"
        entityId={widget?.id!}
        entityType="WIDGET"
      >
        <StyledSwitch
          label="Enabled"
          name={["configurations", "announcements", "isEnabled"]}
        />

        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => {
            const isEnabled = getFieldValue([
              "configurations",
              "announcements",
              "isEnabled",
            ]);

            if (isEnabled) {
              return (
                <Form.Item
                  label="Message"
                  name={["configurations", "announcements", "message"]}
                >
                  <LazyQuillEditor />
                </Form.Item>
              );
            }
            return <></>;
          }}
        </Form.Item>
      </ConfigurationEditorComponent>

      <Divider>Power Settings</Divider>

      <ChannelNameForm connectionId={connectionId} />

      <RestrictAccessForm connectionId={connectionId} />

      <EmailNotificationsForm connectionId={connectionId} />

      <ConfigurationEditorComponent
        icon={"ri-robot-line"}
        title="Chatbot Profile"
        description="Customize the avatar and name of chatbot in the live chat widget"
        entityId={widget?.id!}
        entityType="WIDGET"
      >
        <ImageUploaderFormItem
          label="Bot Assistant Avatar"
          fieldName={["configurations", "chatBotProfile", "avatar"]}
          entityType="WIDGET"
          entityId={widget?.id!}
          rules={[]}
        />

        <Form.Item
          label="Bot Assistant Name"
          name={["configurations", "chatBotProfile", "name"]}
          rules={[]}
        >
          <Input placeholder="Eg: AI Assistant" />
        </Form.Item>
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon={"ri-chat-settings-line"}
        title="Allow Multiple Active Chats"
        description="Customer are allowed to start a second chat while one conversation is open"
        entityId={widget?.id!}
        entityType="WIDGET"
      >
        <StyledSwitch
          label="Allow Multiple Active Conversations Per Session"
          name={["configurations", "allowMultipleActiveChat"]}
        />
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon={"ri-chat-upload-line"}
        title="Auto Widget Popup"
        description="Automatically open the chat widget when there is new message"
        entityId={widget?.id!}
        entityType="WIDGET"
      >
        <StyledSwitch
          label="Enable"
          name={["configurations", "automaticallyOpenChatWidgetOnNewMessages"]}
        />
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon={"ri-honour-line"}
        title="Remove ClickConnector Branding"
        description="Remove ClickConnector branding in the home screen of the live chat widget."
        entityId={widget?.id!}
        entityType="WIDGET"
      >
        <Alert
          message="Consider supporting us by keeping the non-obstructive text - 'Powered by ClickConnector' "
          type="info"
          className="mb-2"
        />
        <StyledSwitch
          label="Remove ClickConnector Branding"
          name={["configurations", "hideCCLogo"]}
        />
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon={"ri-mail-settings-line"}
        title="Connected Ticket Connection"
        description="If you are going to use Chat Widget APIs to create a conversation programmatically, you'll need to set up the ticket connection"
        entityId={connectionId!}
        entityType="CONNECTION"
      >
        <Form.Item
          label="Connections to Use"
          name={["data", "ticketConnectionId"]}
        >
          <ConnectionSelector
            connectionFilter={(connection) => connection.type === "EMAIL"}
            allowClear={true}
          />
        </Form.Item>
      </ConfigurationEditorComponent>
      <ConfigurationEditorComponent
        icon={"ri-run-line"}
        title="Page Navigation Activities"
        description="Show Customer Page Navigation Activity in Chat Messages"
        entityId={connectionId!}
        entityType="CONNECTION"
        transformFormValues={(values) => ({
          data: {
            disablePageNavigationActivitiesInChats:
              !values.data.enablePageNavigationActivitiesInChats,
          },
        })}
        transformEntityValues={(connection: iConnection) => ({
          data: {
            enablePageNavigationActivitiesInChats:
              !connection.data.disablePageNavigationActivitiesInChats,
          },
        })}
      >
        <StyledSwitch
          label="Show Navigation in Chat Messages"
          name={["data", "enablePageNavigationActivitiesInChats"]}
        />
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon="ri-spam-3-line"
        title="Blacklisted Clients"
        description="disable the widget if a person tries to access chat through specific IPs"
        entityId={widget?.id!}
        entityType="WIDGET"
      >
        <Form.Item
          label="Blacklisted IPs"
          name={["configurations", "blackListedClients", "ips"]}
          hasFeedback
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, ips) {
                if (!ips || ips.length === 0) {
                  return Promise.resolve();
                }
                const invalidIps = ips.filter((ip) => !isIpAddress(ip));
                if (invalidIps.length > 0) {
                  return Promise.reject(
                    `The following Ips are Invalid: ${stringArrayToSentence(
                      invalidIps,
                    )}`,
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Blacklisted IPs"
            dropdownStyle={{ display: "none" }}
            allowClear
          ></Select>
        </Form.Item>
        <Form.Item
          label="Whitelisted Countries"
          name={[
            "configurations",
            "blackListedClients",
            "whiteListedCountries",
          ]}
          hasFeedback
          help="Leave it blank to allow all countries"
        >
          <Select showSearch mode="tags">
            {CountryCodes.map((country) => (
              <Select.Option
                value={`${country.code}|${country.name}`}
                key={country.code}
              >
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon="ri-shield-check-line"
        title="Whitelisted Domains"
        description="Domains where the widget can be embedded"
        entityId={widget?.id!}
        entityType="WIDGET"
      >
        <Form.Item
          label="Whitelisted Domains"
          name={["configurations", "whitelistedDomains"]}
          hasFeedback
          rules={[]}
        >
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Whitelisted Domains"
            dropdownStyle={{ display: "none" }}
            allowClear
          ></Select>
        </Form.Item>
        <Alert
          type="info"
          showIcon={true}
          message={
            "Make sure to add your knowledge base domain if you are using one"
          }
        />
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon={"ri-file-info-line"}
        title="Automatic Form Submission Tracking"
        description="Track form submissions and associated data as activity of customer"
        entityId={widget?.id!}
        entityType="WIDGET"
        transformFormValues={(values) => ({
          configurations: {
            disableFormTracking: !values.configurations.enableFormTracking,
          },
        })}
        transformEntityValues={(widget: iWidget) => ({
          configurations: {
            enableFormTracking: !widget.configurations.disableFormTracking,
          },
        })}
      >
        <StyledSwitch
          label="Form Submission Tracking Enabled"
          name={["configurations", "enableFormTracking"]}
        />
      </ConfigurationEditorComponent>

      <ConnectionTaggingPreferencesEditor connectionId={connectionId} />

      <CollapsibleConfigurationSection
        icon={"ri-git-commit-fill"}
        title={"User Limits"}
        description={"Prevent users from being assigned too many chats"}
      >
        <ConnectionLimits connectionId={connectionId!} />
      </CollapsibleConfigurationSection>

      {!hideHeader && (
        <ConfigurationEditorComponent
          icon={"ri-chat-delete-line"}
          title="Disable Widget"
          description="Disable the widget on sites temporarily"
          entityId={widget?.id!}
          entityType="WIDGET"
          transformFormValues={(values) => ({
            metaData: {
              isActive: !values.metaData.isDisabled,
            },
          })}
          transformEntityValues={(widget: iWidget) => ({
            metaData: {
              isDisabled: !widget.metaData.isActive,
            },
          })}
        >
          <StyledSwitch
            label="Disable Widget"
            name={["metaData", "isDisabled"]}
          />
        </ConfigurationEditorComponent>
      )}

      <Divider>Danger</Divider>
      <DeleteConnectionSettings connectionId={connectionId!} />

      <Alert
        message={
          <div className="flex flex-row items-center">
            <div className="left">
              Your Chat Page Link:
              {widgetDirectUrl}
            </div>
            <div className="right">
              <Tooltip title="Copy URL to clipboard">
                <Button
                  type="text"
                  icon={<i className="ri-clipboard-line"></i>}
                  onClick={() => {
                    copyToClipboard(widgetDirectUrl);
                    message.success("URL has been copied to clipboard");
                  }}
                ></Button>
              </Tooltip>
            </div>
          </div>
        }
        type="info"
        showIcon
        className="mb-4"
      />

      <HelpCenterPromptButton
        type="BANNER"
        text="💡 You can use Chat Widget API to create custom workflows and experiences"
        description="Learn about Chat Widget JS APIs"
        articleId="fc89e0-b4c0b"
        // collectionId="fc89e0-d943b"
        className="mb-2"
      />
    </div>
  );
};
