import { iConnectionType } from "@sdk/conversations/conversations.models";

export const ConnectionTypeLabelMap: Record<iConnectionType, string> = {
  SMS: "Sms",
  EMAIL: "Email",
  WEB_FORM: "Web Form",
  WEB_CHAT: "Live Chat",
  FACEBOOK: "Facebook",
  WHATS_APP: "WhatsApp",
  INSTAGRAM: "Instagram",
  WHATS_APP_PRIVATE: "WhatsApp",
  WHATS_APP_OFFICIAL: "WhatsApp",
  WHATS_APP_CLOUD: "WhatsApp",
  WHATS_APP_CLOUD_NATIVE: "WhatsApp",
  TWITTER: "Twitter",
  TELNYX: "Soft Phone",
  TELEGRAM: "Telegram",
};
