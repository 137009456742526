export const ConnectionTypeToLabelMap = {
  EMAIL: "Email",
  WEB_CHAT: "Chat Widget",
  FACEBOOK: "Facebook",
  WHATS_APP: "WhatsApp",
  INSTAGRAM: "Instagram",
  WHATS_APP_PRIVATE: "WhatsApp",
  WHATS_APP_OFFICIAL: "WhatsApp",
  TWITTER: "Twitter",
  TELNYX: "Phone",
};
