import { flatten } from "lodash";
import { useMemo } from "react";
import { DeepPartial } from "redux";
import { mergeDeep } from "utils/merge-deep";
import { ChatWidgetAvatarGroup } from "./components/chat-widget-avatar-group";
import { StyledWidgetContainer } from "./components/styled-widget-container";
import { ChatWidgetUserAvatarContainer } from "./components/user-avatar-container";
import { defaultWidgetPreviewData } from "./default-widget-data";
import "./widget-home-preview.scss";

export const WidgetHomePreview = ({
  color,
  configData,
}: {
  color?: string;
  configData: DeepPartial<typeof defaultWidgetPreviewData>;
}) => {
  const data: typeof defaultWidgetPreviewData = useMemo(
    () => mergeDeep({ ...defaultWidgetPreviewData }, configData),
    [configData],
  );

  const { faqs } = useMemo(() => {
    const faqCollections = configData.FAQ?.COLLECTIONS;

    const faqs = flatten(
      (faqCollections || []).map((collection) => collection?.faqList || []),
    );
    return { faqs };
  }, [configData.FAQ?.COLLECTIONS]);

  return (
    <div
      style={{ width: 400 }}
      className="overflow-hidden relative rounded-lg widget-preview-home-outer shadow-lg bg-white h-full"
    >
      <StyledWidgetContainer color={color}>
        <div className="h-full w-full flex flex-col">
          <div
            className="tab-container flex-1"
            style={{ height: "calc(100% - 75px)" }}
          >
            <div className="page-overflow-container h-full w-full flex-1 flex flex-col pb-4">
              <div
                className="w-full absolute z-0 top-0 primary-gradient-bg-3"
                style={{ height: "50vh" }}
              >
                <div
                  className="w-full h-full"
                  style={{
                    backgroundImage:
                      data.HOME_HEADER.HEADER_PATTERN || undefined,
                  }}
                />
                <div
                  className="w-fill py-20 -mt-10 absolute bottom-0 w-full"
                  style={{
                    background:
                      "linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 90%, rgb(255, 255, 255) 100%)",
                  }}
                />
              </div>
              <div className="flex flex-col text-white animate fade-in-down top-0 w-full z-0 mb-2">
                <div className="flex flex-col max-container w-full p-6 pt-2">
                  <div className="top-menu flex flex-row justify-end items-center  w-full">
                    <div className="relative">
                      <span
                        role="img"
                        aria-label="ellipsis"
                        className="anticon anticon-ellipsis text-3xl cursor-pointer mt-3 h-8 w-12 flex items-center justify-center rounded-full hover:bg-black hover:bg-opacity-25 outline-none"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="ellipsis"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="greeting-message-container text-3xl font-bold w-full mt-24">
                    {data.HOME_HEADER.WELCOME_TEXT}
                  </div>
                  <div className="guide-message-container text-lg  w-full">
                    {data.HOME_HEADER.HEADER_TAGLINE}
                  </div>

                  <ChatWidgetAvatarGroup data={data.HOME_HEADER} />
                </div>
              </div>
              <div className="w-full pb-4">
                <div className="home-page-widget m-4 mt-0 p-3 rounded-lg animate fade-in-right  bg-white">
                  <div className="title-section flex flex-row justify-between items-center mb-2">
                    <div className="font-semibold text-lg text-gray-800">
                      <div className="bg-gray-200 rounded-lg p-2 flex justify-center items-center">
                        <div
                          className="icon-container"
                          style={{ width: "1.2rem", height: "1.2rem" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="1.2rem"
                            height="1.2rem"
                            className="icon-svg"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M14.45 19L12 22.5 9.55 19H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-6.55z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center px-2 flex-1">
                      <div className="">
                        <div className="font-semibold text-gray-800">
                          Your Conversations
                        </div>
                      </div>
                      <div className="text-gray-600 text-xs  cursor-pointer">
                        See All
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="conversation-list-container animate fade-in-right">
                      <div className="flex flex-row pointer hover:bg-gray-300 p-4 cursor-pointer rounded-lg">
                        <div className="user-avatar-container">
                          <ChatWidgetUserAvatarContainer
                            data={data.HOME_HEADER.AVATARS_IMAGES}
                          />
                        </div>
                        <div className="message-container flex-col flex-1 overflow-hidden">
                          <div className="first-line flex flex-row items-center">
                            <div className="name flex-1 overflow-hidden whitespace-no-wrap text-overflow-ellipses">
                              Sales Team
                            </div>
                            <div className="last-activity-data text-xs text-gray-600">
                              17 Jul, 10:19 AM
                            </div>
                          </div>
                          <div className="last-message text-gray-600 text-sm overflow-hidden whitespace-no-wrap text-overflow-ellipses">
                            Next Message
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="powered-by-cc flex flex-row justify-center items-center bg-white text-sm font-semibold p-2">
                <img
                  src="/logo192.png"
                  alt="CC Logo"
                  style={{
                    height: 20,
                    filter: "brightness(0)",
                    marginRight: "0.1rem",
                  }}
                />
                Powered by
                <a
                  href="https://clickconnector.com/"
                  target="_blank"
                  className="ml-1"
                >
                  ClickConnector
                </a>
              </div> */}
                </div>

                {faqs.length > 0 && (
                  <div>
                    <div className="home-page-widget m-4 mt-0 p-3 rounded-lg animate fade-in-right  bg-white">
                      <div className="title-section flex flex-row justify-between items-center mb-2">
                        <div className="font-semibold text-lg text-gray-800">
                          <div className="bg-gray-200 rounded-lg p-2 flex justify-center items-center">
                            <div
                              className="icon-container"
                              style={{ width: "1.3rem", height: "1.3rem" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="1.3rem"
                                height="1.3rem"
                                className="icon-svg"
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M5.455 15L1 18.5V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v12H5.455zm-.692-2H16V4H3v10.385L4.763 13zM8 17h10.237L20 18.385V8h1a1 1 0 0 1 1 1v13.5L17.545 19H9a1 1 0 0 1-1-1v-1z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row justify-between items-center px-2 flex-1">
                          <div className="">
                            <div className="font-semibold text-gray-800">
                              Frequently Asked Questions
                            </div>
                            <div className="text-gray-600 text-sm">
                              Got Questions?
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="search-bar flex flex-row items-center justify-between cursor-pointer px-6 bg-gray-200 hover:bg-gray-300 rounded-md">
                        <input
                          placeholder="Search FAQs"
                          className="flex-1 border-b border-solid border-gray-200 pb-2 pt-2 cursor-pointer  outline-none bg-gray-200 hover:bg-gray-300 font-bold"
                        />
                        <div
                          className="icon-container mr-2"
                          style={{ width: "1.3rem", height: "1.3rem" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="1.3rem"
                            height="1.3rem"
                            className="icon-svg"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
                          </svg>
                        </div>
                      </div>
                      <div className="faq-list-container mt-4">
                        {faqs.map((faq) => (
                          <div
                            className="flex flex-row hover-text-primary hover:bg-gray-200 p-2 cursor-pointer justify-between items-center rounded-lg"
                            key={faq?.question}
                          >
                            <div className="title truncate mr-4">
                              {faq?.question}
                            </div>
                            <div
                              className="icon-container"
                              style={{ width: "1.2rem", height: "1.2rem" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="1.2rem"
                                height="1.2rem"
                                className="icon-svg"
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                              </svg>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="browse-all-button p-4">
                        <div className="secondary-button flex items-center justify-center font-semibold rounded-lg cursor-pointer p-2">
                          <div className="flex flex-row">
                            <div
                              className="icon-container mr-2"
                              style={{ width: "1.3rem", height: "1.3rem" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="1.3rem"
                                height="1.3rem"
                                className="icon-svg"
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
                              </svg>
                            </div>{" "}
                            Browse All
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="bottom-tabs flex flex-row justify-center items-center  border-t border-gray-200 z-50 bg-white relative"
            style={{
              height: 70,
              boxShadow: "rgba(0, 0, 0, 0.06) 0px 0px 30px 10px",
            }}
          >
            <span
              className="absolute bottom-0 top-0 flex overflow-hidden transition-all duration-300 flex-col"
              style={{ left: 0, width: 137, zIndex: 10 }}
            >
              <span className="h-1 w-full rounded-lg bg-gray-300" />
            </span>
            <span
              className="absolute bottom-0 top-0 flex overflow-hidden transition-all duration-300 flex-col"
              style={{ left: 0, width: 137, zIndex: -10 }}
            >
              <span className="h-full w-full rounded-lg bg-gray-200" />
            </span>
            <div
              className="flex-1 flex flex-col justify-center items-center cursor-pointer hover:bg-gray-100 h-full  font-bold text-primary"
              id="bottom-tab-HOME"
            >
              <div className="relative">
                <div
                  className="icon-container"
                  style={{ width: "1.7rem", height: "1.7rem" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="1.7rem"
                    height="1.7rem"
                    className="icon-svg"
                  >
                    <path d="M20 20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20ZM7.5 13C7.5 15.4853 9.51472 17.5 12 17.5C14.4853 17.5 16.5 15.4853 16.5 13H14.5C14.5 14.3807 13.3807 15.5 12 15.5C10.6193 15.5 9.5 14.3807 9.5 13H7.5Z" />
                  </svg>
                </div>
              </div>
              <div className="text-xs">Home</div>
            </div>
            <div
              className="flex-1 flex flex-col justify-center items-center cursor-pointer hover:bg-gray-100 h-full rounded-lg"
              id="bottom-tab-FAQS"
            >
              <div
                className="icon-container"
                style={{ width: "1.7rem", height: "1.7rem" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="1.7rem"
                  height="1.7rem"
                  className="icon-svg"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M5.455 15L1 18.5V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v12H5.455zm-.692-2H16V4H3v10.385L4.763 13zM8 17h10.237L20 18.385V8h1a1 1 0 0 1 1 1v13.5L17.545 19H9a1 1 0 0 1-1-1v-1z" />
                </svg>
              </div>
              <div className="text-xs">FAQs</div>
            </div>
            <div
              className="flex-1 flex flex-col justify-center items-center cursor-pointer hover:bg-gray-100 h-full rounded-lg"
              id="bottom-tab-PROFILE"
            >
              <div
                className="icon-container"
                style={{ width: "1.7rem", height: "1.7rem" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="1.7rem"
                  height="1.7rem"
                  className="icon-svg"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M3.783 2.826L12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zM5 4.604v9.185a4 4 0 0 0 1.781 3.328L12 20.597l5.219-3.48A4 4 0 0 0 19 13.79V4.604L12 3.05 5 4.604zM12 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm-4.473 5a4.5 4.5 0 0 1 8.946 0H7.527z" />
                </svg>
              </div>
              <div className="text-xs">My Profile</div>
            </div>
          </div>
        </div>
      </StyledWidgetContainer>
    </div>
  );
};
