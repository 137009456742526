/* eslint-disable react-refresh/only-export-components */
import Mention from "../mention";

const displayOnTopStyes = {
  mention: "draftJsMentionPlugin__mention__29BEd",
  mentionSuggestionsEntry:
    "draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm",
  mentionSuggestionsEntryFocused:
    "draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm",
  mentionSuggestionsEntryText:
    "draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq",
  mentionSuggestionsEntryAvatar:
    "draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9",
  mentionSuggestions: "draftJsMentionPlugin__mentionSuggestions__ fixed-top",
};
const mentionPluginRegistry = [];
const [userMentions, UserMentionSuggestions] = Mention({
  mentionPluginRegistry,
  mentionTrigger: ["@"],
  entityMutability: "IMMUTABLE",
  // Popper JS is used for positioning
  // popperOptions
});

const [messagePresetsShortcuts, MessagePresetsSuggestions] = Mention({
  mentionPluginRegistry,
  mentionTrigger: "/",
  entityMutability: "IMMUTABLE",
  // Popper JS is used for positioning
  // popperOptions
  theme: displayOnTopStyes,
});

const [actionShortcuts, ActionShortcutsSuggestions] = Mention({
  mentionPluginRegistry,
  mentionTrigger: "\\",
  entityMutability: "IMMUTABLE",
  // Popper JS is used for positioning
  // popperOptions
  theme: displayOnTopStyes,
});

export {
  ActionShortcutsSuggestions,
  MessagePresetsSuggestions,
  UserMentionSuggestions,
  // Actions
  actionShortcuts,
  // Message Presets
  messagePresetsShortcuts,
  userMentions,
};
