import { Form, Select } from "antd";
import { StyledSwitch } from "components/common/styled-swtich";
import { ChatBotSelector } from "components/pages/chat-bots/chat-bot-selector/chat-bot-selector";
import { ConnectionDataEditor } from "../common-connection-settings/connection-data-editor";

export const ConnectionStartWithChatBotConfig = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  return (
    <ConnectionDataEditor
      connectionId={connectionId!}
      icon={"ri-robot-line"}
      title="Connect Chat Bot"
      description="Connect a Chat Bot when a conversation starts"
    >
      <StyledSwitch
        name={["data", "startWithChatBot", "isEnabled"]}
        label="Enabled"
      />
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const configEnabled = getFieldValue([
            "data",
            "startWithChatBot",
            "isEnabled",
          ]);
          if (configEnabled) {
            return (
              <div>
                <Form.Item
                  name={["data", "startWithChatBot", "chatBotId"]}
                  label="Select Chatbot Flow"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Chatbot flow",
                    },
                  ]}
                >
                  <ChatBotSelector />
                </Form.Item>
                <Form.Item
                  label="Activate Bot For"
                  name={["data", "startWithChatBot", "activateOn"]}
                  rules={[
                    {
                      required: true,
                      message: "Please select a an option",
                    },
                  ]}
                >
                  <Select placeholder="Select an option">
                    <Select.Option
                      key={"ALL_NEW_CONVERSATION"}
                      value={"ALL_NEW_CONVERSATION"}
                    >
                      New Chats and Re-Opened Chats
                    </Select.Option>
                    <Select.Option key={"NEW_LEAD"} value={"NEW_LEAD"}>
                      New Chats Only
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            );
          }
          return <></>;
        }}
      </Form.Item>
    </ConnectionDataEditor>
  );
};
