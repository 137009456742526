import { QueryConfig } from "store/utils/query-config";

// * Notes
// Protobi Query does not Support a lot of MongoDB queries
// eg: members.userId = 'xxxx'
// to overcome this, you need to use $elemMatch again
// before writing any conversation queries anywhere, make sure to test it with both  MongoDB and Protobi/Query

export const buildChatViewQueryConfigs = (
  currentUserId: string
): {
  [alias: string]: QueryConfig;
} => {
  return {
    "All Assigned": {
      query: {
        status: "OPEN",
        // members: { $elemMatch: { $exists: true } }, // Protobi Does not support this
        // "members.0": { $exists: true },
        hasMembers: true,
      },
      options: {
        sortBy: ["-metaData.lastMessage.timestamp"],
      },
    },
    "My Inbox": {
      query: {
        status: "OPEN",
        members: {
          $elemMatch: {
            userId: currentUserId,
          },
        },
      },
      options: {
        sortBy: ["-metaData.lastMessage.timestamp"],
      },
    },
    Unassigned: {
      query: {
        status: "OPEN",
        // protobi/query does not support empty array equal
        // members: { $size: 0 },
        hasMembers: false,
        hasActiveBot: false,
        //  I changed this to optimize the query, however i did not test it front end
        // activeBot: {
        //   $in: [null, ""],
        // },
        // $or: [
        //   {
        //     activeBot: { $exists: false }
        //   },
        //   {
        //     activeBot: { $exists: true, $eq: "" }
        //   }
        // ]
      },
      options: {
        sortBy: ["-metaData.lastMessage.timestamp"],
      },
    },
    "Requires Attention": {
      query: {
        status: "OPEN",
        // protobi/query does not support empty array equal
        // "members.0": { $exists: true },
        hasMembers: true,
        "metaData.flags.requiresAttention.isActive": true,
      },
      options: {
        sortBy: ["-metaData.lastMessage.timestamp"],
      },
    },
    "Active Qualifications": {
      query: {
        status: "OPEN",
        // protobi/query does not support empty array equal
        // members: { $size: 0 },
        hasMembers: false,
        // activeBot: { $exists: true, $ne: "" },
        hasActiveBot: true,
      },
      options: {
        sortBy: ["-metaData.lastMessage.timestamp"],
      },
    },
  };
};
