import classNames from "classnames";
import React, { memo } from "react";
import { Avatar } from "./avatar";
import "./avatar.scss";
import { useContactAvatar } from "./use-contact-avatar";

export const ContactAvatar = memo(
  ({
    contactId,
    connectionId,
    size = 32,
    style,
    className,
    hideMedium,
  }: {
    contactId: string;
    connectionId?: string;
    size?: number;
    style?: React.CSSProperties;
    className?: string;
    hideMedium?: boolean;
  }) => {
    const { name, isOnline, avatar, connectionType } = useContactAvatar(
      contactId,
      connectionId,
    );

    return (
      <div
        className={classNames(
          "user-avatar",
          connectionType === "WEB_CHAT" ? (isOnline ? "online" : "away") : "",
        )}
        style={{ width: size }}
      >
        {!hideMedium && (
          <>
            {connectionType === "FACEBOOK" && (
              <div className="medium-icon facebook">
                <i className="ri-facebook-box-fill"></i>
              </div>
            )}
            {(connectionType === "WHATS_APP_PRIVATE" ||
              connectionType === "WHATS_APP_OFFICIAL" ||
              connectionType === "WHATS_APP_OFFICIAL" ||
              connectionType === "WHATS_APP_CLOUD_NATIVE") && (
              <div className="medium-icon whatsapp">
                <i className="ri-whatsapp-fill"></i>
              </div>
            )}

            {connectionType === "EMAIL" && (
              <div className="medium-icon email">
                <i className="ri-mail-fill"></i>
              </div>
            )}
            {connectionType === "INSTAGRAM" && (
              <div className="medium-icon instagram">
                <i className="ri-instagram-fill"></i>
              </div>
            )}
            {connectionType === "TELNYX" && (
              <div className="medium-icon telnyx">
                <i className="ri-smartphone-fill"></i>
              </div>
            )}
            {connectionType === "TWITTER" && (
              <div className="medium-icon twitter">
                <i className="ri-twitter-fill"></i>
              </div>
            )}
            {connectionType === "TELEGRAM" && (
              <div className="medium-icon telegram">
                <i className="ri-telegram-fill"></i>
              </div>
            )}
          </>
        )}

        <Avatar
          name={name}
          avatarUrl={avatar}
          size={size}
          style={style}
          className={className}
        />
      </div>
    );
  },
);
