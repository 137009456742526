export const CommonChannelPowerSettings = [
  // {
  //   label: "Connection Name",
  //   description: "Change this connection's name",
  //   icon: <i className="ri-folder-info-line" />,
  // },
  {
    label: "Restrict Access by Teams, Departments or Custom Groups",
    description: "change who can access this connection",
    icon: <i className="ri-lock-line" />,
  },
  {
    label: `Make Conversation Tags Mandatory`,
    description: `Users will not be allowed to continue chatting without tagging the chat`,
    icon: <i className="ri-price-tag-3-line" />,
  },
  {
    label: "Email Notifications",
    description:
      "Choose to receive email notifications on new chats and new messages",
    icon: <i className="ri-notification-3-fill" />,
  },
  {
    label: "User Limits",
    description: "Prevent users from being assigned too many chats",
    icon: <i className="ri-git-commit-fill" />,
  },
];

export const CommonChannelRoutingSettings = [
  {
    label: "Default Chat Routing Rules",
    description:
      "Choose how chats are routed to users when no chatbots or automation rules are enabled",
    icon: <i className="ri-arrow-go-forward-line" />,
  },
];

export const CommonChatBotConfigSettings = [
  {
    label: "Connect Chat Bot",
    description: "Connect a Chat Bot when a conversation starts",
    icon: <i className="ri-robot-line" />,
  },
];

const mailWorkflowSettings = [
  {
    label: "Confirmation Email on New Ticket",
    description: "Send a confirmation email when a ticket is created",
    icon: <i className="ri-chat-new-line" />,
  },
  {
    label: "Confirmation Email on Closing a Ticket",
    description: "Send a confirmation email when a ticket is closed",
    icon: <i className="ri-inbox-archive-line" />,
  },
  {
    label: "Send Replies as Ticket Updates",
    description: "Format all replies in a standard ticket template",
    icon: <i className="ri-layout-top-2-line" />,
  },
];

const emailPowerSettings = [
  {
    label: "Spam Filter",
    description: "Enable/disable default email spam filter",
    icon: <i className="ri-spam-line" />,
  },
  {
    label: "Read 'Reply-to' Headers as Sender",
    description: "Automatically read 'Reply-to' headers as Sender",
    icon: <i className="ri-mail-star-line" />,
  },
  {
    label: "Whitelisted Forwarding Emails",
    description:
      "When emails are forwarded from these address, ClickConnector will attempt to parse the original email that is forwarded",
    icon: <i className="ri-mail-lock-line" />,
  },
  {
    label: "Enable Simple Chat Ids",
    description:
      "5 digit Chat ID allows you to easily refer conversations with customer",
    icon: <i className="ri-chat-settings-line" />,
  },
];

const widgetAppearance = [
  {
    label: "Customize Look and Feel",
    description:
      "Change widget colour, header pattern, chat bubble style, position and size",
    icon: <i className="ri-palette-line" />,
  },
  {
    label: "Widget Language and Text Customization",
    description:
      "customize greeting messages, team name, and other texts/content in chat widget",
    icon: <i className="ri-text" />,
  },
];

const widgetGreetings = [
  {
    label: "Setup Greetings and Chat Bot",
    description:
      "Change widget colour, header pattern, chat bubble style, position and size",
    icon: <i className="ri-chat-smile-line" />,
  },
  ...CommonChannelRoutingSettings,
];

const chatWidgetFeatures = [
  {
    label: "Auto-Email Follow-Up",
    description:
      "When customer is not on your website, send messages sent by users to customers via email",
    icon: <i className="ri-mail-send-fill" />,
  },
  {
    label: "Enable Shopify Plugin",
    description:
      "Allow your customers to check order status right from ClickConnector",
    icon: (
      <img
        src="/assets/integrations/shopify.png"
        style={{ width: "2rem" }}
        alt=""
        className="mt-2"
      />
    ),
  },
  {
    label: "Enable Chat Ratings",
    description: "Collect feedback after the conversation is ended",
    icon: <i className="ri-emotion-happy-line" />,
  },
  {
    label: "Show Knowledge Base",
    description:
      "Display your Knowledge Base articles right inside the chat widget",
    icon: <i className="ri-book-line" />,
  },
  {
    label: "Pre-chat Form",
    description:
      "Collect data through a simple form before a user starts to chat",
    icon: <i className="ri-file-list-line" />,
  },
  {
    label: "Setup FAQs (Depreciated - Use Knowledge Base instead)",
    description:
      "Setup up most frequently asked questions for your customers to browse",
    icon: <i className="ri-question-answer-line" />,
  },
  {
    label: "Setup Online Hours",
    description:
      "Let customers know when they can expect to connect with your team",
    icon: <i className="ri-rest-time-line" />,
  },
  {
    label: "Temporary Widget Announcements",
    description: "Show a simple temporary announcement to all customers",
    icon: <i className="ri-megaphone-line" />,
  },
];

const widgetPowerSettings = [
  {
    label: "Chatbot Profile",
    description:
      "Customize the avatar and name of chatbot in the live chat widget",
    icon: <i className="ri-robot-line" />,
  },
  {
    label: "Allow Multiple Active Chats",
    description:
      "Customer are allowed to start a second chat while one conversation is open",
    icon: <i className="ri-chat-settings-line" />,
  },
  {
    label: "Auto Widget Popup",
    description: "Automatically open the chat widget when there is new message",
    icon: <i className="ri-chat-upload-line" />,
  },
  {
    label: "Remove ClickConnector Branding",
    description:
      "Remove ClickConnector branding in the home screen of the live chat widget.",
    icon: <i className="ri-honour-line" />,
  },
  {
    label: "Connected Ticket Connection",
    description:
      "If you are going to use Chat Widget APIs to create a conversation programmatically, you'll need to set up the ticket connection",
    icon: <i className="ri-mail-settings-line" />,
  },
  {
    label: "Page Navigation Activities",
    description: "Show Customer Page Navigation Activity in Chat Messages",
    icon: <i className="ri-run-line" />,
  },
  {
    label: "Blacklisted Clients",
    description:
      "disable the widget if a person tries to access chat through specific IPs",
    icon: <i className="ri-spam-3-line" />,
  },
  {
    label: "Whitelisted Domains",
    description: "Domains where the widget can be embedded",
    icon: <i className="ri-shield-check-line" />,
  },
  {
    label: "Automatic Form Submission Tracking",
    description:
      "Track form submissions and associated data as activity of customer",
    icon: <i className="ri-file-info-line" />,
  },
  {
    label: "Disable Widget",
    description: "Disable the widget on sites temporarily",
    icon: <i className="ri-git-commit-fill" />,
  },
];

export const EmailChannelSettings = [
  ...CommonChannelRoutingSettings,
  ...CommonChannelPowerSettings,
  ...mailWorkflowSettings,
  ...emailPowerSettings,
];

export const FBChannelSettings = [
  ...CommonChannelRoutingSettings,
  ...CommonChannelPowerSettings,
  ...CommonChatBotConfigSettings,
  {
    label: "Auto Campaign Routing",
    description: "Route Conversations based on campaigns",
    icon: <i className="ri-route-line" />,
  },
];

export const IGChannelSettings = [
  ...CommonChannelRoutingSettings,
  ...CommonChannelPowerSettings,
  ...CommonChatBotConfigSettings,
];

export const WidgetChannelSettings = [
  ...CommonChannelRoutingSettings,
  ...CommonChannelPowerSettings,
  ...widgetAppearance,
  ...widgetGreetings,
  ...chatWidgetFeatures,
  ...widgetPowerSettings,
];

export const WhatsappPrivateChannelSetting = [
  ...CommonChannelRoutingSettings,
  ...CommonChannelPowerSettings,
  // Custom Buttons
  {
    label: "Sync Conversations",
    description: "",
    icon: <i className="ri-refresh-line"></i>,
  },
  {
    label: "Reboot Instance",
    description: "",
    icon: <i className="ri-restart-line"></i>,
  },
  {
    label: "Reset Instance",
    description: "",
    icon: <i className="ri-shut-down-line"></i>,
  },
];

export const WhatsappCloudChannelSetting = [
  ...CommonChannelRoutingSettings,
  ...CommonChannelPowerSettings,
  // Custom Buttons
  {
    label: "Manage Templates",
    description: "",
    icon: <i className="ri-message-2-line"></i>,
  },
  {
    label: "Message Insights",
    description: "",
    icon: <i className="ri-bar-chart-box-line"></i>,
  },
];

export const PhoneChannelChannelSetting = [
  ...CommonChannelRoutingSettings,
  ...CommonChannelPowerSettings,
  // Todo
];

export const TelegramChannelSetting = [
  ...CommonChannelRoutingSettings,
  ...CommonChatBotConfigSettings,
  ...CommonChannelPowerSettings,
];
