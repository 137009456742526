import { iUploadValidation } from "./upload-validation-model";

export const ImageTypeValidation: iUploadValidation = {
  type: "FILE_TYPE",
  acceptedTypes: ["image/jpeg", "image/png"],
};

export const FileSizeValidation_2mb: iUploadValidation = {
  type: "FILE_SIZE",
  maxSize: 2, // 2mb
};

export const ImageDimensionsValidation_Logo: iUploadValidation = {
  type: "IMAGE_DIMENSIONS",
  maxWidth: 1080,
  maxHeight: 1080,
  minWidth: 256,
  minHeight: 70,
};

export const ImageDimensionsValidation_Avatar: iUploadValidation = {
  type: "IMAGE_DIMENSIONS",
  maxWidth: 1080,
  maxHeight: 1080,
  minWidth: 128,
  minHeight: 128,
};

export const LogoUploadValidations: iUploadValidation[] = [
  ImageTypeValidation,
  FileSizeValidation_2mb,
  ImageDimensionsValidation_Logo,
];

export const AvatarUploadValidations: iUploadValidation[] = [
  ImageTypeValidation,
  FileSizeValidation_2mb,
  ImageDimensionsValidation_Avatar,
];
