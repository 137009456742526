import { useCCCampaignTrigger } from "@libs/cc-widget/use-cc-campaign-trigger";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { selectIsInsiderUser } from "store/modules/workspace/workspace.selectors";

export const InsiderFeedbackCollector = ({
  text,
  className,
}: {
  text?: string;
  className?: string;
}) => {
  const isInsider = useSelector(selectIsInsiderUser);

  const loadChatWithCampaign = useCCCampaignTrigger();

  const isMobileView = useMediaQuery({ query: "(max-width: 500px)" });

  if (isMobileView || !isInsider) {
    return <></>;
  }

  return (
    <div
      className={classNames(
        "border-2 p-2 border-black rounded-lg dark:border-gray-800 cursor-pointer",
        className,
      )}
      onClick={() =>
        loadChatWithCampaign(
          `Go on! Shoot what you've got. We are always listening to your suggestions`,
        )
      }
    >
      <div className="flex flex-row items-center">
        <div className="bg-black text-white p-2 rounded-lg font-bold dark:bg-gray-800 ">
          <i className="ri-user-star-line"></i> Hey Insider 👋
        </div>
        <div className="ml-2 font-bold">
          {text || `How can we improve your experience?`}
        </div>
      </div>
    </div>
  );
};
