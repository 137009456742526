import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ChangeLogsModal = React.lazy(() => import("./change-logs.modal"));

const ChangeLogs: iModalProps<typeof ChangeLogsModal, {}> = {
  name: "change-logs",
  component: GenerateModalWithSuspense(ChangeLogsModal),
};

export default ChangeLogs;
