import {
  ActionId,
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarResults,
  KBarSearch,
  VisualState,
  useKBar,
  useMatches,
  useRegisterActions,
} from "@libs/kbar";
import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { Button, Spin } from "antd";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import { KeyboardShortcut } from "components/common/keyboard-shortcut/keyboard-shortcut";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { GetContactIdentifier } from "components/modules/crm/contacts/helpers/get-contact-identifier";
import { getContactName } from "components/modules/crm/contacts/helpers/get-contact-name";
import { push } from "connected-react-router";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";

import classNames from "classnames";
import HelpCenter from "components/common/help-center/help-center.modal-registry";
import ContactProfile from "components/pages/contacts/components/contact-profile/contact-profile-v2.modal-registry";
import { TicketIcon } from "components/pages/tickets/components/ticket-icon";
import TicketView from "components/pages/tickets/components/ticket-view/ticket-view.modal-registry";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useStore } from "react-redux";
import { UserTracker } from "user-tracker";
import { GlobalEventEmitter } from "utils/global-event-emitter";
import { useDebouncedValue } from "utils/hooks/use-debounced-value";
import { useUrlState } from "utils/hooks/use-url-state";
import "./k-bar.scss";
import { useGlobalSearch } from "./use-global-search";

const searchStyle = {
  width: "100%",
  boxSizing: "border-box" as React.CSSProperties["boxSizing"],
  outline: "none",
  border: "none",
  // background: "var(--background)",
  // color: "var(--foreground)",
};

const animatorStyle = {
  maxWidth: "600px",
  width: "100%",
  // background: "var(--background)",
  // color: "var(--foreground)",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: "var(--shadow)",
};

const groupNameStyle = {
  padding: "8px 16px",
  fontSize: "10px",
  textTransform: "uppercase" as const,
  opacity: 0.5,
};

export const KBar = () => {
  const store = useStore();
  const { results } = useMatches();

  const [globalShortcuts, setGlobalShortcuts] = useState([] as any[]);

  useEffect(() => {
    const registerEvents = (data) => {
      setGlobalShortcuts((globalEvents) => [...globalEvents, ...data]);
    };
    const removeEvents = (data) => {
      setGlobalShortcuts((globalEvents) => _.without(globalEvents, ...data));
    };
    GlobalEventEmitter.addListener("SHORT_CUT_REGISTRY_ADD", registerEvents);
    GlobalEventEmitter.addListener("SHORT_CUT_REGISTRY_REMOVE", removeEvents);
    return () => {
      GlobalEventEmitter.removeListener("SHORT_CUT_REGISTRY", registerEvents);
      GlobalEventEmitter.removeListener(
        "SHORT_CUT_REGISTRY_REMOVE",
        removeEvents,
      );
    };
  }, []);

  const actions = useMemo(() => {
    const viewActions = {
      id: "VIEW_ACTIONS",
      icon: <i className="ri-arrow-right-wide-line"></i>,
      subtitle: "All actions that you can execute from your current view",
      name: "Actions",
      shortcut: ["$mod+p"],
      keywords: "View Actions",
    };
    const actions = [...globalShortcuts, viewActions];
    return actions;
  }, [globalShortcuts]);

  useRegisterActions(actions, [actions]);

  const { visualState } = useKBar((state) => ({
    visualState: state.visualState,
  }));

  useEffect(() => {
    if (visualState === VisualState.showing) {
      UserTracker.track("Global Search - Visible", {});
    }
  }, [visualState]);

  return (
    <KBarPortal>
      <KBarPositioner className="kb-mask">
        <KBarAnimator style={animatorStyle} className="dark-gradient-bg">
          <div className="absolute top-4 left-4">
            <i className="ri-search-line"></i>
          </div>
          <KBarSearch
            style={searchStyle}
            className="bg-white dark:bg-black p-4 px-10 pb-2"
            defaultPlaceholder="Search, Create, Navigate, Execute Actions…"
          />
          <RenderResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};

const useDebouncedSearchBar = () => {
  const { search } = useKBar((state) => ({
    search: state.searchQuery,
  }));

  const [searchText] = useDebouncedValue(search, 600);
  return searchText;
};

function RenderResults() {
  const search = useDebouncedSearchBar();

  const { results, rootActionId } = useMatches();
  //  Todo: use parent and section property to group the results
  // Todo: You can also use priority: Priority.LOW

  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    setProcessing(true);
    const timer = setTimeout(() => {
      setProcessing(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const entitySearchResults = useGlobalSearch(search);

  useEffect(() => {}, [entitySearchResults]);

  const { changePanelState, triggerTempModal } = useModalPanels();
  const [isHelpCenterVisible, setHelpCenterVisibility] = useUrlState(
    "activate-help-center",
  );

  const store = useStore();

  // Todo: Ideally this needs to be separated out in different hooks to stop extra processing
  const kbSearchBindings = useMemo(() => {
    const conversations = entitySearchResults.conversations
      .slice(0, 5)
      .map((conversation) => ({
        id: conversation.id!,
        section: "Matched Conversations",
        icon: (
          <ContactAvatar
            contactId={conversation.contactId}
            connectionId={conversation.connectionId}
          />
        ),
        subtitle: (conversation as any).contact
          ? `conversation with ${(conversation as any).contact?.data
              ?.firstName} ${(conversation as any).contact?.data
              ?.lastName} started on ${dayjs(
              conversation.metaData.createdAt,
            ).format("DD-MM-YYYY")}`
          : undefined,
        name: `${conversation.subject}`,
        keywords: `${conversation.subject} tags: ${conversation.tags?.join(
          " ",
        )} ${conversation.contact?.data?.firstName}  ${conversation.contact
          ?.data?.lastName}  ${conversation.contact?.data
          ?.primaryEmail}  ${conversation.contact?.data?.primaryMobile} ${
          conversation.id
        } # ${conversation.data.simpleId || ""} ${
          entitySearchResults.conversationSearchTerm
        }`,
        perform: () =>
          store.dispatch(push(`/conversations/auto/${conversation.id}`)),
      }));

    const tickets = entitySearchResults.tickets.slice(0, 5).map((ticket) => ({
      id: ticket.id!,
      section: "Matched Trackers",
      icon: <TicketIcon ticketTypeId={ticket.ticketTypeId} />,
      subtitle: (ticket as any).contact
        ? `tracker created on ${dayjs(ticket.createdAt).format("DD-MM-YYYY")}`
        : undefined,
      name: `${ticket.label}`,
      keywords: `${ticket.label} tags: ${ticket.tags?.join(" ")} ${ticket
        .primaryContact?.data?.firstName}  ${ticket.primaryContact?.data
        ?.lastName}  ${ticket.primaryContact?.data?.primaryEmail}  ${ticket
        .primaryContact?.data?.primaryMobile} ${ticket.id} # ${
        ticket.body || ""
      }`,
      perform: () =>
        changePanelState(TicketView, true, { ticketId: ticket.id! }),
    }));

    const contacts = entitySearchResults.contacts
      .slice(0, 5)
      .map((contact) => ({
        id: contact.id!,
        section: "Matched Contacts",
        icon: <ContactAvatar contactId={contact.id!} />,
        subtitle: `${GetContactIdentifier(contact)} | Record created on ${dayjs(
          contact.metaData.createdTime,
        ).format("DD-MM-YYYY")}`,
        name:
          getContactName(contact) ||
          contact?.data?.primaryEmail ||
          contact?.data?.primaryMobile ||
          "",
        keywords: `${getContactName(contact)} ${contact.data.tags?.join(
          " ",
        )} ${contact?.data?.primaryEmail}  ${contact?.data?.primaryMobile}`,
        perform: () => {
          changePanelState(ContactProfile, true, { contactId: contact.id! });
        },
      }));

    const opportunities = entitySearchResults.opportunities
      .slice(0, 5)
      .map((opportunity) => ({
        id: opportunity.id!,
        section: "Matched Opportunities",
        icon: <i className="ri-money-dollar-box-line"></i>,
        subtitle: (opportunity as any).contact
          ? `conversation with ${(opportunity as any).contact?.data
              ?.firstName} ${(opportunity as any).contact?.data
              ?.lastName} started on ${dayjs(opportunity.createdAt).format(
              "DD-MM-YYYY",
            )}`
          : undefined,
        name: opportunity.title,
        keywords: `${opportunity.title} ${opportunity.tags?.join(
          " ",
        )} ${opportunity.contact?.data?.firstName}  ${opportunity.contact?.data
          ?.lastName}  ${opportunity.contact?.data?.primaryEmail}  ${opportunity
          .contact?.data?.primaryMobile}`,
        perform: () =>
          store.dispatch(push(`/opportunities?opportunity=${opportunity.id}`)),
      }));
    const articles = entitySearchResults.articles
      .slice(0, 5)
      .map((article) => ({
        id: article.id!,
        section: "Matched Help Desk Articles",
        icon: <i className="ri-question-line"></i>,
        subtitle: article.description,
        name: article.title,
        keywords: `${article.title} ${article.description} ${article.tags?.join(
          " ",
        )}`,
        perform: () => {
          setHelpCenterVisibility("YES", {
            "help-center-article": article.id!,
            // "help-center-collection": collectionId,
          });
          triggerTempModal(HelpCenter, {});
        },
      }));

    const contactLists = entitySearchResults.contactLists
      .slice(0, 5)
      .map((contactList) => ({
        id: contactList.id!,
        section: "Matched Contact Lists",
        icon: <i className="ri-mail-send-line"></i>,
        // subtitle: contactList.data?.subjectLine,
        name: contactList.data.label,
        keywords: `${contactList.data.label}`,
        perform: () => {
          store.dispatch(
            push(
              `/customers?contact-filter=((key:marketingList,operator:IS_IN,operatorConfig:(value:${contactList.id})))`,
            ),
          );
        },
      }));

    // Todo: Action Needs to be decided

    // const campaigns = entitySearchResults.campaigns
    //   .slice(0, 5)
    //   .map((campaign) => ({
    //     id: campaign.id!,
    //     section: "Matched Campaigns",
    //     icon: <i className="ri-mail-send-line"></i>,
    //     subtitle: campaign.data?.subjectLine,
    //     name: campaign.label,
    //     keywords: `${campaign.label} ${campaign.data?.subjectLine}`,
    //     perform: () => {
    //       store.dispatch(push(`/campaigns`));
    //     },
    //   }));

    // const chatBots = entitySearchResults.chatBots
    //   .slice(0, 5)
    //   .map((chatBot) => ({
    //     id: chatBot.id!,
    //     section: "Matched Chat Bots",
    //     icon: <i className="ri-mail-send-line"></i>,
    //     // subtitle: chatBot.data?.subjectLine,
    //     name: chatBot.label,
    //     keywords: `${chatBot.label}`,
    //     perform: () => {
    //       store.dispatch(push(`/campaigns`));
    //     },
    //   }));

    // const collections = entitySearchResults.collections
    //   .slice(0, 5)
    //   .map((collection) => ({
    //     id: collection.id!,
    //     section: "Matched Collections",
    //     icon: <i className="ri-mail-send-line"></i>,
    //     subtitle: collection.description,
    //     name: collection.label,
    //     keywords: `${collection.label} ${collection.description}`,
    //     perform: () => {
    //       store.dispatch(push(`/campaigns`));
    //     },
    //   }));

    // const dynamicForms = entitySearchResults.dynamicForms
    //   .slice(0, 5)
    //   .map((dynamicForm) => ({
    //     id: dynamicForm.id!,
    //     section: "Matched Forms",
    //     icon: <i className="ri-file-text-line"></i>,
    //     // subtitle: dynamicForm.data?.subjectLine,
    //     name: dynamicForm.label,
    //     keywords: `${dynamicForm.label}`,
    //     perform: () => {},
    //   }));

    // const sequences = entitySearchResults.sequences
    //   .slice(0, 5)
    //   .map((sequence) => ({
    //     id: sequence.id!,
    //     section: "Matches Automation Sequences",
    //     icon: <i className="ri-mail-send-line"></i>,
    //     subtitle: `${sequence.steps.length} Step(s)`,
    //     name: sequence.label,
    //     keywords: `${sequence.label}`,
    //     perform: () => {
    //       store.dispatch(push(`/campaigns`));
    //     },
    //   }));

    return [
      ...conversations,
      ...tickets,
      ...contacts,
      ...opportunities,
      ...articles,
      // ...campaigns,
      // ...chatBots,
      // ...collections,
      ...contactLists,
      // ...dynamicForms,
      // ...sequences,
    ];
  }, [
    changePanelState,
    entitySearchResults?.articles,
    entitySearchResults?.contactLists,
    entitySearchResults?.contacts,
    entitySearchResults?.conversationSearchTerm,
    entitySearchResults?.conversations,
    entitySearchResults?.opportunities,
    entitySearchResults?.tickets,
    setHelpCenterVisibility,
    store,
    triggerTempModal,
  ]);

  useRegisterActions(kbSearchBindings, [kbSearchBindings]);

  const { query } = useKBar();

  return (
    <div className="bg-white dark:bg-black">
      {!rootActionId && <GlobalShortcutsHelperTop />}

      <div className="px-4">
        <KBarResults
          items={results}
          onRender={({ item, active }) =>
            typeof item === "string" ? (
              <div
                style={groupNameStyle}
                className="border-t border-gray-200 dark:border-gray-800 mt-2"
              >
                {item}
              </div>
            ) : (
              <ResultItem
                action={item}
                active={active}
                currentRootActionId={rootActionId!}
              />
            )
          }
        />
      </div>

      {isProcessing && search && (
        <div className="text-gray-600 text-sm p-4 flex flex-row items-center justify-center">
          <Spin spinning={true} indicator={<LoadingIndicatorWithSpin />}></Spin>
        </div>
      )}
      {entitySearchResults.isConversationLoading && (
        <div className="text-gray-600 text-sm p-4 flex flex-row items-center">
          <Spin spinning={true} indicator={<LoadingIndicatorWithSpin />}></Spin>
          <div className="text ml-2">Searching Conversations...</div>
        </div>
      )}
      {entitySearchResults.isContactsLoading && (
        <div className="text-gray-600 text-sm p-4 flex flex-row items-center">
          <Spin spinning={true} indicator={<LoadingIndicatorWithSpin />}></Spin>
          <div className="text ml-2">Searching Customers...</div>
        </div>
      )}
      {entitySearchResults.isTicketsLoading && (
        <div className="text-gray-600 text-sm p-4 flex flex-row items-center">
          <Spin spinning={true} indicator={<LoadingIndicatorWithSpin />}></Spin>
          <div className="text ml-2">Searching Trackers...</div>
        </div>
      )}

      {rootActionId && (
        <div className="p-2 w-full flex justify-start items-center">
          <Button
            type="text"
            // size="small"
            icon={<i className="ri-arrow-left-line"></i>}
            onClick={() => {
              query.setCurrentRootAction(null);
            }}
            block
            className="flex flex-row justify-start items-center"
          >
            Previous Menu <KeyboardShortcut label="Backspace" />
          </Button>
        </div>
      )}
      <div className="p-4 flex items-center dark:border-neutral-700 justify-between text-gray-600 w-full">
        <div className="inline-flex items-center gap-x-2">
          <div className="w-5 flex items-center  dark:border-neutral-700">
            <svg
              className="flex-shrink-0 dark:text-neutral-500"
              width={16}
              height={7}
              viewBox="0 0 16 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 4.99712C14.3882 5.37064 13.7772 6 12.938 6C12.0987 6 11.2022 5.04455 11.2022 3.78386V3.19193C11.2022 1.8838 12.0314 1.00102 12.938 1.00102C13.8447 1.00102 14.3662 1.49912 14.5 2.11899M9.65213 1.93635C9.51016 1.44272 8.86395 0.975267 8.11903 1.00102C7.37431 1.02666 6.70773 1.59524 6.70773 2.26932C6.70773 2.9434 7.14855 3.18797 8.11955 3.29149C9.09034 3.39501 9.61853 3.95222 9.65213 4.54259C9.68573 5.13296 9.1754 6 8.11955 6C7.19423 6 6.50191 5.06384 6.46306 4.52706M4.91442 5.08667C4.63772 5.70602 4.06396 5.9999 3.29279 5.9999C2.52162 5.9999 1.5 5.34854 1.5 3.70223V3.22237C1.5 2.13651 2.2571 1.00081 3.29279 1.00081C4.32858 1.00081 5.01753 2.07562 4.91442 3.34559H1.8104"
                stroke="currentColor"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span className="text-xs dark:text-neutral-500">to close</span>
        </div>
        <div className="inline-flex items-center gap-x-4">
          <div className="inline-flex items-center gap-x-2">
            <div className="w-5 v834c flex items-center   dark:border-neutral-700">
              <svg
                className="flex-shrink-0 dark:text-neutral-500"
                width={12}
                height={11}
                viewBox="0 0 12 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 1V4.375C10.5 5.5 9.375 6.625 8.25 6.625H1.5M1.5 6.625L4.875 10M1.5 6.625L4.875 3.25"
                  stroke="currentColor"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <span className="text-xs dark:text-neutral-500">to select</span>
          </div>
          <div className="inline-flex items-center gap-x-2">
            <div className="w-5  items-center   dark:border-neutral-700">
              <svg
                className="flex-shrink-0 dark:text-neutral-500"
                width={10}
                height={11}
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 1V10M5 10L8.5 6.625M5 10L1.5 6.625"
                  stroke="currentColor"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="w-5  items-center  dark:border-neutral-700">
              <svg
                className="flex-shrink-0 dark:text-neutral-500"
                width={10}
                height={11}
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 10V1M5 1L8.5 4.375M5 1L1.5 4.375"
                  stroke="currentColor"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <span className="text-xs  dark:text-neutral-500">to navigate</span>
          </div>
        </div>
      </div>
      <DarkModeBg />
    </div>
  );
}

const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId: ActionId;
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex(
        (ancestor) => ancestor.id === currentRootActionId,
      );
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    return (
      <div
        ref={ref}
        className={classNames(
          "rounded-lg flex items-center justify-between cursor-pointer p-2 w-full",
          {
            "bg-gray-100 dark:bg-gray-900": active,
          },
        )}
      >
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          {action.icon && action.icon}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              {/* Show Parent */}
              {/* {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <React.Fragment key={ancestor.id}>
                    <span
                      style={{
                        opacity: 0.5,
                        marginRight: 8,
                      }}
                    >
                      {ancestor.name}
                    </span>
                    <span
                      style={{
                        marginRight: 8,
                      }}
                    >
                      &rsaquo;
                    </span>
                  </React.Fragment>
                ))} */}
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <span
                style={{ fontSize: 12 }}
                className="text-gray-600 dark:text-gray-400"
              >
                {action.subtitle}
              </span>
            )}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div
            aria-hidden
            style={{ display: "grid", gridAutoFlow: "column", gap: "4px" }}
          >
            {action.shortcut.map((sc) => (
              <KeyboardShortcut key={sc} label={sc} />
              // <kbd
              //   key={sc}
              //   style={{
              //     padding: "4px 6px",
              //     background: "rgba(0 0 0 / .1)",
              //     borderRadius: "4px",
              //     fontSize: 14,
              //   }}
              // >
              //   {replaceKeyBindingToActualKey(sc)}
              // </kbd>
            ))}
          </div>
        ) : null}
      </div>
    );
  },
);

export const GlobalShortcutsHelperTop = () => {
  return (
    <div className="w-full border-b border-t border-gray-200 dark:border-gray-800 mb-8">
      <div className="flex flex-row p-2">
        <div className="p-2">
          <i className="ri-information-2-line"></i>
        </div>
        <div className="flex flex-col">
          {/* <div className="">Searching</div> */}
          <div className="text-gray-600 dark:text-gray-400 text-sm">
            You can search for contacts, chats, engagements and your workspace
            configurations.
          </div>
          <div className="text-gray-600 dark:text-gray-400 text-sm">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Tip: To Search messages, start your search with a character '*'
          </div>
        </div>
      </div>
    </div>
  );
};

export const GlobalShortcutsHelperBottom = () => {
  return <></>;
};
