import { mdiBullhornOutline, mdiCommentSearchOutline } from "@mdi/js";
import {
  ShopifyActions,
  iActionTypes,
} from "@sdk/chat-bots/chat-bot-node-data-model";
import { MDIIcon } from "components/common/mdi-icon";

export const ShopifyActionLabels: Record<ShopifyActions, string> = {
  SHOPIFY_LAST_ORDER_CANCEL: "Cancel Last Order",
  SHOPIFY_ORDER_CANCEL: "Cancel Order",
  SHOPIFY_LAST_ORDER_DUPLICATE: "Duplicate Last Order",
  SHOPIFY_LAST_ORDER_EDIT_SHIPPING_ADDRESS: "Edit Shipping Address",
  SHOPIFY_LAST_ORDER_REFUND_SHIPPING_COST: "Refund Shipping Cost",
  SHOPIFY_LAST_ORDER_REFUND: "RefundLast Order",
  SHOPIFY_LAST_ORDER_PARTIAL_REFUND: "Partially Refund Last Order",
  SHOPIFY_LAST_ORDER_EDIT_NOTE: "Edit Last Order's Note",
};

export const ShopifyActionDescriptions: Record<ShopifyActions, string> = {
  SHOPIFY_LAST_ORDER_CANCEL: "Cancel Last Order of current customer",
  SHOPIFY_ORDER_CANCEL: "Cancel Order with custom order Id of current customer",
  SHOPIFY_LAST_ORDER_DUPLICATE: "Duplicate Order with the order ID",
  SHOPIFY_LAST_ORDER_EDIT_SHIPPING_ADDRESS:
    "Edit Shipping Address of last order",
  SHOPIFY_LAST_ORDER_REFUND_SHIPPING_COST: "Refund Shipping Cost of last order",
  SHOPIFY_LAST_ORDER_REFUND: "Refund last order",
  SHOPIFY_LAST_ORDER_PARTIAL_REFUND: "Partially Refund Last Order",
  SHOPIFY_LAST_ORDER_EDIT_NOTE: "Edit Last Order's Note in Shopify Store",
};

export const ActionLabels: Record<iActionTypes, string> = {
  // Message
  EXTRACT_CONTACT_DATA: "Extract contact data from message",
  // Conversation
  END_CONVERSATION: "Close Conversation",
  ROUTE_TO_HUMAN: "Route to Human",
  ADD_CONVERSATION_TAG: "Add Tag to Chat",
  REMOVE_CONVERSATION_TAG: "Remove Tag from Chat",
  SEND_MESSAGE: "Send a Message",
  ACTIVATE_CHAT_BOT: "Activate Chat Bot",
  ACTIVATE_SLA: "Activate SLA",
  DEACTIVATE_SLA: "Deactivate SLA",
  EMAIL_TRANSCRIPT: "Email Transcript",
  ACTIVATE_AUTO_FOLLOWUP_FLAGGING: "Activate Auto Flag for Followups",
  // Contact
  ADD_CONTACT_TAG: "Add Tag to Contact",
  REMOVE_CONTACT_TAG: "Remove Tag from Contact",
  ADD_MARKETING_LIST: "Add to Marketing List",
  UNSUBSCRIBE_MARKETING_LIST: "Unsubscribe from Marketing List",
  REMOVE_MARKETING_LIST: "Remove From Marketing List",
  SUBSCRIBE_MAILCHIMP: "Subscribe to Mailchimp List",
  // Shopify
  ...ShopifyActionLabels,
};

export const ActionDescriptions: Record<iActionTypes, string> = {
  // Message
  EXTRACT_CONTACT_DATA:
    "Scan incoming message and automatically extract emails and mobile numbers and save it in the CRM",
  // Conversation
  END_CONVERSATION: "Close the current conversation",
  ROUTE_TO_HUMAN: "Route the chat to your team to handle",
  ADD_CONVERSATION_TAG: "Tag the current conversation",
  REMOVE_CONVERSATION_TAG: "Remove a tag from the current conversation",
  SEND_MESSAGE: "Send a new message in the current conversation",
  ACTIVATE_CHAT_BOT:
    "Instantly activate a chatbot for the ongoing conversation, deactivating any existing bots",
  ACTIVATE_SLA: "Activate SLA on current conversation",
  DEACTIVATE_SLA: "Deactivate active SLA on current conversation",
  EMAIL_TRANSCRIPT:
    "Email the transcript to your emails or your customer email",
  ACTIVATE_AUTO_FOLLOWUP_FLAGGING:
    "Automatically flag conversations that requires followups",
  // Contact
  ADD_CONTACT_TAG: "Tag the current contact record",
  REMOVE_CONTACT_TAG: "Remove a tag from the current contact record",
  ADD_MARKETING_LIST: "Add the current contact to selected marketing list",
  UNSUBSCRIBE_MARKETING_LIST:
    "Unsubscribe the current contact to selected marketing list",
  REMOVE_MARKETING_LIST:
    "Remove the current contact to selected marketing list",
  SUBSCRIBE_MAILCHIMP: "Add the current contact to your Mailchimp list",
  // Shopify
  ...ShopifyActionDescriptions,
};

export const ActionIcons: Record<iActionTypes, JSX.Element> = {
  // Message
  EXTRACT_CONTACT_DATA: <MDIIcon icon={mdiCommentSearchOutline} />,
  // Conversation
  END_CONVERSATION: <i className="ri-check-line"></i>,
  ROUTE_TO_HUMAN: <i className="ri-route-line"></i>,
  ADD_CONVERSATION_TAG: <i className="ri-price-tag-3-line"></i>,
  REMOVE_CONVERSATION_TAG: <i className="ri-price-tag-3-line"></i>,
  SEND_MESSAGE: <i className="ri-send-plane-2-line"></i>,
  ACTIVATE_CHAT_BOT: <i className="ri-robot-line"></i>,
  ACTIVATE_SLA: <i className="ri-timer-line"></i>,
  DEACTIVATE_SLA: <i className="ri-timer-line"></i>,
  EMAIL_TRANSCRIPT: <i className="ri-mail-send-line"></i>,
  ACTIVATE_AUTO_FOLLOWUP_FLAGGING: <i className="ri-flag-line"></i>,
  // Contact
  ADD_CONTACT_TAG: <i className="ri-price-tag-3-line"></i>,
  REMOVE_CONTACT_TAG: <i className="ri-price-tag-3-line"></i>,
  ADD_MARKETING_LIST: <MDIIcon icon={mdiBullhornOutline} />,
  UNSUBSCRIBE_MARKETING_LIST: <i className="ri-close-line"></i>,
  REMOVE_MARKETING_LIST: <i className="ri-delete-bin-line"></i>,
  SUBSCRIBE_MAILCHIMP: (
    <img
      src="/assets/integrations/mailchimp.png"
      style={{ width: 20 }}
      alt=""
      className=""
    />
  ),
  // Shopify
  SHOPIFY_LAST_ORDER_CANCEL: <i className="ri-store-2-line"></i>,
  SHOPIFY_ORDER_CANCEL: <i className="ri-store-2-line"></i>,
  SHOPIFY_LAST_ORDER_DUPLICATE: <i className="ri-store-2-line"></i>,
  SHOPIFY_LAST_ORDER_EDIT_SHIPPING_ADDRESS: <i className="ri-store-2-line"></i>,
  SHOPIFY_LAST_ORDER_REFUND_SHIPPING_COST: <i className="ri-store-2-line"></i>,
  SHOPIFY_LAST_ORDER_REFUND: <i className="ri-store-2-line"></i>,
  SHOPIFY_LAST_ORDER_PARTIAL_REFUND: <i className="ri-store-2-line"></i>,
  SHOPIFY_LAST_ORDER_EDIT_NOTE: <i className="ri-store-2-line"></i>,
};

export const AllActionsByGroup = [
  {
    label: "Message Action",
    actions: ["EXTRACT_CONTACT_DATA"],
  },
  {
    label: "Conversation Actions",
    actions: [
      "END_CONVERSATION",
      "ROUTE_TO_HUMAN",
      "ADD_CONVERSATION_TAG",
      "REMOVE_CONVERSATION_TAG",
      "SEND_MESSAGE",
      "ACTIVATE_CHAT_BOT",
      "ACTIVATE_SLA",
      "DEACTIVATE_SLA",
      "EMAIL_TRANSCRIPT",
      "ACTIVATE_AUTO_FOLLOWUP_FLAGGING",
    ],
  },
  {
    label: "Contact Actions",
    actions: [
      "ADD_CONTACT_TAG",
      "REMOVE_CONTACT_TAG",
      "ADD_MARKETING_LIST",
      // "REMOVE_MARKETING_LIST",
      "UNSUBSCRIBE_MARKETING_LIST",
      "SUBSCRIBE_MAILCHIMP",
    ],
  },
  {
    label: "Shopify Actions",
    actions: Object.keys(ShopifyActionLabels).filter(
      (e) =>
        ![
          "SHOPIFY_LAST_ORDER_REFUND_SHIPPING_COST",
          "SHOPIFY_LAST_ORDER_PARTIAL_REFUND",
          "SHOPIFY_LAST_ORDER_EDIT_SHIPPING_ADDRESS",
          "SHOPIFY_LAST_ORDER_DUPLICATE",
        ].includes(e),
    ),
  },
];

export const ActionConfigLabels: Record<iActionTypes, string> = {
  // Message
  EXTRACT_CONTACT_DATA: "",
  // Conversation
  END_CONVERSATION: "",
  ROUTE_TO_HUMAN: "Routing Configuration",
  ADD_CONVERSATION_TAG: "Tag Configuration",
  REMOVE_CONVERSATION_TAG: "Tag Configuration",
  SEND_MESSAGE: "Message",
  ACTIVATE_CHAT_BOT: "Activate Chat Bot",
  ACTIVATE_SLA: "Activate SLA Template",
  DEACTIVATE_SLA: "Deactivate Current SLA",
  EMAIL_TRANSCRIPT: "",
  ACTIVATE_AUTO_FOLLOWUP_FLAGGING: "",
  // Contact
  ADD_CONTACT_TAG: "Tag Configuration",
  REMOVE_CONTACT_TAG: "Tag Configuration",
  ADD_MARKETING_LIST: "Tag Configuration",
  UNSUBSCRIBE_MARKETING_LIST: "List Configuration",
  REMOVE_MARKETING_LIST: "List Configuration",
  SUBSCRIBE_MAILCHIMP: "List Configuration",
  // Shopify
  ...ShopifyActionLabels,
};
