import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iMagicAssistant } from "./magic-assistants-model";

export class MagicAssistantServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.magicAssistants.configure(config);
  }

  magicAssistants = Object.assign(
    new EntityServices<iMagicAssistant>(SDKConfig, "magic-assistants", {
      onCreate: () => {
        UserTracker.track("MAGIC_ASSISTANT - Create", {});
      },
      onDelete: () => {
        UserTracker.track("MAGIC_ASSISTANT - Delete", {});
      },
    }),
    {},
  );
}
