import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { mdiNavigationVariant, mdiSchool } from "@mdi/js";
import { Button, Divider } from "antd";
import { openHelpDeskModal } from "components/common/help-center/embeddable-components/openHelpDeskModal";
import HelpCenter from "components/common/help-center/help-center.modal-registry";
import { MDIIcon } from "components/common/mdi-icon";
import { CCPopoverV2 } from "components/common/popover/popover-v2-portal";
import FeatureRequests from "components/feature-requests/feature-requests.modal-registry";
import TestimonialCollection from "components/modules/testimonial-collection-modal/testimonial-collection.modal-registry";
import { useMemo, useRef } from "react";
import { useStore } from "react-redux";
import { GlobalEventEmitter } from "utils/global-event-emitter";
import {
  SideBarSubMenuIconItem,
  SideBarSubMenuItem,
} from "./side-bar-sub-menu-item";

const TourMenu = [
  {
    label: "Navigation",
    icon: <MDIIcon icon={mdiNavigationVariant} className="mr-2" />,
    onClick: () => {
      GlobalEventEmitter.emit("OPEN_TOUR", "NAVIGATION");
    },
  },
  {
    label: "Inbox",
    icon: <i className="ri-inbox-line"></i>,
    onClick: () => {
      GlobalEventEmitter.emit("OPEN_TOUR", "INBOX");
    },
  },
];
const GuideMenu = [
  {
    label: "Introduction to Unibox",
    icon: <i className="ri-inbox-line"></i>,
    onClick: (triggerTempModal) => {
      // https://cc.clickconnector.io/knowledge-base?activate-help-center=YES&help-center-article=fc89e0-42a1e&help-center-collection=fc89e0-abf1a&searchTerm=
      openHelpDeskModal({
        articleId: "fc89e0-42a1e",
        collectionId: "fc89e0-abf1a",
      });
      triggerTempModal(HelpCenter, {});
    },
  },
  {
    label: "Sales Manager's Guide",
    icon: <i className="ri-user-star-line"></i>,
    onClick: (triggerTempModal) => {
      /// https://cc.clickconnector.io/knowledge-base?activate-help-center=YES&help-center-article=fc89e0-b443b&help-center-collection=fc89e0-abf1a&searchTerm=
      openHelpDeskModal({
        articleId: "fc89e0-b443b",
        collectionId: "fc89e0-abf1a",
      });
      triggerTempModal(HelpCenter, {});
    },
  },
  {
    label: "Marketing Managers's Guide",
    icon: <i className="ri-user-star-line"></i>,
    onClick: (triggerTempModal) => {
      // https://cc.clickconnector.io/knowledge-base?activate-help-center=YES&help-center-article=fc89e0-4ca0c&help-center-collection=fc89e0-abf1a&searchTerm=
      openHelpDeskModal({
        articleId: "fc89e0-4ca0c",
        collectionId: "fc89e0-abf1a",
      });
      triggerTempModal(HelpCenter, {});
    },
  },
  {
    label: "Account Administrator's Guide",
    icon: <i className="ri-user-settings-line"></i>,
    onClick: (triggerTempModal) => {
      // https://cc.clickconnector.io/knowledge-base?activate-help-center=YES&help-center-article=fc89e0-f4f37&help-center-collection=fc89e0-abf1a&searchTerm=
      openHelpDeskModal({
        articleId: "fc89e0-f4f37",
        collectionId: "fc89e0-abf1a",
      });
      triggerTempModal(HelpCenter, {});
    },
  },
  {
    label: "Quick Demo on Reports",
    icon: <i className="ri-folder-chart-line"></i>,
    onClick: (triggerTempModal) => {
      // https://cc.clickconnector.io/knowledge-base?activate-help-center=YES&help-center-article=fc89e0-7aee8&help-center-collection=fc89e0-abf1a&searchTerm=
      openHelpDeskModal({
        articleId: "fc89e0-7aee8",
        collectionId: "fc89e0-abf1a",
      });
      triggerTempModal(HelpCenter, {});
    },
  },
];

export const HelpCenterPopover = () => {
  const store = useStore();

  const { triggerTempModal, changePanelState } = useModalPanels();

  const popoverRef = useRef(null as any);

  const ProductMenu = useMemo(() => {
    return [
      {
        icon: <i className="ri-service-line"></i>,
        label: "Send us a Testimonial",
        onClick: () => {
          changePanelState(TestimonialCollection, true, {});
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-lightbulb-line"></i>,
        label: "Feature Requests",
        onClick: () => {
          changePanelState(FeatureRequests, true, {
            initialTab: "FEATURE_REQUESTS" as const,
          });
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-bug-line"></i>,
        label: "Report a Bug",
        onClick: () => {
          changePanelState(FeatureRequests, true, {
            initialTab: "BUG_BOARD" as const,
          });
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-guide-line"></i>,
        label: "Road map",
        onClick: () => {
          changePanelState(FeatureRequests, true, {
            initialTab: "ROAD_MAP" as const,
          });
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-file-paper-2-line"></i>,
        label: "Release Notes",
        onClick: () => {
          changePanelState(FeatureRequests, true, {
            initialTab: "RELEASE_NOTES" as const,
          });
          popoverRef.current.closePopover();
        },
      },
    ];
  }, [changePanelState]);

  return (
    <CCPopoverV2
      // openState={true}
      ref={popoverRef}
      className="p-4"
      content={
        <div className="animate-slide-up" style={{ width: "20rem" }}>
          <div className="font-bold">Tours</div>
          {TourMenu.map((item) => (
            <SideBarSubMenuItem key={item.label} {...item} />
          ))}
          <div className="font-bold">Videos</div>
          {GuideMenu.map((item) => (
            <SideBarSubMenuItem
              key={item.label}
              {...item}
              onClick={() => item.onClick(triggerTempModal)}
            />
          ))}
          <Divider className="my-0" />
          <div className="font-bold">
            <SideBarSubMenuItem
              label="Open help center"
              icon={<i className="ri-question-line"></i>}
              onClick={function () {
                triggerTempModal(HelpCenter, {});
                // triggerTempModal(ChangeLogs, {});
              }}
            />
          </div>
          <Divider style={{ margin: 0 }} />

          <div className="flex flex-row flex-wrap gap-4 items-center justify-center">
            {ProductMenu.map((item) => (
              <SideBarSubMenuIconItem key={item.label} {...item} />
            ))}
          </div>
        </div>
      }
      popoverId="help-center-popover"
    >
      <Button
        type="text"
        className="h-10"
        icon={<MDIIcon icon={mdiSchool} />}
        block
      ></Button>
    </CCPopoverV2>
  );
};
