import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iTourStep } from "@sdk/screen-tours/screen-tours-model";
import React from "react";

const TourStepEditorModal = React.lazy(
  () => import("./tour-step-editor.modal"),
);

const TourStepEditor: iModalProps<
  typeof TourStepEditorModal,
  {
    step: iTourStep;
    onChange: (step: iTourStep) => any;
  }
> = {
  name: "tour-step-editor",
  component: GenerateModalWithSuspense(TourStepEditorModal),
};

export default TourStepEditor;
