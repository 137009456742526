import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const FeatureRequestsModal = React.lazy(
  () => import("./feature-requests.modal"),
);

const FeatureRequests: iModalProps<
  typeof FeatureRequestsModal,
  {
    initialTab: "FEATURE_REQUESTS" | "ROAD_MAP" | "RELEASE_NOTES" | "BUG_BOARD";
  }
> = {
  name: "feature-requests",
  component: GenerateModalWithSuspense(FeatureRequestsModal),
};

export default FeatureRequests;
