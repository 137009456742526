import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ReportABugModal = React.lazy(() => import("./report-bug-modal.modal"));

const ReportBug: iModalProps<
  typeof ReportABugModal,
  {
    onAdded?: () => any;
  }
> = {
  name: "report-bug",
  component: GenerateModalWithSuspense(ReportABugModal),
};

export default ReportBug;
