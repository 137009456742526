import { Button, Tooltip } from "antd";

import { useSelector, useStore } from "react-redux";

import { useKBar, useRegisterActions } from "@libs/kbar";
import classNames from "classnames";
import { KeyboardShortcut } from "components/common/keyboard-shortcut/keyboard-shortcut";
import {
  CCPopoverV2,
  PopoverGroup,
} from "components/common/popover/popover-v2-portal";
import { push } from "connected-react-router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";

import {
  useCCWidgetEvents,
  WindowEvent,
} from "@libs/cc-widget/use-cc-widget-events";
import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { useChangeLogs } from "components/common/change-logs/use-change-logs";
import HelpCenter from "components/common/help-center/help-center.modal-registry";

import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { LoadAllGroups } from "store/modules/groups/groups.helpers";
import { selectAllGroupsQuery } from "store/modules/groups/groups.selectors";
import { loadAllMagicAssistants } from "store/modules/magic-assistants/magic-assistants.helpers";
import { selectAllMagicAssistants } from "store/modules/magic-assistants/magic-assistants.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { justWait } from "utils/just-wait";
import { HelpCenterPopover } from "./components/help-center-popover";
import { Logo } from "./components/logo";
import { MentionsAndTasksPopover } from "./components/mentiones-tasks-popover";
import { NotificationsMenu } from "./components/notifications-menu";
import { PhoneButton } from "./components/phone-button";
import { ProfileMenu } from "./components/profile-menu";
import { SettingsPopover } from "./components/settings-popover";
import { SideBarSubMenuItem } from "./components/side-bar-sub-menu-item";
import { useMainNavigation } from "./helpers/use-main-navigation";
import "./main-navigation-bar-3.style.scss";

const PathHistory: {
  [rootPath: string]: {
    path: string;
    search: string;
  };
} = {};

export const MainNavigationBar3 = () => {
  const store = useStore();
  const navigationItems = useMainNavigation();

  const location = useLocation();

  const {
    query: { toggle, setCurrentRootAction },
  } = useKBar();

  useEffect(() => {
    const firstPath = location.pathname.split("/")[1];
    PathHistory[firstPath] = {
      path: location.pathname,
      search: location.search,
    };
  }, [location]);

  const [itemsToFit, setItemsToFit] = useState(0);

  const menuContainerRef = useRef(null as any);
  const topSectionRef = useRef(null as any);
  const bottomSectionRef = useRef(null as any);

  useEffect(() => {
    const handleResize = () => {
      const sidebarHeight = menuContainerRef.current?.clientHeight || 0;
      const topSectionHeight = topSectionRef.current?.clientHeight || 0;
      const bottomSectionHeight = bottomSectionRef.current?.clientHeight || 0;
      const heightForNavigation =
        sidebarHeight - (topSectionHeight + bottomSectionHeight + 100);

      setItemsToFit(heightForNavigation / 40);
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { visibleNavigationItems, hiddenNavigationItems } = useMemo(() => {
    return {
      visibleNavigationItems: navigationItems.slice(0, itemsToFit),
      hiddenNavigationItems: navigationItems.slice(itemsToFit),
    };
  }, [navigationItems, itemsToFit]);

  const [activeMenuItemTopOffset, setActiveMenuItemTopOffset] = useState(0);
  const [activeMenuItemHeight, setActiveMenuItemHeight] = useState(0);
  const activeMenuItem = location.pathname.split("/")[1];
  const setTabPosition = useCallback(() => {
    const activeMenuItem = document.getElementsByClassName(
      `active-menu-item`,
    )[0] as any;
    setActiveMenuItemTopOffset(activeMenuItem?.offsetTop ?? -200);
    setActiveMenuItemHeight(activeMenuItem?.offsetHeight ?? 0);
  }, []);

  useEffect(() => {
    setTabPosition();
  }, [activeMenuItem, setTabPosition]);

  useEffect(() => {
    setTabPosition();
    setTimeout(() => {
      setTabPosition();
    }, 200);
  }, [setTabPosition, visibleNavigationItems]);

  useEffect(() => {
    if (!menuContainerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setTabPosition();
    });
    resizeObserver.observe(menuContainerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [setTabPosition]);

  const currentUser = useSelector(selectCurrentUser);
  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(
      currentUser?.permissions || [],
    );
    return currentUserRole;
  }, [currentUser?.permissions]);

  const { triggerTempModal } = useModalPanels();

  const kbBindings = useMemo(() => {
    // const mainNavigation = navigation.map((item) => ({
    //   id: item.label,
    //   name: `Navigate to ${item.label}`,
    //   icon: <i className="ri-arrow-right-line"></i>,
    //   section: "Navigation",
    //   shortcut: item.keyboardShortcut ? [item.keyboardShortcut] : undefined,
    //   keywords: `Navigate to ${item.label}`,
    //   perform: () => store.dispatch(push(item.path)),
    // }));
    const shortcuts = [
      {
        id: "Help Center",
        name: `Open Help Center`,
        icon: <i className="ri-question-line"></i>,
        shortcut: ["?"],
        keywords: `Open Help Center`,
        perform: () => triggerTempModal(HelpCenter, {}),
      },
    ];
    return [...shortcuts];
  }, [triggerTempModal]);

  useRegisterActions(kbBindings, [kbBindings]);

  const user = useSelector(selectCurrentUser);
  const currentOrganization = useSelector(selectOrganization);

  const { state: connections, retry: reload } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections,
  );

  // Just Load All
  const {
    state: { list: userGroups },
  } = useQueryWithStore(selectAllGroupsQuery, LoadAllGroups);

  const { state: magicAssistants } = useQueryWithStore(
    selectAllMagicAssistants,
    loadAllMagicAssistants,
  );

  // Support KeyStrokes inside iframe
  useEffect(() => {
    // https://www.basedash.com/blog/how-to-simulate-a-keypress-in-javascript
    const CC_EVENT_METHOD = (window as any).addEventListener
      ? "addEventListener"
      : "attachEvent";
    const CC_EVENT_LISTENER = window[CC_EVENT_METHOD as any] as any;
    const CC_EVENT_TYPE =
      CC_EVENT_METHOD === "attachEvent" ? "onmessage" : "message";

    const listener = function (event: WindowEvent) {
      if (event.data === "load_cmd_k") {
        // console.log("load cmd k");
        toggle();
        // const enterEvent = new KeyboardEvent("keypress", {
        //   key: "k",
        //   metaKey: true,
        // });
        // window.dispatchEvent(enterEvent);
      } else if (event.data === "load_cmd_p") {
        // console.log("load cmd p");
        toggle();
        setCurrentRootAction("VIEW_ACTIONS");
        // const enterEvent = new KeyboardEvent("keypress", {
        //   key: "p",
        //   metaKey: true,
        // });
        // window.dispatchEvent(enterEvent);
      } else if (event.data === "load_n_key") {
        // console.log("load cmd p");
        toggle();
        setCurrentRootAction("Create New");
        // const enterEvent = new KeyboardEvent("keypress", {
        //   key: "p",
        //   metaKey: true,
        // });
        // window.dispatchEvent(enterEvent);
      }
    };

    CC_EVENT_LISTENER(CC_EVENT_TYPE, listener, false);

    return () => {
      const CC_EVENT_METHOD = (window as any).addEventListener
        ? "removeEventListener"
        : "detachEvent";
      const CC_EVENT_REMOVER = window[CC_EVENT_METHOD as any] as any;
      CC_EVENT_REMOVER(CC_EVENT_TYPE, listener, false);
    };
  }, [setCurrentRootAction, toggle]);

  useEffect(() => {
    const runAsync = async () => {
      if (
        !(user && (window as any).ccWidget && (window as any).ccWidget.identify)
      ) {
        await justWait(1000);
      }
      if (
        user &&
        (window as any).ccWidget &&
        (window as any).ccWidget.identify
      ) {
        (window as any).ccWidget.identify({
          id: user.id,
          firstName: user.data.firstName,
          lastName: user.data.lastName,
          primaryEmail: user.credentials.email,
          company: {
            id: currentOrganization?.id,
            name: currentOrganization?.data.name,
          },
        });
      }
    };
    runAsync();
  }, [currentOrganization?.data.name, currentOrganization?.id, user, user?.id]);

  const onWidgetLoaded = useCallback(() => {
    const user = selectCurrentUser(store.getState());
    const currentOrganization = selectOrganization(store.getState());
    if (
      user &&
      (window as any).ccWidget &&
      (window as any).ccWidget.identify &&
      currentOrganization
    ) {
      (window as any).ccWidget.identify({
        id: user.id,
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        primaryEmail: user.credentials.email,
        company: {
          id: currentOrganization?.id,
          name: currentOrganization?.data.name,
        },
      });
    }
  }, [store]);

  useCCWidgetEvents({ onInit: onWidgetLoaded });

  useChangeLogs();

  const { changePanelStateWithName } = useModalPanels();

  return (
    <div
      className="h-full flex items-stretch relative main-navigation-bar z-10 mode_transition"
      data-click-context="Side Nav Bar"
      ref={menuContainerRef}
    >
      <div
        style={{ width: "3.5rem" }}
        className="h-full flex flex-col justify-between items-center rounded-lg rounded-l-none"
      >
        <div className=" top-0 z-10" ref={topSectionRef}>
          <Logo />
          <div className="flex flex-col justify-center items-center">
            <Tooltip
              title={
                <div>
                  Search
                  <KeyboardShortcut
                    inDarkBg
                    label={"$mod+k"}
                    disableToolTip={true}
                  />
                </div>
              }
              arrow={false}
              placement="right"
            >
              <Button
                type="text"
                className="h-12 rounded-none"
                block
                icon={<i className="ri-search-line text-xl"></i>}
                onClick={() => {
                  toggle();
                }}
              ></Button>
            </Tooltip>
          </div>
          <div className="w-full">
            <Tooltip
              title={
                <div>
                  Create Record
                  <KeyboardShortcut
                    inDarkBg
                    label={"n"}
                    disableToolTip={true}
                  />
                </div>
              }
              arrow={false}
              placement="right"
            >
              <Button
                type="text"
                className="h-12 rounded-none"
                icon={<i className="ri-add-line text-2xl"></i>}
                block
                onClick={() => {
                  toggle();
                  setCurrentRootAction("Create New");
                }}
              ></Button>
            </Tooltip>
          </div>
        </div>
        <div className="w-full">
          <span
            className="absolute flex transition-all duration-300 flex-col active-menu-item-highlight mode_transition"
            style={{
              top: activeMenuItemTopOffset,
              width: "100%",
              // zIndex: -10,
              height: activeMenuItemHeight,
            }}
          >
            <span className="w-full h-full active-menu-item-highlight-inner mode_transition" />
          </span>
          {visibleNavigationItems.map((item) => (
            <div
              key={item.path}
              className="flex flex-col justify-center items-center w-full"
              id={`MAIN_MENU_${item.path}`}
            >
              <Tooltip title={item.label} placement="right" arrow={false}>
                <Button
                  type={
                    // `/${location.pathname.split("/")[1]}` === item.path
                    //   ? "primary"
                    "text"
                  }
                  className={classNames(
                    "h-12 rounded-none text-2xl mode_transition",
                    {
                      "active-menu-item hover:bg-transparent":
                        `/${location.pathname.split("/")[1]}` === item.path,
                    },
                  )}
                  icon={
                    <>
                      {item.icon}
                      {item.extraLabel}
                    </>
                  }
                  block
                  style={{ height: "3rem" }}
                  onClick={() => {
                    const rootPath = String(item.path);
                    const LastPath = PathHistory[rootPath.split("/")[1]];
                    store.dispatch(
                      push(
                        LastPath
                          ? `${LastPath.path}${LastPath.search}`
                          : rootPath,
                      ),
                    );
                  }}
                ></Button>
              </Tooltip>
            </div>
          ))}
          {hiddenNavigationItems.length > 0 && (
            <CCPopoverV2
              content={
                <div className="animate-slide-up" style={{ width: "16rem" }}>
                  {hiddenNavigationItems.map((item) => (
                    <SideBarSubMenuItem
                      key={item.label}
                      icon={item.icon}
                      label={item.label}
                      onClick={() => {
                        const rootPath = String(item.path);
                        const LastPath = PathHistory[rootPath.split("/")[1]];
                        store.dispatch(
                          push(
                            LastPath
                              ? `${LastPath.path}${LastPath.search}`
                              : rootPath,
                          ),
                        );
                      }}
                    />
                  ))}
                </div>
              }
              popoverId="mentions-popover"
            >
              <Button
                type={"text"}
                className={classNames(
                  "h-12 rounded-none text-2xl mode_transition",
                  {
                    "active-menu-item hover:bg-transparent":
                      hiddenNavigationItems
                        .map((item) => item.path)
                        .includes(`/${location.pathname.split("/")[1]}`),
                  },
                )}
                icon={<i className="ri-more-line"></i>}
                block
                style={{ height: "3rem" }}
              ></Button>
            </CCPopoverV2>
          )}
        </div>
        <div className="w-full  bottom-0" ref={bottomSectionRef}>
          <PopoverGroup>
            <MentionsAndTasksPopover />

            <div className="w-full">
              <NotificationsMenu mobileMode={false} />
            </div>
            <HelpCenterPopover />
            {/* {userRole === "Owner" && (
              <Button
                type="text"
                className={classNames("h-10", {
                  "active-menu-item hover:bg-white dark:hover:bg-transparent":
                    `/${location.pathname.split("/")[1]}` === "/configurations",
                })}
                icon={<i className="ri-settings-2-line text-xl"></i>}
                block
                onClick={() => {
                  changePanelStateWithName("configurations", true, {});
                }}
                id={`MAIN_MENU_/configurations`}
              ></Button>
            )} */}
            {userRole === "Owner" && <SettingsPopover />}

            <PhoneButton mobileMode={false} />

            <div className="w-full">
              <ProfileMenu />
            </div>
          </PopoverGroup>
        </div>
      </div>
    </div>
  );
};
