import { Button, message } from "antd";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectSubscriptionDetails } from "store/modules/workspace/workspace.selectors";
import { ActivateSubscriptionModal } from "./activate-subscription";
import { AddOnPlans } from "./addon-plans";
import { AppSumoTiers } from "./appsumo-tiers";
import { BillingAddons } from "./billing-addons";
import { ManageSubAccounts } from "./manage-sub-accounts";
import { NextPaymentSummary } from "./next-payment-summary";
import { PaymentMethodSummaryCard } from "./payment-method-summary-card";
import { APPSUMO_PLANS } from "./pricing-plans-data";
import { RecentInvoices } from "./recent-invoices";
import { useSubscriptionDetails } from "./use-subscription-details";

export const AppSumoSubscriptionCard = () => {
  const subscriptionDetails = useSelector(selectSubscriptionDetails);
  const appSumoSubscription = subscriptionDetails?.appSumoBilling;

  const activatedPlan = subscriptionDetails?.appSumoBilling?.plan_id;

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4 flex flex-col justify-center items-center">
        <img
          src="/assets/app-sumo/app-sumo.svg"
          style={{ width: 300 }}
          alt="Custom Billing"
        />
        <div className="font-bold text-2xl my-8">
          You are using a license purchased from AppSumo.
        </div>
        <div className="text-gray-600">
          <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                {AppSumoTiers.map((item) => (
                  <div
                    className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
                    key={item.planId}
                  >
                    <h3 className="mb-4 text-2xl font-semibold">
                      {item.title}
                    </h3>
                    <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                      {item.description}
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">
                        {" "}
                        {item.price}
                      </span>
                      <span className="text-gray-500 dark:text-gray-400">
                        {/*  */}
                      </span>
                    </div>

                    <ul role="list" className="mb-8 space-y-4 text-left">
                      {item.included.map((lineItem) => (
                        <li
                          className="flex items-center space-x-3"
                          key={lineItem}
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span>{lineItem}</span>
                        </li>
                      ))}
                    </ul>
                    {item.planId === activatedPlan && (
                      <div>
                        <div className="font-bold ">
                          <i className="ri-check-line"></i> Activated
                        </div>
                      </div>
                    )}
                    {/* {item.planId !== activatedPlan && (
                      <div>
                        <Button
                          className="font-bold"
                          href={`https://appsumo.com/account/redemption/${appSumoSubscription?.invoice_item_uuid}#change-plan`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Activate
                        </Button>
                      </div>
                    )} */}
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
        <AppSumoBillingPortal />
        {activatedPlan === "clickconnector_tier3" && <ManageSubAccounts />}
      </SimpleCardSection>
    </StyledScrollArea>
  );
};

export const AppSumoBillingPortal = () => {
  const recentInvoicesRef = useRef<any>();
  const nextPaymentSummaryRef = useRef<any>();

  const { isSubscribed, subscriptionDetails, isYearly, plan } =
    useSubscriptionDetails();

  const reloadStripeData = () => {
    recentInvoicesRef?.current?.reloadInvoices?.();
    nextPaymentSummaryRef?.current?.reloadSubscriptions?.();
  };

  const [activatePaymentModalVisibility, setActivatePaymentModalVisibility] =
    useState(false);

  const selectedPlan = useMemo(() => {
    return { ...APPSUMO_PLANS[0], planId: `${APPSUMO_PLANS[0].id}_MONTHLY` };
  }, []);

  return (
    <div className="mt-8 w-full">
      {isSubscribed && (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="plan-card flex flex-col rounded-lg border border-gray-200 p-4">
              <div className="font-bold">AppSumo License Activated</div>
              <div className="text-gray-600  text-xs">
                No additional steps required
              </div>
            </div>

            <PaymentMethodSummaryCard reloadStripeData={reloadStripeData} />
          </div>

          <NextPaymentSummary ref={nextPaymentSummaryRef} />

          <BillingAddons reloadStripeData={reloadStripeData} />
        </>
      )}

      {!isSubscribed && (
        <>
          <div className="font-bold text-xl">Add-ons</div>
          <AddOnPlans />
          <div className="rounded-md items-center justify-center flex py-6 flex-col border dark:border-gray-700 dark:bg-gray-800 m-4 hover:shadow-md mode_transition font-bold">
            Add Payment Method to Activate Billing and Add-ons
            <Button
              icon={<i className="ri-secure-payment-line"></i>}
              onClick={() => setActivatePaymentModalVisibility(true)}
              type="primary"
              className="mt-2 font-bold"
            >
              Add Payment Method
            </Button>
          </div>
        </>
      )}

      <RecentInvoices ref={recentInvoicesRef} />

      <ActivateSubscriptionModal
        isActivated={isSubscribed}
        visible={activatePaymentModalVisibility}
        onChangeVisibility={setActivatePaymentModalVisibility}
        onActivated={() => {
          setActivatePaymentModalVisibility(false);
          message.success("Subscription has been activated");
          reloadStripeData();
        }}
        plan={selectedPlan}
      />
    </div>
  );
};
