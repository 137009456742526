import { Button, message, Tooltip } from "antd";
import copyToClipboard from "copy-to-clipboard";
import React, { useMemo } from "react";
import { loadAllCCArticleCollections } from "store/modules/cc-article-collection/cc-article-collection.helpers";
import { selectAllCCArticleCollections } from "store/modules/cc-article-collection/cc-article-collection.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useUrlState } from "utils/hooks/use-url-state";

export const HelpCenterBreadCrumbBar = () => {
  const { state: collections, retry: reload } = useQueryWithStore(
    selectAllCCArticleCollections,
    loadAllCCArticleCollections,
  );

  const [selectedArticle, setSelectedArticle] = useUrlState(
    "help-center-article",
  );

  const [selectedCollection, onSelectCollection] = useUrlState(
    "help-center-collection",
  );

  const breadcrumbs = useMemo(() => {
    const items = [
      <Button
        icon={<i className="ri-home-fill"></i>}
        type="text"
        onClick={() => {
          setSelectedArticle("", {
            "help-center-collection": "",
          });
          // onSelectCollection("");
        }}
        key={"help-center-collection"}
      >
        <div className="flex flex-1 w-full font-bold text-xl flex-row items-center">
          <span>Help Center</span>
        </div>
      </Button>,
    ];
    if (selectedCollection) {
      const collection = collections.find(
        (item) => item.id === selectedCollection,
      );
      items.push(
        <Button
          type="text"
          onClick={() => {
            setSelectedArticle("");
          }}
        >
          <div className="flex flex-1 w-full text-xl flex-row items-center">
            <span>{collection?.label}</span>
          </div>
        </Button>,
      );
    }
    return items;
  }, [collections, selectedCollection, setSelectedArticle]);

  return (
    <div className="flex flex-1 w-full flex-row justify-between items-center pr-8">
      <div>
        {breadcrumbs.map((item, index) => (
          <React.Fragment key={index}>
            {item}
            <i className="ri-arrow-right-s-line"></i>
          </React.Fragment>
        ))}
      </div>
      <div className="right-side">
        <Tooltip title="Share Article With URL">
          <Button
            onClick={() => {
              // Todo: It could be the current domain or help desk page domain
              let URL = "https://kb.clickconnector.com/";
              // const url = new URL(baseUrl);
              // url.searchParams.append("activate-help-center", "YES");
              if (selectedArticle) {
                URL += `articles/${selectedArticle}`;
              } else if (selectedCollection) {
                URL += `collections/${selectedCollection}`;
              }
              copyToClipboard(URL);
              message.success("URL has been copied to your clipboard");
            }}
            icon={<i className="ri-share-line"></i>}
            // icon={<i className="ri-clipboard-line"></i>}
            type="text"
            shape="circle"
          ></Button>
        </Tooltip>
        <Button
          type="primary"
          className="font-bold"
          onClick={() => {
            (window as any).ccWidget.setWidgetVisibility(true);
          }}
        >
          Chat with us
        </Button>
      </div>
    </div>
  );
};
