import { iSDK } from "@sdk/sdk";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";
import { RouterPrompt } from "components/common/router-prompt/router-prompt";

import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectChatWidgetById } from "store/modules/chat-widgets/chat-widgets.selectors";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectDynamicFormById } from "store/modules/dynamic-forms/dynamic-forms.selectors";
import { selectMagicAssistantById } from "store/modules/magic-assistants/magic-assistants.selectors";
import { selectSelfServicePortalById } from "store/modules/self-service-portals/self-service-portals.selectors";
import {
  selectCurrentUserId,
  selectUser,
} from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { UserTracker } from "user-tracker";

export const ConfigurationEditorComponent = ({
  children,
  icon,
  title,
  description,
  entityType,
  entityId,
  transformFormValues,
  transformEntityValues,
  onFormValueChange,
  panelRef,
  ignoreNavigationWarning,
  onSaved,
}: {
  icon: string | JSX.Element;
  title: string;
  description: string;
  children;
  entityType:
    | "CONNECTION"
    | "CONTACT"
    | "WORKSPACE"
    | "MY_PROFILE"
    | "USER"
    | "WIDGET"
    | "SELF_SERVICE_PORTAL"
    | "DYNAMIC_FORM"
    | "MAGIC_ASSISTANT";
  entityId?: string;
  transformFormValues?: (formValues: any) => any;
  transformEntityValues?: (entity: any) => any;
  onFormValueChange?: (formValues: any) => any;
  panelRef?: any;
  ignoreNavigationWarning?: boolean;
  onSaved?: () => any;
}) => {
  return (
    <CollapsibleConfigurationSection
      icon={icon}
      title={title}
      description={description}
      ref={panelRef}
    >
      <ConfigurationEditorInner
        entityType={entityType}
        entityId={entityId}
        transformFormValues={transformFormValues}
        transformEntityValues={transformEntityValues}
        onFormValueChange={onFormValueChange}
        panelRef={panelRef}
        ignoreNavigationWarning={ignoreNavigationWarning}
        onSaved={onSaved}
        title={title}
      >
        {children}
      </ConfigurationEditorInner>
    </CollapsibleConfigurationSection>
  );
};

export const ConfigurationEditorInner = ({
  children,
  entityType,
  entityId,
  transformFormValues,
  transformEntityValues,
  onFormValueChange,
  panelRef,
  ignoreNavigationWarning,
  onSaved,
  title,
  formLayout,
}: {
  children;
  entityType:
    | "CONNECTION"
    | "CONTACT"
    | "WORKSPACE"
    | "MY_PROFILE"
    | "USER"
    | "WIDGET"
    | "SELF_SERVICE_PORTAL"
    | "DYNAMIC_FORM"
    | "MAGIC_ASSISTANT";
  entityId?: string;
  transformFormValues?: (formValues: any) => any;
  transformEntityValues?: (entity: any) => any;
  onFormValueChange?: (formValues: any) => any;
  panelRef?: any;
  ignoreNavigationWarning?: boolean;
  onSaved?: () => any;
  title: string;
  formLayout?: "vertical" | "inline" | "horizontal";
}) => {
  const [form] = useForm();
  const [isFormTouched, setFormTouched] = useState(false);

  const selector = useMemo(() => {
    if (entityType === "CONNECTION") {
      return selectConnectionById(entityId!);
    } else if (entityType === "WORKSPACE") {
      return selectOrganization;
    } else if (entityType === "USER") {
      return selectUser(entityId);
    } else if (entityType === "MY_PROFILE") {
      return selectCurrentUserId;
    } else if (entityType === "WIDGET") {
      return selectChatWidgetById(entityId!);
    } else if (entityType === "SELF_SERVICE_PORTAL") {
      return selectSelfServicePortalById(entityId!);
    } else if (entityType === "DYNAMIC_FORM") {
      return selectDynamicFormById(entityId!);
    } else if (entityType === "CONTACT") {
      return selectContactById(entityId!);
    } else if (entityType === "MAGIC_ASSISTANT") {
      return selectMagicAssistantById(entityId!);
    }
    return selectUser(entityId!);
  }, [entityId, entityType]);

  const entity = useSelector(selector as any) as any;

  const initialValues = useMemo(() => {
    if (!entity) {
      return {};
    }
    return {
      ...(transformEntityValues ? transformEntityValues(entity) : entity),
    };
  }, [entity, transformEntityValues]);

  useEffect(() => {
    form.resetFields();
    setFormTouched(false);
  }, [entity, form]);

  const { doAction: editEntity, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: ((SDK: iSDK) => (edits) => {
        UserTracker.track(`${entityType} - Edit - ${title}`, {});
        if (transformFormValues) {
          edits = transformFormValues(edits);
        }
        if (entityType === "WORKSPACE") {
          return SDK.editCurrentOrganization(edits);
        } else if (entityType === "USER") {
          return SDK.editUser(entityId!, edits);
        } else if (entityType === "MY_PROFILE") {
          return SDK.editCurrentUser(edits);
        } else if (entityType === "CONNECTION") {
          return SDK.connections.editById(entityId!, edits);
        } else if (entityType === "WIDGET") {
          return SDK.chatWidgets.editById(entityId!, edits);
        } else if (entityType === "SELF_SERVICE_PORTAL") {
          return SDK.selfServicedPortals.editById(entityId!, edits);
        } else if (entityType === "DYNAMIC_FORM") {
          return SDK.dynamicForms.editById(entityId!, edits);
        } else if (entityType === "CONTACT") {
          return SDK.editContact(entityId!, edits);
        } else if (entityType === "MAGIC_ASSISTANT") {
          return SDK.magicAssistants.editById(entityId!, edits);
        }
        return SDK.editCurrentUser(edits);
      }) as any,
      failureMessage: "Something went wrong",
    }),
    [entityId, entityType, title, transformFormValues],
  );

  return (
    <div>
      {!ignoreNavigationWarning && (
        <RouterPrompt when={form.isFieldsTouched()} />
      )}

      <Form
        layout={formLayout || "vertical"}
        onFinish={() => {
          const formValues = form.getFieldsValue();
          editEntity(formValues).then((d) => {
            message.success("Saved");
            onSaved && onSaved();
          });
        }}
        form={form}
        initialValues={initialValues}
        onValuesChange={(d) => {
          if (form.isFieldsTouched() && !isFormTouched) {
            setFormTouched(true);
          }
          onFormValueChange && onFormValueChange(form.getFieldsValue());
        }}
      >
        {children}
      </Form>

      <div
        className="flex flex-row justify-end items-center"
        style={{ height: 30 }}
      >
        {isFormTouched && (
          <Button
            onClick={() => form.submit()}
            className="font-bold animated fadeInRight"
            icon={<i className="ri-save-line"></i>}
            loading={isProcessing}
            type="primary"
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};
