import { axios, AxiosResponse } from "@sdk/axios";
import { DeepPartial } from "redux";
import { UserTracker } from "user-tracker";
import {
  ResReq_BulkCreateUsers,
  UserReq_AcceptInvite,
  UserReq_BulkEditUsers,
  UserRes_AcceptInviteResponse,
  UserRes_InvitationData,
  Validated_AccountCreateRequest,
  Validated_ApiUserCreateRequest,
  Validated_UserCreateRequest,
} from "./user-management.controller-models";
import {
  iAutoKickUsersConfig,
  iConversationTag,
  iOrganization,
  iUser,
  iUserAppPermission,
  iUserGroup,
  iUserInvitation,
  UserGroupType,
} from "./user-management.models";

export class AccountsAndUserManagementServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  /* ---------------------------------- Login / Register --------------------------------- */

  async createAccount(req: Validated_AccountCreateRequest) {
    UserTracker.track("Register", { source: "STANDARD" });
    const results: AxiosResponse<{
      accessToken: string;
      organization: iOrganization;
      user: iUser;
    }> = await axios.post(
      this.config.basePath + "/accounts/register",
      {
        ...req,
        organizationData: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...(req.organizationData || {}),
        },
      },
      {
        headers: {},
      },
    );
    return results.data;
  }

  async checkDomainAvailability(domain: string) {
    const results: AxiosResponse<{
      organization?: iOrganization;
      availability: boolean;
    }> = await axios.get(
      this.config.basePath + "/accounts/check-domain-availability",
      {
        headers: {},
        params: {
          domain,
        },
      },
    );
    return results.data;
  }

  async createEmailVerificationRequest(email: string) {
    const results: AxiosResponse<{
      status?: boolean;
    }> = await axios.post(
      this.config.basePath + "/accounts/create-email-verification-request",
      { email },
      {
        headers: {},
      },
    );
    return results.data;
  }

  async login(domain: string, email: string, password: string) {
    UserTracker.track("AUTH - Login", { domain });
    const results: AxiosResponse<{
      accessToken: string;
      user: iUser;
      access: { valid: boolean; ip?: string; whiteListedAccessIPs?: string[] };
    }> = await axios.post(
      this.config.basePath + "/auth/login",
      {
        email,
        password,
        domain,
      },
      {
        headers: {},
      },
    );
    return results.data;
  }

  async getOtp(email: string) {
    UserTracker.track("AUTH - GetOTP", { email });
    const results: AxiosResponse<{
      success: boolean;
    }> = await axios.post(
      this.config.basePath + "/auth/get-login-otp",
      {
        email,
      },
      {
        headers: {},
      },
    );
    return results.data;
  }

  async getTokenWithOTP(email: string, otp: string) {
    const results: AxiosResponse<{
      userAccessToken: string;
    }> = await axios.post(
      this.config.basePath + "/auth/get-token-with-otp",
      {
        email,
        otp,
      },
      {
        headers: {},
      },
    );
    return results.data;
  }

  async getTokenWithGoogleCode(code: string) {
    const results: AxiosResponse<{
      userAccessToken: string;
      email: string;
    }> = await axios.post(
      this.config.basePath + "/auth/get-token-with-google-code",
      {
        code,
      },
      {
        headers: {},
      },
    );
    return results.data;
  }

  async getAccountsWithUserAccessToken(userAccessToken: string) {
    const results: AxiosResponse<
      {
        id: string;
        workspaceId: string;
        workspaceLogo: string;
        workspaceName: string;
        workspaceDomain: string;
        workspaceActive: string;
        userAvatar: string;
        userEmail: string;
        userName: string;
      }[]
    > = await axios.post(
      this.config.basePath + "/auth/get-accounts",
      {
        userAccessToken,
      },
      {
        headers: {},
      },
    );
    return results.data;
  }

  async loginWithUserToken(userAccessToken: string, organizationId: string) {
    const results: AxiosResponse<{
      accessToken: string;
      user: iUser;
      access: { valid: boolean; ip?: string; whiteListedAccessIPs?: string[] };
    }> = await axios.post(
      this.config.basePath + "/auth/login-with-token",
      {
        userAccessToken,
        organizationId,
      },
      {
        headers: {},
      },
    );
    return results.data;
  }

  async validateAccess() {
    const results: AxiosResponse<{
      valid: boolean;
      ip?: string;
      whiteListedAccessIPs?: string[];
    }> = await axios.post(
      this.config.basePath + "/organizations/me/validate-access",
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async troubleshoot(organizationId: string) {
    UserTracker.track("WORKSPACE - Troubleshoot", { organizationId });
    const results: AxiosResponse<iOrganization> = await axios.post(
      this.config.basePath + `/accounts/troubleshoot/${organizationId}`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  /* --------------------------------- Organization -------------------------------- */
  async getCurrentOrganization() {
    const results: AxiosResponse<iOrganization> = await axios.get(
      this.config.basePath + "/organizations/me",
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async editCurrentOrganization(edits: DeepPartial<iOrganization>) {
    const results: AxiosResponse<iOrganization> = await axios.patch(
      this.config.basePath + "/organizations/me",
      edits,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async deduceLogo() {
    const results: AxiosResponse<{ logo: string }> = await axios.post(
      this.config.basePath + "/organizations/me/deduce-logo",
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async autoSetupAccount() {
    UserTracker.track("WORKSPACE - Auto Setup Account", {});
    const results: AxiosResponse<{ status: boolean }> = await axios.post(
      this.config.basePath + "/organizations/me/auto-setup-account",
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async patchAvailableConversationTags(conversationTags: iConversationTag[]) {
    UserTracker.track("WORKSPACE - Edit Conversation Tags", {});
    const results: AxiosResponse<iOrganization> = await axios.patch(
      this.config.basePath + "/organizations/conversation-tags",
      {
        conversationTags,
      },
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async patchAvailableContactTags(contactTags: iConversationTag[]) {
    UserTracker.track("WORKSPACE - Edit Contact Tags", {});
    const results: AxiosResponse<iOrganization> = await axios.patch(
      this.config.basePath + "/organizations/contact-tags",
      {
        contactTags,
      },
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async addConversationTag(tag: iConversationTag) {
    UserTracker.track("WORKSPACE - Add Conversation Tag", {});
    const results: AxiosResponse<iOrganization> = await axios.post(
      this.config.basePath + "/organizations/conversations/tags",
      tag,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async addContactTag(tag: iConversationTag) {
    UserTracker.track("WORKSPACE - Add Contact Tag", {});
    const results: AxiosResponse<iOrganization> = await axios.post(
      this.config.basePath + "/organizations/contacts/tags",
      tag,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async addPipelineCardTag(tag: iConversationTag) {
    UserTracker.track("WORKSPACE - Add Pipeline Tag", {});
    const results: AxiosResponse<iOrganization> = await axios.post(
      this.config.basePath + "/organizations/pipeline-cards/tags",
      tag,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async deActivateCurrentOrganization() {
    UserTracker.track("WORKSPACE - Deactivate Workspace", {});
    const results: AxiosResponse<iOrganization> = await axios.post(
      this.config.basePath + "/organizations/me/deactivate",
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }
  async patchAutoKickUserConfig(autoKickUsersConfig: iAutoKickUsersConfig) {
    UserTracker.track("WORKSPACE - Edit Auto Kick Users Config", {});
    const results: AxiosResponse<iOrganization> = await axios.patch(
      this.config.basePath + "/organizations/auto-kick-users-config",
      autoKickUsersConfig,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async forceResetSubscription() {
    const results: AxiosResponse<iOrganization> = await axios.post(
      this.config.basePath + "/billing/reset",
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async blockIpAddress(body: { ip: string; status: boolean }) {
    UserTracker.track("WORKSPACE - Block IP Address", {});
    const results: AxiosResponse<iOrganization> = await axios.post(
      this.config.basePath + "/organizations/block-ip",
      body,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async setCustomDomain(domain: string) {
    UserTracker.track("WORKSPACE - Set Custom Domain", {});
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/organizations/pages-custom-domain`,
      { domain },
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }
  async removeCustomDomain() {
    UserTracker.track("WORKSPACE - Remove Custom Domain", {});
    const results: AxiosResponse<any> = await axios.delete(
      this.config.basePath + `/organizations/pages-custom-domain`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }
  async refreshValidityCustomDomain() {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/organizations/pages-custom-domain/verify`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async fetchSubAccounts() {
    const results: AxiosResponse<
      { id: string; domain: string; data: { logo: string; name: string } }[]
    > = await axios.post(
      this.config.basePath + `/organizations/me/fetch-sub-account`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async getOrganizationPublicData(organizationId: string) {
    const results: AxiosResponse<{
      id: string;
      domain: string;
      data: { logo: string; name: string };
    }> = await axios.get(
      this.config.basePath + `/organizations/${organizationId}/public-data`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  /* --------------------------------- User Groups -------------------------------- */

  async getAllGroups() {
    const results: AxiosResponse<iUserGroup[]> = await axios.get(
      this.config.basePath + "/user-groups",
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async getGroupById(id: string) {
    const results: AxiosResponse<iUserGroup> = await axios.get(
      this.config.basePath + `/user-groups/${id}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async editGroupById(id: string, edits: DeepPartial<iUserGroup>) {
    const results: AxiosResponse<iUserGroup> = await axios.patch(
      this.config.basePath + `/user-groups/${id}`,
      edits,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async createGroup(label: string, type: UserGroupType) {
    UserTracker.track("WORKSPACE - Create User Group", {});
    const results: AxiosResponse<iUserGroup> = await axios.post(
      this.config.basePath + "/user-groups/",
      { label, type },
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async updateMembers(id: string, users: string[]) {
    UserTracker.track("WORKSPACE - Update User Group Members", {});
    const results: AxiosResponse<{ status: boolean }> = await axios.post(
      this.config.basePath + `/user-groups/${id}/members`,
      { users },
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async deactivateGroup(id: string) {
    UserTracker.track("WORKSPACE - Deactivate User Group", {});
    const results: AxiosResponse<iUserGroup> = await axios.post(
      this.config.basePath + `/user-groups/${id}/deactivate`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async deleteGroup(id: string) {
    UserTracker.track("WORKSPACE - Delete User Group", {});
    const results: AxiosResponse<iUserGroup> = await axios.delete(
      this.config.basePath + `/user-groups/${id}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  /* --------------------------------- Users -------------------------------- */

  async checkEmailAvailability(email: string, organizationId?: string) {
    const params: any = {
      email,
    };
    if (organizationId) {
      params.organizationId = organizationId;
    }
    const results: AxiosResponse<{
      email: string;
      availability: boolean;
    }> = await axios.get(
      this.config.basePath + "/users/check-email-availability",
      {
        headers: {},
        params,
      },
    );
    return results.data;
  }

  async getCurrentUser() {
    const results: AxiosResponse<iUser> = await axios.get(
      this.config.basePath + "/users/me",
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async editCurrentUser(updatedUserDetails: DeepPartial<iUser>) {
    const results: AxiosResponse<iUser> = await axios.patch(
      this.config.basePath + "/users/me",
      updatedUserDetails,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async editUser(id: string, updatedUserDetails: DeepPartial<iUser>) {
    const results: AxiosResponse<iUser> = await axios.patch(
      this.config.basePath + `/users/${id}`,
      updatedUserDetails,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async testPushNotification() {
    const results: AxiosResponse<iUser> = await axios.post(
      this.config.basePath + "/users/me/test-push-notifications",
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async getAllUsers() {
    const results: AxiosResponse<iUser[]> = await axios.get(
      this.config.basePath + "/users",
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async createUser(userDetails: Validated_UserCreateRequest) {
    UserTracker.track("WORKSPACE - Add a User", {});
    const results: AxiosResponse<iUser> = await axios.post(
      this.config.basePath + "/users/",
      userDetails,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async createAPIUser(apiUserDetails: Validated_ApiUserCreateRequest) {
    UserTracker.track("WORKSPACE - Add API User", {});
    const results: AxiosResponse<iUser> = await axios.post(
      this.config.basePath + "/users/api-user",
      apiUserDetails,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async getAPIUserSecret(id: string) {
    const results: AxiosResponse<{ accessToken: string }> = await axios.get(
      this.config.basePath + `/users/api-user/${id}/secret`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async bulkCreateUser(req: ResReq_BulkCreateUsers) {
    UserTracker.track("WORKSPACE - Bulk Create Users", {});
    const results: AxiosResponse<iUser> = await axios.post(
      this.config.basePath + "/users/bulk-create",
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async deactivateUser(id: string) {
    UserTracker.track("WORKSPACE - Deactivate User", {});
    const results: AxiosResponse<iUser> = await axios.post(
      this.config.basePath + `/users/${id}/deactivate`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async deleteUser(id: string) {
    UserTracker.track("WORKSPACE - Delete User", {});
    const results: AxiosResponse<iUser> = await axios.post(
      this.config.basePath + `/users/${id}/delete-account`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async activateUser(id: string) {
    UserTracker.track("WORKSPACE - Reactivate User", {});
    const results: AxiosResponse<iUser> = await axios.post(
      this.config.basePath + `/users/${id}/reactivate`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async inviteUser(req: { email: string; permissions: iUserAppPermission[] }) {
    UserTracker.track("WORKSPACE - Invite User", {});
    const results: AxiosResponse<iUserInvitation> = await axios.post(
      this.config.basePath + `/users/invite-user`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async deleteInvitation(email: string) {
    UserTracker.track("WORKSPACE - Retract User Invitation", {});
    const results: AxiosResponse<iUserInvitation> = await axios.delete(
      this.config.basePath + `/users/invitations`,
      {
        headers: {
          Authorization: this.config.token,
        },
        params: {
          email,
        },
      },
    );
    return results.data;
  }

  async emailResetPasswordLink(req: { email: string; domain: string }) {
    UserTracker.track("WORKSPACE - Email Reset Password Link", {});
    const results: AxiosResponse<{ status: boolean }> = await axios.post(
      this.config.basePath + `/users/email-reset-password-link`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async resetPasswordWithCode(req: {
    domain: string;
    email: string;
    secret: string;
    newPassword: string;
  }) {
    const results: AxiosResponse<{ status: boolean }> = await axios.post(
      this.config.basePath + `/users/reset-password-with-code`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async createAccountWithInviteCode(req: UserReq_AcceptInvite) {
    UserTracker.track("WORKSPACE - Accept User Invitation", {});
    const results: AxiosResponse<UserRes_AcceptInviteResponse> =
      await axios.post(this.config.basePath + `/users/accept-invite`, req, {
        headers: {
          Authorization: this.config.token,
        },
      });
    return results.data;
  }

  async getAllActiveInvitation() {
    const results: AxiosResponse<iUserInvitation[]> = await axios.get(
      this.config.basePath + `/users/invitations`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async getInvitationData(secret: string) {
    const results: AxiosResponse<UserRes_InvitationData> = await axios.get(
      this.config.basePath + `/users/invitations/${secret}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async bulkActionUsers(req: UserReq_BulkEditUsers) {
    UserTracker.track("WORKSPACE - Bulk User Actions", {});
    const results: AxiosResponse<{ status: boolean }> = await axios.post(
      this.config.basePath + `/users/bulk-action`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }
}
