import { push } from "connected-react-router";
import qs from "query-string";
import { BrowserHistory } from "store/browser-history";
import { getGlobalStore } from "store/get-global-store";

export const openHelpDeskModal = ({
  articleId,
  collectionId,
}: { articleId?: string; collectionId?: string } = {}) => {
  const newPath: any = {
    pathname: BrowserHistory.location.pathname,
    search: qs.stringify({
      ...qs.parse(BrowserHistory.location.search),
      "activate-help-center": "YES",
      "help-center-article": articleId,
      "help-center-collection": collectionId,
    }),
  };
  getGlobalStore().dispatch(push(newPath));
};
