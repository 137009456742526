import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { Button, Divider } from "antd";
import classNames from "classnames";
import { NotificationHelper } from "components/common/notifications/notifications-helper";
import { CCPopoverV2 } from "components/common/popover/popover-v2-portal";
import AddNewConnection from "components/pages/configurations/sections/add-new-connection.modal-registry";
import { push } from "connected-react-router";

import FeatureRequests from "components/feature-requests/feature-requests.modal-registry";
import TestimonialCollection from "components/modules/testimonial-collection-modal/testimonial-collection.modal-registry";

import { mdiCogPlay } from "@mdi/js";
import { MDIIcon } from "components/common/mdi-icon";
import { useMemo, useRef } from "react";
import { useSelector, useStore } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import {
  SideBarSubMenuIconItem,
  SideBarSubMenuItem,
} from "./side-bar-sub-menu-item";

export const SettingsPopover = () => {
  const store = useStore();

  const history = useHistory();
  const location = useLocation();
  const { changePanelState, changePanelStateWithName } = useModalPanels();

  const DevMenu = useMemo(() => {
    return [
      {
        icon: <i className="ri-pulse-fill"></i>,
        label: "Send Test Error",
        onClick: () => {
          popoverRef.current.closePopover();
          throw new Error("Test Error");
        },
      },
      {
        icon: <i className="ri-notification-3-line"></i>,
        label: "Show Notification",
        onClick: () => {
          NotificationHelper.fire.testNotification({
            title: "Hello World",
            body: "Some Body",
          });
          popoverRef.current.closePopover();
        },
      },
    ];
  }, []);

  const ProductMenu = useMemo(() => {
    return [
      {
        icon: <i className="ri-service-line"></i>,
        label: "Send us a Testimonial",
        onClick: () => {
          changePanelState(TestimonialCollection, true, {});
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-lightbulb-line"></i>,
        label: "Feature Requests",
        onClick: () => {
          changePanelState(FeatureRequests, true, {
            initialTab: "FEATURE_REQUESTS" as const,
          });
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-bug-line"></i>,
        label: "Report a Bug",
        onClick: () => {
          changePanelState(FeatureRequests, true, {
            initialTab: "BUG_BOARD" as const,
          });
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-guide-line"></i>,
        label: "Road map",
        onClick: () => {
          changePanelState(FeatureRequests, true, {
            initialTab: "ROAD_MAP" as const,
          });
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-file-paper-2-line"></i>,
        label: "Release Notes",
        onClick: () => {
          changePanelState(FeatureRequests, true, {
            initialTab: "RELEASE_NOTES" as const,
          });
          popoverRef.current.closePopover();
        },
      },
    ];
  }, [changePanelState]);

  const QuickAccessMenu = useMemo(() => {
    return [
      {
        icon: <i className="ri-user-add-line"></i>,
        label: "Invite User",
        onClick: () => {
          changePanelStateWithName(
            "configurations",
            true,
            {},
            {
              configurations: "USER_MANAGEMENT",
              "show-invite": "true",
            },
          );
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-price-tag-3-line"></i>,
        label: "Manage Conversation Tags",
        onClick: () => {
          changePanelStateWithName(
            "configurations",
            true,
            {},
            {
              configurations: "TAGS_ATTRIBUTES/chat-tags",
            },
          );
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-add-line"></i>,
        label: "Add Channel",
        onClick: () => {
          changePanelState(AddNewConnection, true, {});
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <MDIIcon icon={mdiCogPlay} size="1rem" />,
        label: "Workflow Automation",
        onClick: () => {
          changePanelStateWithName(
            "configurations",
            true,
            {},
            {
              configurations: "WORKFLOW_AUTOMATION",
            },
          );
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-plug-line"></i>,
        label: "Apps and Integrations",
        onClick: () => {
          changePanelStateWithName(
            "configurations",
            true,
            {},
            {
              configurations: "APPS_AND_INTEGRATIONS",
            },
          );
          popoverRef.current.closePopover();
        },
      },
      // {
      //   icon: <i className="ri-download-2-line"></i>,
      //   label: "Download App (Beta)",
      //   onClick: () => {
      //     changePanelState(DownloadApp, true, {});
      //     popoverRef.current.closePopover();
      //   },
      // },
    ];
  }, [changePanelState, changePanelStateWithName]);

  const isDevMode = useSelector(selectIsAdvancedMode);

  const popoverRef = useRef(null as any);

  return (
    <CCPopoverV2
      ref={popoverRef}
      // openState={true}
      className="p-4"
      content={
        <div className="animate-slide-up" style={{ width: "20rem" }}>
          <div className="font-semibold mb-4">Quick Settings Access</div>
          {QuickAccessMenu.map((item) => (
            <SideBarSubMenuItem key={item.label} {...item} />
          ))}

          <Divider style={{ margin: 0 }} />

          <div className="font-bold">
            <SideBarSubMenuItem
              icon={<i className="ri-settings-2-line text-xl"></i>}
              label="View All Workspace Settings"
              onClick={() => {
                store.dispatch(push("/configurations/quick-access"));
              }}
            />
          </div>

          <Divider style={{ margin: 0 }} />

          <div className="flex flex-row flex-wrap gap-4 items-center justify-center">
            {ProductMenu.map((item) => (
              <SideBarSubMenuIconItem key={item.label} {...item} />
            ))}
          </div>

          {isDevMode && (
            <>
              {DevMenu.map((item) => (
                <SideBarSubMenuItem key={item.label} {...item} />
              ))}
            </>
          )}
        </div>
      }
      popoverId="settings-popover"
    >
      <Button
        type="text"
        className={classNames("h-10", {
          "active-menu-item hover:bg-white dark:hover:bg-transparent":
            `/${location.pathname.split("/")[1]}` === "/configurations",
        })}
        icon={<i className="ri-settings-2-line text-xl"></i>}
        block
        onClick={() => {
          // store.dispatch(push("/configurations"));
          changePanelStateWithName("configurations", true, {});
          popoverRef.current.closePopover();
        }}
        id={`MAIN_MENU_/configurations`}
      ></Button>
    </CCPopoverV2>
  );
};
