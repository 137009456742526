import { useCCWidgetEvents } from "@libs/cc-widget/use-cc-widget-events";
import { useKBar, useRegisterActions } from "@libs/kbar";
import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { SDK } from "@sdk";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import {
  Apps,
  getRoleFromPermission,
} from "@sdk/roles-and-permissions/roles-and-permissions";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { AvailabilityStatus } from "@sdk/user-management/user-management.models";
import {
  Badge,
  Button,
  Dropdown,
  Input,
  Layout,
  Menu,
  Popover,
  Space,
  Switch,
  Tag,
  Tooltip,
  message,
  notification,
} from "antd";
import { Header } from "antd/lib/layout/layout";
import classNames from "classnames";
import { UserAvatar } from "components/common/avatar/user-avatar";
import { useChangeLogs } from "components/common/change-logs/use-change-logs";

import { KeyboardShortcut } from "components/common/keyboard-shortcut/keyboard-shortcut";
import { MobileMenu } from "components/common/mobile-menu/mobile-menu";
import { ModalTitle } from "components/common/modal-title";
import { LocalNotificationsService } from "components/common/notifications/local-notifications-controller";
import { useNotificationsPermission } from "components/common/notifications/use-notifications-permission";
import { SideBarMenu } from "components/common/side-bar-menu/side-bar-menu";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { QueryUnreadCountBadge } from "components/pages/conversations/components/conversation-query-unread-count-badge/conversation-query-unread-count-badge";
import { QueryConversationsBadgeCount } from "components/pages/conversations/components/query-conversations-count-badge/query-conversations-count-badge";
import { push } from "connected-react-router";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import {
  removeRecentLoginData,
  setSwitchingProfile,
} from "store/modules/app-state/app-state.actions";
import { iRecentLoginData } from "store/modules/app-state/app-state.model";
import { selectRecentLogins } from "store/modules/app-state/app-state.selectors";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { loadAllNotifications } from "store/modules/notifications/notifications.helpers";
import { selectActiveNotification } from "store/modules/notifications/notifications.selectors";
import {
  setDarkMode,
  setIsAdvancedMode,
  setSideBarCollapsed,
} from "store/modules/ui-state/ui-state.actions";
import {
  selectFeatureRestrictionConfig,
  selectOrganization,
  selectOrganizationDomain,
} from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useUrlState } from "utils/hooks/use-url-state";
import { justWait } from "utils/just-wait";
import {
  selectIsAdvancedMode,
  selectIsDarkMode,
  selectIsElectronMode,
  selectSideBarIsCollapsed,
} from "../../../store/modules/ui-state/ui-state.selectors";
import {
  logout,
  setAccessToken,
} from "../../../store/modules/users/users.actions";
import {
  selectAccessToken,
  selectCurrentUser,
} from "../../../store/modules/users/users.selectors";
import { NotificationsInbox } from "../../common/notification-inbox/notifications-inbox";
import { PhoneButton } from "./components/phone-button";

import { mdiNavigationVariant, mdiSchool } from "@mdi/js";
import { BadgesController } from "badge-controller";
import { HelpCenterPromptButton } from "components/common/help-center/embeddable-components/help-center-prompt-button";
import { openHelpDeskModal } from "components/common/help-center/embeddable-components/openHelpDeskModal";
import HelpCenterModal from "components/common/help-center/help-center.modal";
import { MDIIcon } from "components/common/mdi-icon";
import { NotificationHelper } from "components/common/notifications/notifications-helper";
import { useAutoSubscribeToPush } from "components/common/notifications/use-auto-subscribe-to-push";
import { usePushNotificationStatus } from "components/common/notifications/use-push-noitificaiton-status";
import DownloadApp from "components/electron-app/download-app/download-app.modal-registry";
import FeatureRequests from "components/feature-requests/feature-requests.modal-registry";
import MyManagePresets from "components/modules/conversations/components/manage-presets-x/manage-presets-x.modal-registry";
import MyEmailSignatures from "components/modules/email-signatures/email-signature-manager.modal-registry";
import TestimonialCollection from "components/modules/testimonial-collection-modal/testimonial-collection.modal-registry";
import MyProfileX from "components/modules/user-management/users/components/my-profile/my-profile.modal-registry";

import { useBetaFeatures } from "components/pages/conversations/use-beta-features";
import { useCan } from "permission-guards/use-can";
import { LoadAllGroups } from "store/modules/groups/groups.helpers";
import { selectAllGroupsQuery } from "store/modules/groups/groups.selectors";
import { loadAllMagicAssistants } from "store/modules/magic-assistants/magic-assistants.helpers";
import { selectAllMagicAssistants } from "store/modules/magic-assistants/magic-assistants.selectors";
import { loadAllUsers } from "store/modules/users/users.helpers";
import { UserTracker } from "user-tracker";
import { GlobalEventEmitter } from "utils/global-event-emitter";
import "./main-navigation-bar.style.scss";

const DarkModeToggle = React.lazy(() => import("react-dark-mode-toggle"));

const { Sider } = Layout;
const { Search } = Input;
const { SubMenu } = Menu;

const _navigation = [
  {
    label: "Home",
    path: "/home",
    keyboardShortcut: "$mod+h",
    icon: <i className="ri-home-2-fill"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  // {
  //   label: "Live View",
  //   path: "/website-live-view",
  //   icon: <i className="ri-global-line"></i>,
  //   requiredPermission: [`${Apps.CONVERSATION}:*`],
  // },
  {
    label: "Inbox",
    path: "/conversations",
    keyboardShortcut: "$mod+i",
    icon: <i className="ri-inbox-2-fill"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    label: "Trackers",
    path: "/trackers",
    icon: <i className="ri-ticket-2-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    // forInsider: true,
  },
  // {
  //   label: "Knowledge Hub",
  //   path: "/knowledge-hub",
  //   icon: <i className="ri-book-open-fill"></i>,
  //   requiredPermission: [`${Apps.CONVERSATION}:*`],
  //   // forInsider: true,
  // },
  // {
  //   label: "Engage Hub",
  //   path: "/engage-hub",
  //   icon: <i className="ri-send-plane-fill"></i>,
  //   requiredPermission: [`${Apps.CONVERSATION}:*`],
  //   // forInsider: true,
  // },
  {
    label: "Customers",
    path: "/customers",
    icon: <i className="ri-team-fill"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    label: "Opportunities",
    path: "/opportunities",
    icon: <i className="ri-money-dollar-box-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Holiday Quotes",
    path: "/holiday-quotes",
    icon: <i className="ri-bill-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    // forInsider: true,
  },
  // {
  //   label: "Support Cases (Beta)",
  //   path: "/customer-cases",
  //   icon: <i className="ri-folder-open-line"></i>,
  //   requiredPermission: [`${Apps.CONVERSATION}:*`],
  //   // forInsider: true,
  // },
  {
    label: "Pipelines",
    path: "/pipelines",
    icon: <i className="ri-layout-column-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  // {
  //   label: "Automations",
  //   path: "/automation",
  //   icon: <i className="ri-magic-line"></i>,
  //   requiredPermission: [`${Apps.ORGANIZATION_MANAGEMENT}:*`]
  // },
  {
    label: "Chatbots",
    path: "/chat-bots",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-robot-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Forms",
    path: "/forms",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-file-text-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Callback Requests",
    path: "/call-back-requests",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-folder-5-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Call Logs",
    path: "/call-logs",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-folder-5-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Sequences",
    path: "/sequences",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-mail-send-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Knowledge Kit",
    path: "/knowledge-kit",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-folder-5-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Knowledge Base",
    path: "/knowledge-base",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-book-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Reports",
    path: "/reports",
    // keyboardShortcut: "$mod+r",
    icon: <i className="ri-bar-chart-fill"></i>,
    requiredPermission: [`${Apps.REPORTS}:*`],
  },
  {
    label: "Workspace Settings",
    path: "/configurations",
    // keyboardShortcut: "$mod+s",
    icon: <i className="ri-home-gear-line"></i>,
    requiredPermission: [`${Apps.ORGANIZATION_MANAGEMENT}:*`],
  },
];

const PathHistory: {
  [rootPath: string]: {
    path: string;
    search: string;
  };
} = {};

export const MainNavigationBar = ({ mobileMode }: { mobileMode?: boolean }) => {
  const store = useStore();
  const dispatch = useDispatch();

  const isSidebarCollapsed = useSelector(selectSideBarIsCollapsed);
  const recentLogins = useSelector(selectRecentLogins);
  const currentOrganizationDomain = useSelector(selectOrganizationDomain);
  const currentOrganization = useSelector(selectOrganization);
  const betaFeatures = useBetaFeatures();

  const location = useLocation();

  const user = useSelector(selectCurrentUser);
  const { can, isReady } = useCan();

  useEffect(() => {
    const firstPath = location.pathname.split("/")[1];
    PathHistory[firstPath] = {
      path: location.pathname,
      search: location.search,
    };
  }, [location]);

  const { state: connections, retry: reload } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections,
  );

  // Just Load All
  const {
    state: { list: userGroups },
  } = useQueryWithStore(selectAllGroupsQuery, LoadAllGroups);

  const { state: magicAssistants } = useQueryWithStore(
    selectAllMagicAssistants,
    loadAllMagicAssistants,
  );

  const liveChatConnection = useMemo(() => {
    return connections.find((item) => item.type === "WEB_CHAT");
  }, [connections]);

  const currentUser = useSelector(selectCurrentUser);
  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(
      currentUser?.permissions || [],
    );
    return currentUserRole;
  }, [currentUser?.permissions]);

  const featureRestrictionConfig = useSelector(selectFeatureRestrictionConfig);

  const navigation = useMemo(
    () =>
      _navigation
        .filter((item) => isReady && can!(item.requiredPermission))
        .filter((item) => {
          // Custom Filter
          if (item.path === "/website-live-view" && !liveChatConnection) {
            return false;
          }
          if (
            item.path === "/customers" &&
            featureRestrictionConfig?.["CRM_ACCESS"]?.[userRole]
          ) {
            return false;
          }
          if (item.path === "/holiday-quotes") {
            return betaFeatures?.QUOTE_CREATOR;
          }
          if (item.path === "/trackers") {
            return (
              (currentOrganization?.appData?.HELP_DESK?.ticketTypes?.length ||
                0) > 0
            );
          }
          return true;
        })
        .filter((item) => (item.forInsider ? false : true))
        .filter((item) =>
          item.forInsider
            ? window.location.href.includes("localhost") ||
              window.location.href.includes("reach-it-right.clickconnector") ||
              window.location.href.includes("cc.clickconnector")
            : true,
        ),
    [
      betaFeatures?.QUOTE_CREATOR,
      can,
      currentOrganization?.appData?.HELP_DESK?.ticketTypes?.length,
      featureRestrictionConfig,
      isReady,
      liveChatConnection,
      userRole,
    ],
  );

  const currentAvailabilityStatus = user.metaData.availabilityStatus;

  const isLargeScreen = useMediaQuery({ query: "(min-width: 1400px)" });

  const { notificationPermissionState } = useNotificationsPermission();

  useEffect(() => {
    const runAsync = async () => {
      if (
        !(user && (window as any).ccWidget && (window as any).ccWidget.identify)
      ) {
        await justWait(1000);
      }
      if (
        user &&
        (window as any).ccWidget &&
        (window as any).ccWidget.identify
      ) {
        (window as any).ccWidget.identify({
          id: user.id,
          firstName: user.data.firstName,
          lastName: user.data.lastName,
          primaryEmail: user.credentials.email,
          company: {
            id: currentOrganization?.id,
            name: currentOrganization?.data.name,
          },
        });
      }
    };
    runAsync();
  }, [currentOrganization?.data.name, currentOrganization?.id, user, user?.id]);

  const onWidgetLoaded = useCallback(() => {
    const user = selectCurrentUser(store.getState());
    const currentOrganization = selectOrganization(store.getState());
    if (
      user &&
      (window as any).ccWidget &&
      (window as any).ccWidget.identify &&
      currentOrganization
    ) {
      (window as any).ccWidget.identify({
        id: user.id,
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        primaryEmail: user.credentials.email,
        company: {
          id: currentOrganization?.id,
          name: currentOrganization?.data.name,
        },
      });
    }
  }, [store]);

  useCCWidgetEvents({ onInit: onWidgetLoaded });

  useChangeLogs();

  const {
    query: { toggle },
  } = useKBar();

  const { doAction: changeAvailabilityStatus, isProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (availabilityStatus: AvailabilityStatus) =>
          SDK.editCurrentUser({
            metaData: {
              availabilityStatus,
            },
          }).then((d) => {
            BadgesController.processBadgeEvent("use-availability");
          }),
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  // const [isTestimonialModalVisible, setTestimonialVisibility] = useState(false);
  const [isTestimonialModalVisible, setTestimonialVisibility] = useUrlState(
    "testimonial-modal",
    "" as string,
  );

  const [isProfileMenuVisible, setIsProfileMenuVisible] = useState(false);
  const isDarkMode = useSelector(selectIsDarkMode);

  const isDevMode = useSelector(selectIsAdvancedMode);
  const toggleDevMode = useCallback(() => {
    dispatch(setIsAdvancedMode(!isDevMode));
    message.info(isDevMode ? "Exit Dev Mode" : "Enter Dev Mode");
  }, [dispatch, isDevMode]);

  const handleMenuClick = useCallback((e) => {
    setIsProfileMenuVisible(false);
  }, []);

  const domainConfig = useMemo(() => {
    if (window.location.href.includes(".clickconnector.io")) {
      return {
        domain: window.location.href
          .split(".clickconnector.io")[0]
          .replace("https://", ""),
        editable: false,
      };
    }
    return {
      domain: "",
      editable: true,
    };
  }, []);

  // Domain Switching from old - START
  const accessToken = useSelector(selectAccessToken);
  useEffect(() => {
    if (window.location.href.includes("web.clickconnector.app")) {
      if (accessToken && currentOrganizationDomain) {
        dispatch(logout());
        window.location.href = `https://${currentOrganizationDomain.toLowerCase()}.clickconnector.io/login?accessToken=${accessToken}`;
      }
    }
  }, [accessToken, currentOrganizationDomain, dispatch]);

  // Sub Domain, Switching Profile from URL parameter - END

  // Patch for Socket not connecting - START
  useEffect(() => {
    setTimeout(async () => {
      const user = selectCurrentUser(store.getState());
      if (user && !user.metaData.isAvailable) {
        console.log("Socket Availability Patch");
        await loadAllUsers()(store, true);
        const user = selectCurrentUser(store.getState());
        if (user && !user.metaData.isAvailable) {
          console.log("Socket Availability Patch");
          window.location.reload();
        }
      }
    }, 1500);
  }, [store]);
  // Patch for Socket not connecting - END

  useEffect(() => {
    if (isDarkMode) {
      BadgesController.processBadgeEvent("use-dark-mode");
    }
  }, [isDarkMode]);

  const [notificationVisibility, setNotificationVisibility] = useState(false);

  const [isHelpCenterVisible, setHelpCenterVisibility] = useUrlState(
    "activate-help-center",
  );

  useEffect(() => {
    if (!LocalNotificationsService.hasPermission) {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          icon={<i className="ri-check-line pr-2"></i>}
          // size="small"
          onClick={() => {
            LocalNotificationsService.requestPermissions();
            notification.destroy(key);
          }}
        >
          Enable
        </Button>
      );
      const icon = <i className="ri-shield-check-line pr-2"></i>;
      notification.open({
        icon,
        message: "Enable Notifications",
        description:
          "Get notified when you receive a new message or when someone tags to you in a conversation",
        btn,
        key,
        onClose: () => {
          // Nothing
        },
        duration: 0,
      });
    }
  }, []);

  const { state: allNotifications } = useQueryWithStore(
    selectActiveNotification,
    loadAllNotifications,
  );

  useEffect(() => {
    const currentUser = selectCurrentUser(store.getState());
    const organization = selectOrganization(store.getState());
    if (currentUser && organization) {
      // Mixpanel User Identification
      UserTracker.identify(currentUser.id);
      const mixPanelProfile = {
        $name: [currentUser.data.firstName, currentUser.data.lastName]
          .filter((e) => e)
          .join(" "),
        $first_name: currentUser.data.firstName,
        $last_name: currentUser.data.lastName,
        $email: currentUser?.credentials.email,
        "User Role": getRoleFromPermission(currentUser.permissions),
        organizationId: currentUser.organizationId,
        workspace: organization?.domain,
      };
      UserTracker.setUserProperties(mixPanelProfile);
    }
  }, [store]);

  const sendDebugData = useCallback(() => {
    const currentUser = selectCurrentUser(store.getState());
    SDK.sendErrorLog({
      subject: "Debug Data",
      data: {
        title: "Debug Data",
        url: window.location.href,
        connectionLogs: JSON.stringify(SocketConnector.connectionLogs),
        organization: selectOrganization(store.getState())?.domain || "N/A",
        user: currentUser?.data?.firstName + " " + currentUser?.data?.lastName,
        userId: currentUser?.id,
      },
    }).catch((e) => {
      message.error("Error while sending debug data");
    });
    message.info("Sending debug data");
  }, [store]);

  const [logoClickedCount, setLogoClickedCount] = useState(0);

  const onLogoClicked = useCallback(() => {
    if (logoClickedCount === 10) {
      setLogoClickedCount(0);
      sendDebugData();
    } else {
      setLogoClickedCount(logoClickedCount + 1);
    }
  }, [logoClickedCount, sendDebugData]);

  const kbBindings = useMemo(() => {
    const mainNavigation = navigation.map((item) => ({
      id: item.label,
      name: `Navigate to ${item.label}`,
      icon: <i className="ri-arrow-right-line"></i>,
      section: "Navigation",
      shortcut: item.keyboardShortcut ? [item.keyboardShortcut] : undefined,
      keywords: `Navigate to ${item.label}`,
      perform: () => store.dispatch(push(item.path)),
    }));
    const profileMenuShortcuts = [
      {
        id: "Help Center",
        name: `Open Help Center`,
        icon: <i className="ri-question-line"></i>,
        shortcut: ["?"],
        keywords: `Open Help Center`,
        perform: () => setHelpCenterVisibility("YES"),
      },
    ];
    return [...mainNavigation, ...profileMenuShortcuts];
  }, [navigation, setHelpCenterVisibility, store]);

  useRegisterActions(kbBindings, [kbBindings]);

  const { changePanelStateWithName } = useModalPanels();

  const Modals = (
    <>
      <HelpCenterModal
        visible={isHelpCenterVisible === "YES"}
        onChangeVisibility={(state) =>
          setHelpCenterVisibility(state ? "YES" : "NO")
        }
      />
    </>
  );

  const ProfileMenu = (
    <>
      <Space
        size={24}
        className={classNames("right-menu-items", {
          "mt-4": !mobileMode,
        })}
        direction={
          mobileMode
            ? "horizontal"
            : isSidebarCollapsed
            ? "vertical"
            : "horizontal"
        }
        align="center"
      >
        <PhoneButton mobileMode={mobileMode} />

        {/* <Tooltip title="Help Center" placement="right">
                <i
                  className="ri-question-line text-xl cursor-pointer"
                  onClick={() => setHelpCenterVisibility(true)}
                ></i>
              </Tooltip> */}
        <Popover
          content={<NotificationsInbox />}
          trigger="click"
          open={notificationVisibility}
          onOpenChange={setNotificationVisibility}
          placement={mobileMode ? "bottom" : "rightBottom"}
          title={
            <div className="my-2 flex flex-row justify-between items-center">
              <ModalTitle
                title="Notifications"
                icon={<i className="ri-notification-3-fill"></i>}
              />
              <HelpCenterPromptButton
                type="ICON"
                text={
                  <div className="text-lg p-3">
                    Learn to Troubleshoot Notifications
                  </div>
                }
                description=""
                icon="QUESTION"
                articleId="fc89e0-0e2fe"
              />
            </div>
          }
        >
          <Tooltip title="Notifications" placement="right">
            <Badge count={allNotifications.length}>
              <Button
                type="text"
                id="notification-icon-nav"
                icon={
                  <i
                    className={classNames(
                      "ri-notification-3-fill cursor-pointer text-xl",
                    )}
                  ></i>
                }
              ></Button>
            </Badge>
          </Tooltip>
        </Popover>

        <Dropdown
          overlay={<ProfileMenuOverlay handleMenuClick={handleMenuClick} />}
          trigger={["click"]}
          placement="topLeft"
          onOpenChange={(flag) => {
            setIsProfileMenuVisible(flag);
          }}
          open={isProfileMenuVisible}
          // arrow
        >
          <div id="profile-menu-icon">
            <UserAvatar
              userId={user.id}
              size={isLargeScreen ? 44 : 36}
              className="cursor-pointer"
            />
          </div>
        </Dropdown>
      </Space>
    </>
  );

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const isElectron = useSelector(selectIsElectronMode);

  const hideNavigation = useCallback(() => setMobileMenuOpen(false), []);

  if (mobileMode) {
    return (
      <Header className="bg-white dark:bg-gray-900  mode_transition">
        <div className="flex flex-row justify-between items-center">
          <div className="menu" style={{ width: 100 }}>
            <Button
              type="text"
              onClick={() => setMobileMenuOpen(true)}
              icon={<i className="ri-menu-line"></i>}
            ></Button>
          </div>
          <img src="/logo.png" alt="Logo" style={{ width: 60 }} />
          <div style={{ width: 100 }}>{ProfileMenu}</div>
        </div>
        <MobileMenu isVisible={isMobileMenuOpen} onHideMenu={hideNavigation}>
          <div className="p-9">
            <SimpleCardSection className="w-full">
              <SideBarMenu
                menuItems={navigation.map((item) => ({
                  type: "LINK_ITEM",
                  key: item.path,
                  label: item.label,
                  icon: item.icon,
                }))}
                onMenuClick={(item) => {
                  if (item.key === "/configurations") {
                    changePanelStateWithName("configurations", true, {});
                    // Todo
                  } else {
                    dispatch(push(String(item.key)));
                  }

                  setMobileMenuOpen(false);
                }}
                selectedKeys={[`/${location.pathname.split("/")[1]}`]}
              />
            </SimpleCardSection>
          </div>
        </MobileMenu>
        {Modals}
      </Header>
    );
  }

  return (
    <PerfectScrollbar
      className="h-full flex items-stretch overflow-x-hidden"
      data-click-context="Side Nav Bar"
    >
      <Sider
        collapsible={true}
        width={"20rem"}
        collapsed={isSidebarCollapsed}
        collapsedWidth={"5rem"}
        theme="light"
        className="main-navigation-bar animated fadeInLeftMin overflow-x-hidden"
        trigger={
          <Button
            type="text"
            icon={
              <i
                className={classNames(
                  "ri-arrow-right-s-line text-2xl transition-all transform",
                  {
                    "rotate-180": !isSidebarCollapsed,
                  },
                )}
              ></i>
            }
            style={{ width: 40, height: 40 }}
            className="bg-gray-100 dark:bg-transparent justify-center items-center mode_transition"
            onClick={() => dispatch(setSideBarCollapsed(!isSidebarCollapsed))}
          />
        }
      >
        <div className="h-full flex flex-col items-center justify-between">
          <div
            className="top-section"
            style={{ marginTop: isElectron ? 15 : 0 }}
          >
            <img
              src="/cc-assets/logo.png"
              style={{ width: "5rem" }}
              alt="ClickConnector"
              onClick={onLogoClicked}
            />
            {(logoClickedCount === 9 || isDevMode) && (
              <div
                style={{ width: 80 }}
                className="flex flex-row justify-center items-center cursor-pointer"
              >
                <Tag onClick={toggleDevMode} className="mr-0">
                  Dev
                </Tag>
              </div>
            )}
            <div className="flex flex-col justify-center items-center">
              <Button
                type="text"
                size="large"
                icon={<i className="ri-search-line"></i>}
                onClick={() => {
                  toggle();
                }}
              ></Button>
              <div
                className="-ml-1 cursor-pointer"
                onClick={() => {
                  toggle();
                }}
              >
                <KeyboardShortcut label={"$mod+k"} disableToolTip={true} />
              </div>
            </div>
          </div>
          <div className="overflow-auto flex-1 flex items-center">
            <PerfectScrollbar className={"flex-1 flex items-center"}>
              <Menu
                theme="light"
                mode="inline"
                defaultSelectedKeys={[]}
                className="m-auto"
                onClick={({ key }) => {
                  const rootPath = String(key);
                  const LastPath = PathHistory[rootPath.split("/")[1]];
                  dispatch(
                    push(
                      LastPath
                        ? `${LastPath.path}${LastPath.search}`
                        : rootPath,
                    ),
                  );
                }}
                selectedKeys={[`/${location.pathname.split("/")[1]}`]}
              >
                {navigation.map((item) => (
                  <Menu.Item
                    id={`MAIN_MENU_${item.path}`}
                    key={item.path}
                    icon={
                      <div className="relative">
                        {item.icon}
                        {item.label === "Inbox" && (
                          <>
                            <span
                              className="absolute"
                              style={{
                                top: "0.5rem",
                                right: "0.5rem",
                              }}
                            >
                              <QueryConversationsBadgeCount
                                query={"Unassigned"}
                              />
                            </span>
                            <span
                              className="absolute"
                              style={{
                                top: "0.5rem",
                                right: "0.5rem",
                              }}
                            >
                              <QueryUnreadCountBadge query={"My Inbox"} />
                            </span>
                          </>
                        )}
                      </div>
                    }
                    className={classNames(
                      item.path === "/configurations" ? "config-side-bar" : "",
                    )}
                  >
                    <div className="flex flex-row justify-between items-center">
                      {item.label}
                      {item.keyboardShortcut && (
                        <KeyboardShortcut
                          label={item.keyboardShortcut}
                          inDarkBg={isSidebarCollapsed}
                        />
                      )}
                    </div>
                  </Menu.Item>
                ))}
              </Menu>
            </PerfectScrollbar>
          </div>

          <div className="bottom-section">
            <div className="mb-4">{ProfileMenu}</div>
          </div>
        </div>
        {Modals}
      </Sider>
    </PerfectScrollbar>
  );
};

export const ProfileMenuOverlay = ({
  handleMenuClick,
}: {
  handleMenuClick: (e) => any;
}) => {
  const store = useStore();
  const dispatch = useDispatch();

  const recentLogins = useSelector(selectRecentLogins);
  const currentOrganizationDomain = useSelector(selectOrganizationDomain);
  const currentOrganization = useSelector(selectOrganization);

  const currentUser = useSelector(selectCurrentUser);
  const user = useSelector(selectCurrentUser);

  const currentAvailabilityStatus = user.metaData.availabilityStatus;

  const isDevMode = useSelector(selectIsAdvancedMode);

  const { changePanelState } = useModalPanels();
  const isDarkMode = useSelector(selectIsDarkMode);

  const switchProfile = useCallback(
    (profileData: iRecentLoginData) => {
      dispatch(setSwitchingProfile(true));
      message.info("Switching Profile");
      dispatch(logout());
      setTimeout(() => {
        dispatch(setAccessToken(profileData.accessToken));
        dispatch(setSwitchingProfile(false));
      }, 600);
    },
    [dispatch],
  );

  const addAccount = useCallback(() => {
    message.info("Add Account");
    dispatch(logout());
  }, [dispatch]);

  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(
      currentUser?.permissions || [],
    );
    return currentUserRole;
  }, [currentUser?.permissions]);

  const { doAction: changeAvailabilityStatus, isProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (availabilityStatus: AvailabilityStatus) =>
          SDK.editCurrentUser({
            metaData: {
              availabilityStatus,
            },
          }).then((d) => {
            BadgesController.processBadgeEvent("use-availability");
          }),
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const betaFeatures = useBetaFeatures();

  const { subscribeToPush, isOfflinePushEnabled } = usePushNotificationStatus();
  useAutoSubscribeToPush();

  const [isHelpCenterVisible, setHelpCenterVisibility] = useUrlState(
    "activate-help-center",
  );

  return (
    <Menu
      style={{ minWidth: "15rem" }}
      onClick={handleMenuClick}
      data-click-context="Profile Menu"
      mode="inline"
    >
      <div className="p-4">
        <div className="font-bold text-lg">
          {user?.data?.firstName} {user?.data?.lastName}
        </div>
        <div className="text-sm text-gray-600">{user?.credentials?.email}</div>
      </div>

      {/* <Menu.SubMenu
        title={
          <span className="text-lg">
            <i className="ri-arrow-left-right-fill"></i> Switch Accounts
          </span>
        }
      >
        {recentLogins &&
          recentLogins.length > 1 &&
          recentLogins.map((item, index) => (
            <Menu.Item
              key={index.toString()}
              onClick={() => switchProfile(item)}
              className="text-lg"
            >
              <div className="flex flex-row items-center">
                <Avatar
                  avatarUrl={item.avatar}
                  name={item.firstName}
                  size={isLargeScreen ? 44 : 38}
                />
                <div
                  className={classNames("flex flex-col pl-2", {
                    "font-bold":
                      user.credentials.email === item.email &&
                      currentOrganizationDomain === item.domain,
                  })}
                >
                  <div className="email">
                    <i
                      className={
                        user.credentials.email === item.email &&
                        currentOrganizationDomain === item.domain
                          ? "ri-checkbox-circle-line"
                          : "ri-checkbox-blank-circle-line"
                      }
                    ></i>
                    {item.domain}
                  </div>
                  <div className="domain text-gray-600"> {item.email}</div>
                </div>
              </div>
            </Menu.Item>
          ))}
        {recentLogins.length > 1 && <Menu.Divider></Menu.Divider>}

        <Menu.Item key={"ADD_ACCOUNT"} onClick={addAccount} className="text-lg">
          <i className="ri-add-box-line"></i>
          Add New Account
        </Menu.Item>
      </Menu.SubMenu> */}
      <Menu.Item
        title=""
        key="1"
        onClick={() => changePanelState(MyProfileX, true, {})}
      >
        <span className="">
          <i className="ri-profile-line"></i> Profile
        </span>
      </Menu.Item>
      <Menu.SubMenu
        title={
          <span className="">
            <i className="ri-customer-service-line"></i> Availability
          </span>
        }
        key={"Availability"}
      >
        <Menu.Item
          onClick={() => changeAvailabilityStatus("AVAILABLE")}
          className=""
        >
          <i
            className={
              currentAvailabilityStatus === "AVAILABLE"
                ? "ri-checkbox-circle-line"
                : "ri-checkbox-blank-circle-line"
            }
          ></i>
          Available
        </Menu.Item>
        <Menu.Item
          onClick={() => changeAvailabilityStatus("BUSY")}
          className=""
        >
          <i
            className={
              currentAvailabilityStatus === "BUSY"
                ? "ri-checkbox-circle-line"
                : "ri-checkbox-blank-circle-line"
            }
          ></i>
          Busy
        </Menu.Item>
        <Menu.Item
          onClick={() => changeAvailabilityStatus("AWAY")}
          className=""
        >
          <i
            className={
              currentAvailabilityStatus === "AWAY"
                ? "ri-checkbox-circle-line"
                : "ri-checkbox-blank-circle-line"
            }
          ></i>
          Away
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Divider></Menu.Divider>
      <Menu.Item title="">
        <div className="flex justify-between items-center dark_mode_toggle">
          <div className="">Dark Mode</div>

          <Switch
            checked={isDarkMode}
            onChange={(checked) => {
              dispatch(setDarkMode(checked));
            }}
          />
          {/* <Suspense fallback={<SuspenseLoadingIndicator />}>
            <DarkModeToggle
              onChange={(checked) => {
                dispatch(setDarkMode(checked));
              }}
              checked={isDarkMode}
              size={30}
              className="dark_mode_toggle"
            />
          </Suspense> */}
        </div>
      </Menu.Item>
      {/* <Menu.Divider></Menu.Divider> */}
      {/* <Menu.Item disabled>
        <a className="">
          <i className="ri-palette-line"></i>Theme
        </a>
      </Menu.Item> */}
      {/* <Menu.Item disabled>
        <a className="">
          <i className="ri-window-line"></i> Panels
        </a>
      </Menu.Item> */}
      <Menu.Divider></Menu.Divider>
      <Menu.Item
        title=""
        key="2"
        onClick={() => changePanelState(MyManagePresets, true, {})}
      >
        <span className="">
          <i className="ri-message-3-line"></i> Message Presets
        </span>
      </Menu.Item>
      <Menu.Item
        title=""
        key="signatures"
        onClick={() => changePanelState(MyEmailSignatures, true, {})}
      >
        <span className="">
          <i className="ri-ball-pen-line"></i>Email Signatures
        </span>
      </Menu.Item>
      <Menu.Divider></Menu.Divider>
      <Menu.SubMenu
        title={
          <span className="">
            <MDIIcon icon={mdiSchool} className="mr-2" /> Learn Basics
          </span>
        }
        key={"Learn Basics"}
      >
        <div className="font-bold px-2 mt-2">Tours</div>
        <Menu.Divider></Menu.Divider>
        <Menu.Item
          onClick={() => {
            GlobalEventEmitter.emit("OPEN_TOUR", "NAVIGATION");
          }}
          className=""
        >
          <MDIIcon icon={mdiNavigationVariant} className="mr-2" />
          Navigation
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            GlobalEventEmitter.emit("OPEN_TOUR", "INBOX");
          }}
          className=""
        >
          <i className="ri-inbox-line"></i>
          Inbox
        </Menu.Item>
        <div className="font-bold px-2 mt-4">Videos</div>
        <Menu.Divider></Menu.Divider>
        <Menu.Item
          onClick={() => {
            // https://cc.clickconnector.io/knowledge-base?activate-help-center=YES&help-center-article=fc89e0-42a1e&help-center-collection=fc89e0-abf1a&searchTerm=
            openHelpDeskModal({
              articleId: "fc89e0-42a1e",
              collectionId: "fc89e0-abf1a",
            });
          }}
          className=""
        >
          <i className="ri-inbox-line"></i>
          Introduction to Unibox
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            // https://cc.clickconnector.io/knowledge-base?activate-help-center=YES&help-center-article=fc89e0-b443b&help-center-collection=fc89e0-abf1a&searchTerm=
            openHelpDeskModal({
              articleId: "fc89e0-b443b",
              collectionId: "fc89e0-abf1a",
            });
          }}
          className=""
        >
          <i className="ri-user-star-line"></i>
          Sales Manager's Guide
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            // https://cc.clickconnector.io/knowledge-base?activate-help-center=YES&help-center-article=fc89e0-4ca0c&help-center-collection=fc89e0-abf1a&searchTerm=
            openHelpDeskModal({
              articleId: "fc89e0-4ca0c",
              collectionId: "fc89e0-abf1a",
            });
          }}
          className=""
        >
          <i className="ri-user-star-line"></i>
          Marketing Managers's Guide
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            // https://cc.clickconnector.io/knowledge-base?activate-help-center=YES&help-center-article=fc89e0-f4f37&help-center-collection=fc89e0-abf1a&searchTerm=
            openHelpDeskModal({
              articleId: "fc89e0-f4f37",
              collectionId: "fc89e0-abf1a",
            });
          }}
          className=""
        >
          <i className="ri-user-settings-line"></i>
          Account Administrator's Guide
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            // https://cc.clickconnector.io/knowledge-base?activate-help-center=YES&help-center-article=fc89e0-7aee8&help-center-collection=fc89e0-abf1a&searchTerm=
            openHelpDeskModal({
              articleId: "fc89e0-7aee8",
              collectionId: "fc89e0-abf1a",
            });
          }}
          className=""
        >
          <i className="ri-folder-chart-line"></i>
          Quick Demo on Reports
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="helpCenter">
        <a onClick={() => setHelpCenterVisibility("YES")} className="">
          <i className="ri-question-line"></i> Help Center{" "}
          <KeyboardShortcut label={"?"} />
        </a>
      </Menu.Item>
      {/* <Menu.Item
        key="changeLogs"
        onClick={() => changePanelState(ChangeLogs, true, false)}
      >
        <span className="">
          <i className="ri-magic-fill"></i> Product Updates
        </span>
      </Menu.Item> */}
      <Menu.SubMenu
        title={
          <span className="">
            <i className="ri-box-3-line"></i> Product
          </span>
        }
        key={"Product"}
      >
        <Menu.Item
          onClick={() => {
            changePanelState(FeatureRequests, true, {
              initialTab: "FEATURE_REQUESTS" as const,
            });
          }}
          className=""
        >
          <i className="ri-lightbulb-line mr-2"></i>
          Feature Requests
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            changePanelState(FeatureRequests, true, {
              initialTab: "BUG_BOARD" as const,
            });
          }}
          className=""
        >
          <i className="ri-bug-line mr-2"></i>
          Report a Bug
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            changePanelState(FeatureRequests, true, {
              initialTab: "ROAD_MAP" as const,
            }); //
          }}
          className=""
        >
          <i className="ri-guide-line mr-2"></i>
          Road map
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            changePanelState(FeatureRequests, true, {
              initialTab: "RELEASE_NOTES" as const,
            }); //
          }}
          className=""
        >
          <i className="ri-file-paper-2-line mr-2"></i>
          Release Notes
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item
        key="testimonial"
        onClick={() => changePanelState(TestimonialCollection, true, {})}
      >
        <span className="">
          <i className="ri-service-line"></i> Send us a Testimonial
        </span>
      </Menu.Item>

      {isDevMode && (
        <>
          <Menu.Item
            key="throw-error"
            onClick={() => {
              throw new Error("Test Error");
            }}
          >
            <span className="">
              <i className="ri-pulse-fill"></i>Send Test Error
            </span>
          </Menu.Item>
          <Menu.Item
            title=""
            key="push-notifications-subscription"
            onClick={() => {
              subscribeToPush();
            }}
          >
            <span className="">
              <i className="ri-notification-3-line"></i> Enable Push
              Notifications{" "}
              {isOfflinePushEnabled && <i className="ri-check-line"></i>}
            </span>
          </Menu.Item>
          <Menu.Item
            title=""
            key="test-notification"
            onClick={() => {
              NotificationHelper.fire.testNotification({
                title: "Hello World",
                body: "Some Body",
              });
            }}
          >
            <span className="">
              <i className="ri-notification-3-line"></i> Show Notification
            </span>
          </Menu.Item>
        </>
      )}

      {/* Desktop App */}
      {/* {(isDevMode || betaFeatures?.QUOTE_CREATOR) && (
        <>
        
        </>
      )} */}
      <Menu.Item
        title=""
        key="download-app"
        onClick={() => {
          changePanelState(DownloadApp, true, {});
        }}
      >
        <span className="">
          <i className="ri-download-2-line"></i> Download App (Beta)
        </span>
      </Menu.Item>

      <Menu.Divider></Menu.Divider>
      <Menu.Item
        title=""
        key="4"
        onClick={() => {
          message.loading("Logging out", 3);
          UserTracker.track("AUTH - Logout", {});
          dispatch(logout());
          dispatch(
            removeRecentLoginData({
              email: user.credentials.email,
              domain: currentOrganizationDomain!,
            }),
          );
        }}
      >
        <span className="">
          <i className="ri-shut-down-line"></i> Log out
        </span>
      </Menu.Item>
    </Menu>
  );
};
