import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import {
  iCampaignTriggerConditionData,
  TriggerConditionPresets,
} from "@sdk/chat-widgets/chat-widgets.models";
import { iArticleCollection } from "@sdk/knowledge-base/knowledge-base.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Tag,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { ImageInputWithUploader } from "components/modules/file-management/image-input-with-uploader";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect } from "react";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { setChatWidget } from "store/modules/chat-widgets/chat-widgets.slice";
import { useQueryWithStore } from "store/store.hooks";
import { uuidv4 } from "utils/generate-uuid";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import "./add-edit-trigger-modal.scss";
import {
  ChatCampaignPresets,
  GetCampaignPresetType,
  iChatCampaignPresetTypes,
} from "./chat-campaign-presets";

//* This whole file and data model should be refactored

const availableUrlParameters = [
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "ad_id",
  "adset_id",
  "campaign_id",
  "ad_name",
  "adset_name",
  "campaign_name",
];

const ExtractCorrectTriggerConditionDataWithPreset = (
  condition: iCampaignTriggerConditionData,
  preset: iChatCampaignPresetTypes,
): iCampaignTriggerConditionData => {
  if (preset === "Customer lands on a campaign") {
    return {
      forUtmTags: condition.forUtmTags,
    };
  } else if (preset === "Customer lands on a specific page") {
    return {
      pageContains: condition.pageContains,
    };
  }
  return {};
};

const AddTag = (props: { add: () => any }) => {
  useEffect(() => {
    props.add();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div>Configure Ad URL Parameter</div>;
};

export const AddEditTriggerModal = ({
  visible,
  onChangeVisibility,
  mode,
  campaignIndex,
  connectionId,
  onSaveConnection,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  mode: "EDIT" | "ADD";
  connectionId: string;
  campaignIndex: number;
  onSaveConnection: () => any;
}) => {
  const [form] = useForm();
  // export interface iCampaignData {
  //   triggerCondition: iCampaignTriggerCondition;
  //   // For Chat Prompts
  //   message: string;
  //   offerImageUrl?: string;
  // }

  const { state: widget, retry: reload } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );

  useEffectWhen(
    () => {
      if (widget && mode === "ADD" && visible) {
        form.resetFields();
      } else if (widget && mode === "EDIT" && visible) {
        const campaignData =
          widget.configurations.conversationFlow.campaigns[campaignIndex].data;
        form.setFieldsValue({
          ...campaignData,
          triggerCondition: {
            ...campaignData.triggerCondition,
            preset: GetCampaignPresetType({
              data: campaignData,
              id: "xxx",
              isEnabled: true,
            }),
          },
        });
      }
    },
    [campaignIndex, form, mode, visible, widget],
    [widget, mode, visible],
  );

  const {
    doAction: saveWidgetCampaigns,
    isProcessing,
    dispatch,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (campaigns) =>
        SDK.chatWidgets
          .editById(widget?.id!, {
            configurations: {
              conversationFlow: {
                campaigns,
              },
            },
          })
          .then((d) => {
            // added as a patch for sync not working on chat widget
            dispatch(setChatWidget(d));
            onChangeVisibility(false);
            onSaveConnection();
          }),
      successMessage: "Saved",
      failureMessage: "Something went wrong",
    }),
    [onChangeVisibility, onSaveConnection, widget?.id],
  );

  const onSave = async () => {
    try {
      await form.validateFields();
      const existingCampaigns = [
        ...(widget?.configurations.conversationFlow.campaigns || []),
      ];
      const formValue = form.getFieldsValue();
      if (mode === "ADD") {
        saveWidgetCampaigns([
          ...existingCampaigns,
          {
            id: uuidv4(),
            isEnabled: true,
            data: {
              ...formValue,
              triggerCondition: {
                ...formValue.triggerCondition,
                data: ExtractCorrectTriggerConditionDataWithPreset(
                  formValue.triggerCondition.data,
                  formValue.triggerCondition.preset,
                ),
                preset: TriggerConditionPresets.NONE,
              },
            },
          },
        ]);
      } else {
        existingCampaigns[campaignIndex] = {
          ...existingCampaigns[campaignIndex],
          data: {
            ...formValue,
            triggerCondition: {
              ...formValue.triggerCondition,
              data: ExtractCorrectTriggerConditionDataWithPreset(
                formValue.triggerCondition.data,
                formValue.triggerCondition.preset,
              ),
              preset: TriggerConditionPresets.NONE,
            },
          },
        };
        saveWidgetCampaigns(existingCampaigns);
      }
    } catch (e) {
      console.log("Error while saving widget", e);
      message.error("Please check your input");
    }
  };

  const availableArticleCollections: iArticleCollection[] = [];

  return (
    <Modal
      title={
        <ModalTitle
          title={mode === "ADD" ? "Add Chat Campaign" : "Edit Chat Campaign"}
          icon={<i className="ri-tools-line"></i>}
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText={"Save"}
      onOk={() => form.submit()}
      okButtonProps={{ loading: isProcessing }}
      className="add-edit-trigger-modal"
      data-click-context="Add Trigger Modal"
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{}}
        onChange={(e) => {
          console.log("Form Value Changed", form.getFieldsValue());
        }}
        onFinish={onSave}
      >
        <div
          className="add-trigger flex flex-col justify-center items-center border border-gray-200 dark:border-gray-800 rounded-lg mode_transition"
          style={{ maxWidth: 500 }}
        >
          <Divider>
            <Tag color="blue">When</Tag>
          </Divider>

          <Form.Item
            label=""
            name={["triggerCondition", "preset"]}
            rules={[
              {
                required: true,
                message: "Select a Preset",
              },
            ]}
          >
            <Select
              style={{ minWidth: 300 }}
              placeholder="Select Preset"
              size="large"
            >
              {ChatCampaignPresets.map((item, index) => (
                <Select.Option value={item.label} key={item.label}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            shouldUpdate
            style={{ marginBottom: 0 }}
            className="hidden-field"
          >
            {({ getFieldValue }) => {
              const selectedOption = getFieldValue([
                "triggerCondition",
                "preset",
              ]);
              if (selectedOption === "Customer lands on a specific page") {
                return (
                  <div style={{ minWidth: 300, maxWidth: 300 }}>
                    <Form.Item
                      name={["triggerCondition", "data", "pageContains"]}
                      rules={[
                        {
                          required: true,
                          message: "Enter a value",
                        },
                      ]}
                      hasFeedback={false}
                      label="Page URL Contains"
                      style={{ marginBottom: 0 }}
                      help="If the current page url contains the above value, the campaign will be triggered"
                    >
                      <Input
                        // placeholder="Type in your message"
                        placeholder="https://abc.com/summer-sale"
                        style={{ minWidth: 300 }}
                        size="large"
                      />
                    </Form.Item>
                  </div>
                );
              } else if (selectedOption === "Customer lands on a campaign") {
                return (
                  <>
                    <div className="label font-bold mb-2 mt-4">
                      Configure Ad UTM Tags
                    </div>
                    <Form.List
                      name={["triggerCondition", "data", "forUtmTags"]}
                    >
                      {(fields, { add, remove }) => {
                        return (
                          <>
                            {fields.map((field, index) => (
                              <>
                                <Space
                                  key={field.key}
                                  style={{ display: "flex" }}
                                  align="baseline"
                                >
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "tag"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Select a tag",
                                      },
                                    ]}
                                    hasFeedback={false}
                                    className="flex-1"
                                  >
                                    <Select
                                      placeholder="UTM Tag"
                                      size="large"
                                      style={{ minWidth: 140 }}
                                    >
                                      {availableUrlParameters.map(
                                        (item, index) => (
                                          <Select.Option
                                            value={item}
                                            key={item}
                                          >
                                            {item}
                                          </Select.Option>
                                        ),
                                      )}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "value"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Enter a value",
                                      },
                                    ]}
                                    hasFeedback={false}
                                    className="flex-1"
                                  >
                                    <Input
                                      // placeholder="Type in your message"
                                      placeholder="eg: summer-sale"
                                      size="large"
                                      style={{ minWidth: 140 }}
                                    />
                                  </Form.Item>
                                  {/* User Group */}

                                  <MinusCircleOutlined
                                    onClick={() => remove(field.name)}
                                  />
                                </Space>
                                {fields.length - 1 !== index && (
                                  <div className="flex flex-row justify-center items-center mb-6">
                                    <Tag>AND</Tag>
                                  </div>
                                )}
                              </>
                            ))}
                            <div
                              onClick={() =>
                                add({
                                  tag: "utm_campaign",
                                  value: "",
                                })
                              }
                              className="cursor-pointer"
                            >
                              {fields.length === 0 ? (
                                <AddTag
                                  add={() =>
                                    add({
                                      tag: "utm_campaign",
                                      value: "",
                                    })
                                  }
                                />
                              ) : (
                                "+ add another parameter"
                              )}
                            </div>
                          </>
                        );
                      }}
                    </Form.List>
                  </>
                );
              }
              return <div></div>;
            }}
          </Form.Item>

          <Divider>
            <Tag color="blue">Trigger the below message</Tag>
          </Divider>
          <Form.Item
            label=""
            name={"message"}
            rules={[
              {
                required: true,
                message: "Select a Preset",
              },
            ]}
          >
            <Input.TextArea
              // placeholder="Type in your message"
              placeholder="Eg: Hey there 👋, How can we help you?"
              style={{ minWidth: 300, minHeight: 100 }}
              size="large"
            />
          </Form.Item>
          <div className="font-bold">Quick Replies</div>
          <div className="quick-replies-container mb-4" style={{ width: 320 }}>
            <Form.List name={"quickReplies"}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space
                      key={field.key}
                      style={{ display: "flex" }}
                      align="baseline"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, "label"]}
                        rules={[
                          {
                            required: true,
                            message: "Enter a text",
                          },
                        ]}
                        hasFeedback={false}
                        style={{ marginBottom: 4 }}
                      >
                        <Input placeholder="Quick Reply" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "action"]}
                        rules={[
                          {
                            required: true,
                            message: "Select an action",
                          },
                        ]}
                        hasFeedback={false}
                        style={{ marginBottom: 4 }}
                      >
                        <Select style={{ minWidth: 150 }}>
                          <Select.Option value="MESSAGE">
                            Send as Message
                          </Select.Option>
                          <Select.Option value="FAQ" disabled>
                            Show FAQ
                          </Select.Option>
                          <Select.Option value="ROUTE" disabled>
                            Route to specific user group
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      {/* User Group */}

                      <Form.Item shouldUpdate style={{ marginBottom: 4 }}>
                        {({ getFieldValue }) => {
                          const selectedOption = getFieldValue([
                            "options",
                            field.name,
                            "action",
                          ]);
                          if (selectedOption === "FAQ") {
                            return (
                              <Form.Item
                                {...field}
                                name={[field.name, "data", "FAQId"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Select a FAQ",
                                  },
                                ]}
                                hasFeedback={false}
                                style={{ marginBottom: 0 }}
                              >
                                <Select style={{ minWidth: 150 }}>
                                  {availableArticleCollections.map(
                                    (collection) => (
                                      <Select.Option
                                        key={collection.id}
                                        value={collection.id!}
                                      >
                                        {collection.label}
                                      </Select.Option>
                                    ),
                                  )}
                                </Select>
                              </Form.Item>
                            );
                          }
                          if (selectedOption === "ROUTE") {
                            return (
                              <Form.Item
                                {...field}
                                name={[field.name, "data", "userGroupId"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Select a User Group",
                                  },
                                ]}
                                hasFeedback={false}
                                style={{ marginBottom: 0 }}
                              >
                                <Select style={{ minWidth: 150 }}>
                                  {availableArticleCollections.map(
                                    (collection) => (
                                      <Select.Option
                                        key={collection.id}
                                        value={collection.id!}
                                      >
                                        {collection.label}
                                      </Select.Option>
                                    ),
                                  )}
                                </Select>
                              </Form.Item>
                            );
                          }
                          return <div></div>;
                        }}
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}
                  <div
                    onClick={() =>
                      add({
                        action: "MESSAGE",
                        /*type: "work", phone: ""*/
                      })
                    }
                    className="cursor-pointer"
                  >
                    + add a quick reply
                  </div>
                </>
              )}
            </Form.List>
          </div>
          <div className="text-gray-600">Campaign Image (Optional)</div>
          <div className="mb-4" style={{ width: 320 }}>
            <Form.Item
              label=""
              name={"offerImageUrl"}
              rules={[]}
              extra={
                <div>
                  * We recommend 320px x 180px images. Please minify the images
                  for faster loading time
                </div>
              }
              help={
                <div>
                  <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                    {() => {
                      return form.getFieldValue("offerImageUrl") ? (
                        <Button
                          type="link"
                          onClick={() =>
                            form.setFieldsValue({
                              offerImageUrl: "",
                            })
                          }
                          className="p-0"
                        >
                          Clear Image
                        </Button>
                      ) : (
                        <></>
                      );
                    }}
                  </Form.Item>
                </div>
              }
            >
              <ImageInputWithUploader
                type="CONNECTION"
                entityId={connectionId}
                width={320}
                height={180}
              />
            </Form.Item>
          </div>
        </div>
        <Button htmlType="submit" type="primary" className="hidden">
          Save
        </Button>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
